import {API_URL} from "../auth";

export const fetchModelsFromIds = async (modelIds, userId) => {
    const response = await fetch(`${API_URL}/routes/get/models/modelsFromIds?userId=${userId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(modelIds)
    });

    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Error fetching models: ${response.status}`);
    }
};

export const fetchLatestModels = async (page = 1, limit = 20) => {
    const response = await fetch(`${API_URL}/routes/get/models/recentModels?page=${page}&limit=${limit}}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    });
    return await response.json();
}

export const searchModels = async (searchString) => {
    const response = await fetch(`${API_URL}/routes/get/models/searchModels?search=${encodeURIComponent(searchString)}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Error searching models: ${response.status}`);
    }
};

export const fetchHighestRatedModels = async (userId) => {
    const response = await fetch(`${API_URL}/routes/get/models/highestQualityModels?userId=${userId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    });
    return await response.json();
};

export const fetchTopModelDay = async (userId) => {
    const response = await fetch(`${API_URL}/routes/get/models/mostStreamedModelInLastDay?userId=${userId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    });
    return await response.json();
};

export const fetchTopSongsFromModel = async (modelId, userId) => {
    const response = await fetch(`${API_URL}/routes/get/models/topSongsFromModel/${modelId}?userId=${userId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    });
    return await response.json();
}
