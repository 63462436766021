import React, { useContext, useEffect, useRef, useState } from 'react';
import './remixScreen.css';
import Pizzicato from "pizzicato/distr/Pizzicato";
import Sidebar from "../../components/sidebar/header";
import SearchBar from "../../components/searchBar/searchBar";
import SearchResultItem from "../../components/searchResultItem/searchResultItem";
import { searchModels } from "../../api/get/model";
import {searchSongs, searchSongsForRemix} from "../../api/get/song";
import {handleReconnectAudiosFromRemix, handleRemixAudio} from "../../api/remix";
import { logEvent } from "../../App";
import AudioContext, { useHandlePlayTrack } from "../../contexts/audioContext";
import magicIcon from "../../assets/icons/magic.svg";
import spinner from "../../assets/images/loading.gif";
import {useLocation, useNavigate} from "react-router-dom";
import ContentEditable from 'react-contenteditable';
import {useTheme} from "../../contexts/themeContext";
import {uploadSongFromRemix} from "../../api/upload/song";
import {attachModelToSong} from "../../api/upload/helpers";
import Header from "../../components/sidebar/header";

const RemixScreen = () => {
    const location = useLocation();
    const { theme } = useTheme();
    const { isPlaying, currentTrack, setIsPlaying } = useContext(AudioContext);
    const { handlePlayTrack } = useHandlePlayTrack();
    const { starterSong } = location.state || {};
    const navigate = useNavigate();

    // process management
    const [activeStep, setActiveStep] = useState(1);
    // model management
    const [searchModelsQuery, setSearchModelsQuery] = useState('');
    const [modelSearchResults, setModelSearchResults] = useState([]);
    const [selectedExistingModel, setSelectedExistingModel] = useState(null);
    // song management
    const [searchSongsQuery, setSearchSongsQuery] = useState('');
    const [songSearchResults, setSongSearchResults] = useState([]);
    const [selectedExistingSong, setSelectedExistingSong] = useState(null);
    // remix management
    const [isInferring, setIsInferring] = useState(false);
    const [remixedVocalUrl, setRemixedVocalUrl] = useState(null);
    const [remixingError, setRemixingError] = useState(null);
    // output + reverb management
    const [loadedDynamicSound, setLoadedDynamicSound] = useState(null);
    const [dynamicAudioIsPlaying, setDynamicAudioIsPlaying] = useState(false);
    const [backgroundAudio, setBackgroundAudio] = useState(null);
    const [progress, setProgress] = useState(0);
    // output details editing
    const [hasPlayedOutput, setHasPlayedOutput] = useState(false)
    const [titleIsEditing, setTitleIsEditing] = useState(false)
    const [artistIsEditing, setArtistIsEditing] = useState(false)
    const [outputSongTitle, setOutputSongTitle] = useState('')
    const [outputSongArtist, setOutputSongArtist] = useState('')
    const [outputSongArtistUrl, setOutputSongArtistUrl] = useState('')
    const [outputSongCover, setOutputSongCover] = useState(null)
    const [outputSongCoverArtPreview, setOutputSongCoverArtPreview] = useState('')
    const [outputIsPrivate, setOutputIsPrivate] = useState(false)
    const [songPassword, setSongPassword] = useState('')
    const coverInputRef = useRef(null);
    // upload song
    const [isUploading, setIsUploading] = useState(false);
    const [uploadingError, setUploadingError] = useState(null);


    const handleUploadSong = async () => {
        setIsUploading(true);
        setUploadingError(null);
        let uploadedSong = null;
        try {
            const { combinedAudioUrl } = await handleReconnectAudiosFromRemix(
                remixedVocalUrl,
                selectedExistingSong?.instrumentalUrl,
                selectedExistingSong?._id,
                selectedExistingModel?._id
            );

            console.log(outputSongCover)
            const songUploadResponse = await uploadSongFromRemix(
                combinedAudioUrl,
                outputSongTitle,
                outputSongArtist,
                outputSongArtistUrl,
                outputSongCover,
                outputIsPrivate,
                songPassword,
                selectedExistingSong?.artist,
                selectedExistingSong?.artistUrl,
            );
            uploadedSong = songUploadResponse?.song;
            await attachModelToSong(selectedExistingModel?._id, songUploadResponse?.song?._id);
        } catch (error) {
            console.error('Error uploading song:', error);
            setUploadingError(`An error occurred while uploading the song. Please try again later. Error: ${error}`);
        } finally {
            setIsUploading(false);
            navigate(`/song/${uploadedSong?._id}`)
        }
    };

    useEffect(() => {
        if (starterSong) {
            const handleSong = async () => {
                setSongSearchResults([starterSong]);
                setSelectedExistingSong(starterSong)
            }
            handleSong()
        }
    }, []);

    useEffect(() => {
        const handleSearchModels = async () => {
            const results = await searchModels(searchModelsQuery);
            setModelSearchResults(results);
        };
        handleSearchModels();
    }, [searchModelsQuery]);

    useEffect(() => {
        if (!starterSong || searchSongsQuery !== '') {
            const handleSearchSongs = async () => {
                const results = await searchSongsForRemix(searchSongsQuery);
                setSongSearchResults(results);
            };
            handleSearchSongs();
        }
    }, [searchSongsQuery]);

    const handleManageRemix = () => {
        const handleRemix = async () => {
            setIsInferring(true);
            setRemixedVocalUrl(null);
            setHasPlayedOutput(false);
            setRemixingError(null);

            try {
                const { inferredAudioUrl } = await handleRemixAudio(
                    selectedExistingModel?._id,
                    selectedExistingSong?.voiceUrl,
                    selectedExistingSong?._id
                );
                setRemixedVocalUrl(inferredAudioUrl);
            } catch (error) {
                if (error) {
                    setRemixingError(error.message);
                } else {
                    setRemixingError('An unexpected error occurred while remixing audio.')
                }
            } finally {
                setIsInferring(false);
            }
        };

        if (selectedExistingModel) {
            handleRemix();
        }
    };

    const handlePlayPause = (song, model, songs, models) => {
        if (isPlaying && currentTrack?._id === song._id) {
            setIsPlaying(!isPlaying);
        } else if (currentTrack?._id === song._id) {
            setIsPlaying(true);
        } else {
            logEvent('Song Played', { location: "Remix Screen", songId: song._id, songName: song.name });
            handlePlayTrack(song, model, songs, models);
        }
    };

    const handlePlayRemixedAudio = () => {
        if (!hasPlayedOutput) {
            const getAudios = async () => {
                const backgroundAudio = new Audio(selectedExistingSong?.instrumentalUrl);
                const voiceAudio = new Audio(remixedVocalUrl)
                voiceAudio.play();
                backgroundAudio.play();
                setBackgroundAudio(backgroundAudio);
                setLoadedDynamicSound(voiceAudio);
                setDynamicAudioIsPlaying(true);
            }
            getAudios();
            setHasPlayedOutput(true);
        } else {
            if (dynamicAudioIsPlaying) {
                loadedDynamicSound.pause();
                backgroundAudio.pause();
                setDynamicAudioIsPlaying(false);
            } else {
                loadedDynamicSound.play();
                backgroundAudio.play();
                setDynamicAudioIsPlaying(true);
            }
        }
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    useEffect(() => {
        if (backgroundAudio) {
            backgroundAudio.addEventListener('timeupdate', handleProgress);
            return () => {
                backgroundAudio.removeEventListener('timeupdate', handleProgress);
            };
        }
    }, [backgroundAudio]);

    useEffect( () => {
        if (selectedExistingSong) {
            setOutputSongTitle(selectedExistingSong.name);
            setOutputSongArtist(selectedExistingSong.artist);
        }
    }, [selectedExistingSong])

    const handleProgress = () => {
        if (backgroundAudio) {
            setProgress((backgroundAudio.currentTime / backgroundAudio.duration) * 100);
        }
    };

    const handleScrub = (e) => {
        if (backgroundAudio) {
            const offsetX = e.nativeEvent.offsetX;
            const containerWidth = e.currentTarget.clientWidth;
            const newProgress = offsetX / containerWidth;
            backgroundAudio.currentTime = newProgress * backgroundAudio.duration;
            loadedDynamicSound.currentTime = newProgress * loadedDynamicSound.duration;
            setProgress(newProgress * 100);
        }
    };

    const handleTitleClick = () => {
        setTitleIsEditing(true)
    }

    const handleTitleKeyDown = async (event) => {
        if (event.keyCode === 32) {
            event.stopPropagation();
        }
        if (event.key === 'Enter') {
            event.preventDefault();
            setTitleIsEditing(false);
            const newTitle = event.target.textContent.replace(/&nbsp;/g, ' ');
            if (newTitle && newTitle.trim() !== '') {
                setOutputSongTitle(newTitle);
            }
        }
    }

    const handleArtistClick = () => {
        setArtistIsEditing(true)
    }
    const handleTitleChange = (event) => {
        setOutputSongTitle(event.target.value);
    };

    const handleArtistChange = (event) => {
        setOutputSongArtist(event.target.value);
    };

    const handleArtistKeyDown = async (event) => {
        if (event.keyCode === 32) {
            event.stopPropagation();
        }
        if (event.key === 'Enter') {
            event.preventDefault();
            setArtistIsEditing(false);
            const newArtist = event.target.textContent.replace(/&nbsp;/g, ' ');
            if (newArtist && newArtist.trim() !== '') {
                setOutputSongArtist(newArtist);
            }
        }
    }

    const handleCheckPrivate = () => {
        setOutputIsPrivate(!outputIsPrivate)
    }

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDropSongCoverArt = (event) => {
        event.preventDefault();
        setOutputSongCover(event.dataTransfer.files[0]);
        setOutputSongCoverArtPreview(URL.createObjectURL(event.dataTransfer.files[0]));
    };

    const handleSongCoverArtChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setOutputSongCover(file);
            setOutputSongCoverArtPreview(URL.createObjectURL(file));
        }
    };

    const handleTitleBlur = () => {
        setTitleIsEditing(false);
    };

    const handleArtistBlur = () => {
        setArtistIsEditing(false);
    };

    return (
        <div className="remix-screen">
            <Header
                dontShowSearch={true}
                dontShowRemix={true} dontShowRadio={true} />
            <div className="search-container">
                {activeStep === 1 && (
                    <div className="section">
                        <div className="section-step">STEP 1</div>
                        <div className="section-title">Select a song</div>
                        <div className="section-description">Pick a song to have the voice changed</div>
                        <SearchBar
                            label=""
                            query={searchSongsQuery}
                            setQuery={setSearchSongsQuery}
                            placeholder="Search"
                        />
                        {songSearchResults.length > 0 && (
                            <div className="search-results">
                                {songSearchResults.slice(0, 10).map((song, index) => (
                                    <SearchResultItem
                                        key={index}
                                        item={song}
                                        onClick={() => {
                                            setSelectedExistingSong(song);
                                        }}
                                        isSelected={selectedExistingSong === song}
                                        onPlay={() => handlePlayPause(song, null, [song], null)}
                                    />
                                ))}
                            </div>
                        )}
                        <div className={"step-nav-row"}>
                            <button
                                onClick={() => setActiveStep(2)}
                                className={`next-step-button ${selectedExistingSong ? 'ready' : 'disabled'}`}>
                                Next Step: Model
                            </button>
                        </div>
                    </div>
                )}
                {activeStep === 2 && (
                    <div className="section">
                        <div className="section-step">STEP 2</div>
                        <div className="section-title">Select a voice model</div>
                        <div className="section-description">Pick the voice for your new song</div>
                        <SearchBar
                            label=""
                            query={searchModelsQuery}
                            setQuery={setSearchModelsQuery}
                            placeholder="Search"
                        />
                        {modelSearchResults.length > 0 && (
                            <div className="search-results">
                                {modelSearchResults.slice(0, 10).map((model, index) => (
                                    <SearchResultItem
                                        key={index}
                                        item={model}
                                        onClick={() => {
                                            setSelectedExistingModel(model);
                                        }}
                                        isSelected={selectedExistingModel === model}
                                    />
                                ))}
                            </div>
                        )}
                        <div className={"step-nav-row"}>
                            <button
                                onClick={() => setActiveStep(1)}
                                className={`next-step-button active`}>
                                Go Back
                            </button>
                            <button
                                onClick={() => setActiveStep(3)}
                                className={`next-step-button ${selectedExistingModel ? 'ready' : 'disabled'}`}>
                                Next Step: Review
                            </button>
                        </div>
                    </div>


                )}
                {activeStep === 3 && (
                    <div className="section review-section-mobile">
                        <div className="section-step">STEP 3</div>
                        <div className="section-title">Remix and Share!</div>
                        <div className="section-description">Listen to your remix and - if you like it - add to your Hugging Bass profile</div>
                        <div className="selected-container">
                            <div className="selected-items">
                                <h2 className={"section-title"}>Remix Details</h2>
                                {selectedExistingSong && (
                                    <div className="selected-song">
                                        <img src={selectedExistingSong.coverImageUrl} alt={`${selectedExistingSong.name} cover`} />
                                        <div>
                                            <p>{selectedExistingSong.name}</p>
                                            <p>by: {selectedExistingSong.artist}</p>
                                        </div>
                                    </div>
                                )}
                                <h1>+</h1>
                                {selectedExistingModel && (
                                    <div className="selected-model">
                                        <div>
                                            <p>{selectedExistingModel.name}</p>
                                            <p>by: {selectedExistingModel.creator}</p>
                                        </div>
                                    </div>
                                )}
                                {selectedExistingModel && selectedExistingSong && !remixedVocalUrl && (
                                    <>
                                        <button className="next-step-button remix-button" onClick={handleManageRemix} disabled={isInferring}>
                                            {isInferring ? (
                                                <>
                                                    <img className="spinnerIcon" src={spinner} alt="loading" />
                                                    <span>Remixing</span>
                                                </>
                                            ) : (
                                                <>
                                                    <img className="magicIcon" src={magicIcon} alt="remix" />
                                                    <span>Remix</span>
                                                </>

                                            )}
                                        </button>
                                        {remixingError && <div className="error-message">{remixingError}</div>}
                                    </>
                                )}
                                {isInferring && <p className="inferring-text">Hang tight, this may take a few minutes...</p>}
                            </div>
                            <div className="output-preview-container">
                                {remixedVocalUrl && (
                                    <>
                                        <h1 className={"section-title"}>Song Preview</h1>
                                        <h5> Here you can change the song title, artist, and the cover</h5>
                                        <div className={"song-details-container"}>
                                            <div
                                                className="image-drop-area"
                                                onDragOver={handleDragOver}
                                                onDrop={handleDropSongCoverArt}>
                                                {outputSongCoverArtPreview ? (
                                                    <>
                                                        <input
                                                            type="file"
                                                            id="fileInput"
                                                            onChange={handleSongCoverArtChange}
                                                        />
                                                        <img
                                                            className={"preview-image"}
                                                            src={outputSongCoverArtPreview}
                                                            alt="Preview"
                                                        />
                                                    </>
                                                ) : (
                                                    <div className="image-drop-message">
                                                        <input
                                                            type="file"
                                                            id="fileInput"
                                                            onChange={handleSongCoverArtChange}
                                                        />
                                                        <label className="photo-input">Select or drop cover art</label>
                                                    </div>
                                                )}
                                            </div>
                                            <div className={"song-details"}>
                                                <>
                                                    {titleIsEditing ? (
                                                        <ContentEditable
                                                            html={outputSongTitle}
                                                            disabled={false}
                                                            onChange={handleTitleChange}
                                                            tagName='article'
                                                            onKeyDown={handleTitleKeyDown}
                                                            onBlur={handleTitleBlur}
                                                            className={"editable-title"}
                                                        />
                                                    ) : (
                                                        <p className={"song-preview-name"} onClick={handleTitleClick}>{outputSongTitle}</p>
                                                    )}
                                                </>
                                                <>
                                                    {artistIsEditing ? (
                                                        <ContentEditable
                                                            html={outputSongArtist}
                                                            disabled={false}
                                                            onChange={handleArtistChange}
                                                            tagName='article'
                                                            onKeyDown={handleArtistKeyDown}
                                                            onBlur={handleArtistBlur}
                                                            className={"editable-title"}
                                                        />
                                                    ) : (
                                                        <p className={"song-preview-artist"} onClick={handleArtistClick}>{outputSongArtist}</p>
                                                    )}
                                                </>
                                            </div>
                                        </div>
                                    </>
                                )}
                                {remixedVocalUrl && (
                                    <>
                                        <img
                                            className="preview-play-button"
                                            onClick={handlePlayRemixedAudio}
                                            style={{filter: `${theme !== 'light' ? 'invert(1)' : ''}`}}
                                            src={dynamicAudioIsPlaying ? require('../../assets/images/pause.png') : require('../../assets/images/play.png')}
                                        />
                                    </>
                                )}
                                {remixedVocalUrl && (
                                    <div className={"player-time-controls"}>
                                        <p>
                                            {backgroundAudio && backgroundAudio.currentTime ? formatTime(backgroundAudio.currentTime) : '0:00'}
                                        </p>
                                        <div
                                            className="player-time-bar-wrapper"
                                            onClick={handleScrub}
                                        >
                                            <div className="player-time-bar-container">
                                                <div className="player-time-bar" style={{width: `${progress}%`}}></div>
                                                {/*{scrubbingWidth !== null && (*/}
                                                {/*    <div className="time-scrubbing-bar"*/}
                                                {/*         style={{width: `${scrubbingWidth}%`}}>*/}

                                                {/*    </div>*/}
                                                {/*)}*/}
                                            </div>
                                        </div>
                                        <p>
                                            {backgroundAudio && backgroundAudio.duration ? formatTime(backgroundAudio.duration) : '0:00'}
                                        </p>
                                    </div>
                                )}
                                {remixedVocalUrl && (
                                    <>
                                        <label className={"tick-details"}>
                                            <input
                                                checked={outputIsPrivate}
                                                onChange={handleCheckPrivate}
                                                type={"checkbox"}>
                                            </input>
                                            I would like to make this a private song
                                        </label>
                                        {outputIsPrivate && (
                                            <>
                                                <label className={"form-entry-title"}>Song Password:</label>
                                                <input type="text" className="form-text-entry" value={songPassword} placeholder="e.g SuperSpecialPassword1123" onChange={(e) => setSongPassword(e.target.value)} />
                                            </>
                                        )}
                                        <label className={"form-entry-title"}>Creator social URL</label>
                                        <input type="text" className="form-text-entry" value={outputSongArtistUrl} placeholder="e.g https://www.youtube.com/@AirJ34" onChange={(e) => setOutputSongArtistUrl(e.target.value)} />
                                        <p className="form-entry-description">This is the Creators social URL (e.g Youtube, Discord, Twitter etc..)</p>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className={"step-nav-row"}>
                            <button
                                onClick={() => setActiveStep(2)}
                                className={`next-step-button active`}>
                                Go Back
                            </button>
                            <button
                                onClick={handleUploadSong}
                                className={`next-step-button 
                                ${remixedVocalUrl && 'ready'} 
                                ${isUploading | !outputSongCover | !outputSongTitle | !outputSongArtistUrl | !outputSongArtist && 'disabled'}`}>
                                {isUploading ? "Uploading... please wait" : "Publish and Share"}
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default RemixScreen;
