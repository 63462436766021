import React, {useState, useEffect} from 'react';
import './modelList.css'
import ModelTile from "../../tiles/modelTile/modelTile";

const ModelList = ({models, statuses, songsFromModels,  showCreators, pageLocation}) => {
    const [topSongsForModels, setTopSongsForModels] = useState([])

    // get top songs for each model
    useEffect(() => {
        const songsUsingModels = [];
        for (const model of models) {
            const songsUsingThisModel = songsFromModels?.filter(song => model.usedIn.includes(song.song?._id));
            songsUsingModels.push({model: model, songs: songsUsingThisModel});
        }
        setTopSongsForModels(songsUsingModels);
    }, [songsFromModels, models])


    return (
        <div className={"model-list"}>
            {models.length > 0 && models.slice().map((model, index) => {
                return (
                    <ModelTile
                        pageLocation={pageLocation}
                        showCreator={showCreators}
                        model={model}
                        songs={topSongsForModels[index]?.songs}
                        status={statuses[index]}
                        key={model?._id}
                    />
                );
            })}
        </div>
    )
}

export default ModelList;
