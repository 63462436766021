import React, { useContext, useEffect, useState } from "react";
import './topModelTile.css';
import { useNavigate } from "react-router-dom";
import { logEvent } from "../../../../App";
import { toggleFavoriteModel } from "../../../../api/status/model";
import { toggleFavoriteSong } from "../../../../api/status/song";
import { useAuth } from "../../../../contexts/authContext";
import LoginModal from "../../../modals/loginModal";
import pauseImage from "../../../../assets/images/pause.png";
import playImage from "../../../../assets/images/play.png";
import { useHandlePlayTrack } from "../../../../contexts/audioContext";
import AudioContext from "../../../../contexts/audioContext";

const TopModelTile = ({ model, status, favoriteCount, modelSongs = [], modelSongStatuses = [] }) => {
    const { activeUser } = useAuth();
    const { isPlaying, setIsPlaying, currentTrack } = useContext(AudioContext);
    const { handlePlayTrack } = useHandlePlayTrack();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [modelFavoriteCount, setModelFavoriteCount] = useState(model?.favoriteCount || 0);
    const [favoriteStatus, setFavoriteStatus] = useState(status);
    const [songFavoriteCounts, setSongFavoriteCounts] = useState(modelSongs.map(song => song.favoriteCount || 0));
    const [songFavoriteStatuses, setSongFavoriteStatuses] = useState(modelSongStatuses);

    useEffect(() => {
        setModelFavoriteCount(model?.favoriteCount || 0);
        setSongFavoriteCounts(modelSongs.map(song => song.favoriteCount || 0));
        setSongFavoriteStatuses(modelSongStatuses);
    }, [model, modelSongs, modelSongStatuses]);

    const handleToggleFavoriteModel = async () => {
        try {
            logEvent('Model Favorite Toggle', { location: "Top Model Tile", modelId: model._id, modelName: model.name });
            if (!activeUser) {
                setIsModalOpen(true);
                return;
            }
            setFavoriteStatus(prevStatus => !prevStatus);
            setModelFavoriteCount(prevCount => favoriteStatus ? prevCount - 1 : prevCount + 1);
            await toggleFavoriteModel(activeUser?._id, model?._id);
        } catch (error) {
            console.error('Favorite/Unfavorite Error:', error);
        }
    };

    const handleToggleFavoriteSong = async (song, index) => {
        try {
            logEvent('Song Favorite Toggled', { location: "Top Model Tile", songId: song._id, songName: song.name });
            if (!activeUser) {
                setIsModalOpen(true);
                return;
            }
            const activeSongStatus = songFavoriteStatuses[index];
            setSongFavoriteStatuses(prevStatuses => {
                const newStatuses = [...prevStatuses];
                newStatuses[index] = !activeSongStatus;
                return newStatuses;
            });

            setSongFavoriteCounts(prevCounts => {
                const newCounts = [...prevCounts];
                newCounts[index] = activeSongStatus ? newCounts[index] - 1 : newCounts[index] + 1;
                return newCounts;
            });

            await toggleFavoriteSong(activeUser?._id, song._id);
        } catch (error) {
            console.error('Favorite/Unfavorite Error:', error);
        }
    };

    const handlePlayPause = (song, model, songs, models) => {
        if (isPlaying && currentTrack?._id === song._id) {
            setIsPlaying(!isPlaying);
        } else if (currentTrack?._id === song._id) {
            setIsPlaying(true);
        } else {
            logEvent('Song Played', { location: "Top Model Tile", songId: song._id, songName: song.name });
            handlePlayTrack(song, model, songs, models);
        }
    };

    return (
        <>
            <div className={"top-model-container"}>
                <div className={"top-model-first-row"}>
                    <p className={"top-model-place"}>1</p>
                    <div className={"top-model-info"}>
                        <p
                            onClick={() => navigate(`/model/${model._id}`)}
                            className={"top-model-title"}>
                            {model?.name}
                        </p>
                        <p
                            onClick={() => navigate(`/creator/${model.creator}`)}
                            className={"top-model-creator"}>
                            {model?.creator}
                        </p>
                    </div>
                </div>
                <div className={"top-model-stats"}>
                    <p className={"top-model-score"}>
                        {Math.round(model?.qualityScore) || 0}%
                    </p>
                    <div onClick={handleToggleFavoriteModel} className={"top-model-tile-heart-pill"}>
                        <p>{modelFavoriteCount}</p>
                        {favoriteStatus === true ? (
                            <img className={"top-model-tile-heart"} src={require('../../../../assets/images/heart-fill.svg').default} alt="upvote"/>
                        ) : (
                            <img className={"top-model-tile-heart"} src={require('../../../../assets/images/light-heart.svg').default} alt="upvote"/>
                        )}
                    </div>
                </div>
                <div className={"top-model-creations-container"}>
                    {modelSongs && modelSongs.length > 0 && (
                        <>
                            {modelSongs.slice(0, 2).map((song, index) => (
                                <div key={index} className={"top-model-song"}>
                                    <div onClick={() => handlePlayPause(song, model, modelSongs.slice(0, 2), [model, model])} alt={song?.name} style={{backgroundImage: `url("${song?.coverImageUrl}")`}} className={"top-model-song-cover"}>
                                        <div className={"high-five-play-button"}>
                                            <img src={isPlaying && currentTrack?._id === song._id ? pauseImage : playImage} alt={"play"} />
                                        </div>
                                    </div>
                                    <div className={"top-model-song-info"}>
                                        <p
                                            onClick={() => navigate(`/song/${song?._id}`)}
                                            className={"top-model-song-title"}>{song?.name}</p>
                                        <p
                                            onClick={() => navigate(`/creator/${song.artist}`)}
                                            className={"top-model-song-artist"}>{song?.artist}</p>
                                        <div
                                            onClick={() => handleToggleFavoriteSong(song, index)}
                                            className={"top-model-tile-heart-pill"}>
                                            <p>{songFavoriteCounts[index]}</p>
                                            {songFavoriteStatuses[index] === true ? (
                                                <img className={"top-model-tile-heart"} src={require('../../../../assets/images/heart-fill.svg').default} alt="upvote"/>
                                            ) : (
                                                <img className={"top-model-tile-heart"} src={require('../../../../assets/images/light-heart.svg').default} alt="upvote"/>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                    )}
                </div>
            </div>
            <LoginModal isVisible={isModalOpen} onClose={() => setIsModalOpen(false)} />
        </>
    )
}

export default TopModelTile;
