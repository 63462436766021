import React, { useContext, useState } from 'react';
import './header.css';
import { useTheme } from "../../contexts/themeContext";
import AudioContext, { useHandlePlayTrack } from "../../contexts/audioContext";
import { useNavigate } from "react-router-dom";
import { logEvent } from "../../App";
import HeaderOverlay from "./sidebarOverlayMobile/headerOverlayMobile";
import { useAuth } from "../../contexts/authContext";
import LoginModal from "../modals/loginModal";
const discord = require('../../assets/images/discord.png');

const Header = ({
                    sortedSongs,
                    dontShowSearch,
                    dontShowUpload = false,
                    searchQuery,
                    setSearchQuery,
                }) => {
    const { activeUser } = useAuth();
    const { isPlaying, setIsPlaying, currentTrack } = useContext(AudioContext);
    const { handlePlayTrack } = useHandlePlayTrack();
    const { theme } = useTheme();
    const navigate = useNavigate();
    const [showMobileSidebar, setShowMobileSidebar] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSearchExpanded, setIsSearchExpanded] = useState(false);


    const handleGoToDiscord = () => {
        window.open('https://discord.gg/JRPz9QSBwa', '_blank', 'noreferrer');
    };

    const playRandomSong = () => {
        logEvent('Hugging Bass Radio Pressed');
        const randomIndex = Math.floor(Math.random() * sortedSongs.length);
        let randomSong = sortedSongs[randomIndex]?.songObject;
        let randomModel = sortedSongs[randomIndex]?.model;
        if (isPlaying && currentTrack?.name === randomSong?.name) {
            setIsPlaying(!isPlaying);
        } else if (currentTrack?.name === randomSong?.name) {
            setIsPlaying(true);
        }
        handlePlayTrack(
            randomSong,
            randomModel[0],
            sortedSongs.map((song) => song.songObject),
            sortedSongs.map((song) => song.model)
        );
    };

    const toggleSearch = () => {
        setIsSearchExpanded(!isSearchExpanded);
    };

    return (
        <>
            <div className="header">
                <div className={"left-wrapper"}>
                    <div className="hamburger-menu" onClick={() => setShowMobileSidebar(!showMobileSidebar)}>
                        <img
                            className={showMobileSidebar ? 'flip' : ''}
                            style={{ filter: `${theme !== 'light' ? 'invert(1)' : ''}` }}
                            src={require('../../assets/images/shevron-up.png')}
                            alt="menu"
                        />
                    </div>
                    <div className={"header-left-section"}>
                        <div className={"header-top-left-section"}>
                            <h2
                                onClick={() => navigate('/')}
                                className={"header-title"}>
                                Hugging Bass.
                            </h2>
                        </div>
                        <p className={"header-subtitle"}>
                            A safe place to share and create AI music covers and vocal swaps.
                        </p>
                    </div>
                </div>
                <div className={"header-right-section"}>
                    {!dontShowSearch && (
                        <div className="search-bar-container">
                            <img
                                src={require('../../assets/icons/searchIcon.svg').default}
                                alt="Search"
                                className="search-icon"
                                onClick={toggleSearch}
                            />
                            <input
                                value={searchQuery}
                                className={`home-search-bar ${isSearchExpanded ? 'expanded' : ''}`}
                                placeholder="Search for songs and models on Hugging Bass..."
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </div>
                    )}
                    {!dontShowUpload && (
                        <button className={"header-upload-button"} onClick={() => {
                            logEvent('Upload Button Pressed');
                            if (isPlaying) setIsPlaying(!isPlaying);
                            if (activeUser) {
                                navigate('/upload');
                            } else {
                                setIsModalOpen(true);
                            }
                        }}>
                            Upload your music
                        </button>
                    )}
                    {activeUser ? (
                        <button
                            onClick={() => navigate('/account')}
                            className={"header-account-button"}>
                            <img src={activeUser?.profilePhotoUrl} />
                            {activeUser?.username}
                        </button>
                    ) : (
                        <button onClick={() => setIsModalOpen(true)} className={"header-account-button"}>
                            Login
                        </button>
                    )}
                    <img className={"header-discord"} onClick={handleGoToDiscord} src={discord} alt="Discord" />
                </div>
            </div>
            <HeaderOverlay isVisible={showMobileSidebar} onClose={() => setShowMobileSidebar(false)} />
            <LoginModal isVisible={isModalOpen} onClose={() => setIsModalOpen(false)} />
        </>
    );
};

export default Header;
