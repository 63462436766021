import React, { createContext, useState, useContext, useEffect } from 'react';
import { handleLogin, handleSignup } from "../api/auth";

const AuthContext = createContext({
    isAuthenticated: false,
    activeUser: null,
    login: () => {},
    signUp: () => {},
    logout: () => {},
    GetUserId: () => {},
    error: null,
    setError: () => {},
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [activeUser, setActiveUser] = useState(null);
    const [error, setError] = useState(null);

    const login = async (formData) => {
        try {
            const response = await handleLogin(formData);
            if (response) {
                localStorage.setItem('activeUser', JSON.stringify(response.user));
                setIsAuthenticated(true);
                setActiveUser(response.user);
                setError(null);
                return response;
            } else {
                setError('Invalid username or password');
            }
        } catch (error) {
            setError(error.message);
            console.error('Login Error:', error);
        }
    };

    const signUp = async (formData) => {
        try {
            const { user } = await handleSignup(formData);
            if (user) {
                setActiveUser(null);
                localStorage.setItem('activeUser', null);
                localStorage.setItem('activeUser', JSON.stringify(user));
                setIsAuthenticated(true);
                setActiveUser(user);
                setError(null);
            }
        } catch (error) {
            setError(error.message);
            console.error('Sign Up Error:', error);
        }
    };
    const handleSetNewActiveUser = (user) => {
        localStorage.setItem('activeUser', JSON.stringify(user));
        setIsAuthenticated(true);
        setActiveUser(user);
    }

    useEffect(() => {
        const savedUser = localStorage.getItem('activeUser');
        if (savedUser) {
            setActiveUser(JSON.parse(savedUser));
            setIsAuthenticated(true);
        }
    }, []);

    const logout = () => {
        setIsAuthenticated(false);
        setActiveUser(null);
        localStorage.removeItem('activeUser');
    };

    useEffect(() => {
        if (!localStorage.getItem('activeUser')) {
            logout();
        }
    }, []);

    const GetUserId = () => {
        return activeUser?._id;
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, activeUser, handleSetNewActiveUser, login, signUp, logout, GetUserId, error, setError }}>
            {children}
        </AuthContext.Provider>
    );
};
