import React, {useContext, useEffect, useState} from 'react';
import './topSongs.css';
import TopSongTile from "./topSongTile/topSongTile";
import {fetchSongsAndModelsTopSongsWeek} from "../../../api/fetches";
import {useAuth} from "../../../contexts/authContext";
import {useQuery} from "react-query";
import AudioContext, {useHandlePlayTrack} from "../../../contexts/audioContext";
import HorizontalSongTile from "../../tiles/horizontalSongTile/horizontalSongTile";
import {logEvent} from "../../../App";
import {useNavigate} from "react-router-dom";

const TopSongs = () => {
    const navigate = useNavigate()
    const { activeUser } = useAuth();
    const { isPlaying, currentTrack, setIsPlaying } = useContext(AudioContext)
    const { handlePlayTrack } = useHandlePlayTrack();
    const { data } = useQuery(
        ['songsAndModelsTopSongs', activeUser?._id],
        () => fetchSongsAndModelsTopSongsWeek({userId: activeUser?._id}));

    // data
    const [topSongsWeek, setTopSongsWeek] = useState([]);
    const [topSongStatuses, setTopSongStatuses] = useState([]);
    const [topSongModelStatuses, setTopSongsWeekModelStatuses] = useState([]);
    const [topSongsModelsWeek, setTopSongsModelsWeek] = useState([])

    useEffect(() => {
        console.log('Active User:', activeUser);
    }, [activeUser]);

    // fetch data
    useEffect(() => {
        if (data) {
            const {mostStreamedWeek } = data;
            setTopSongsWeek(mostStreamedWeek?.week?.map(song => song.song));
            setTopSongsModelsWeek(mostStreamedWeek?.weekModels?.map(model => model.model));
            setTopSongStatuses(mostStreamedWeek?.week?.map(song => song.isFavorite));
            setTopSongsWeekModelStatuses(mostStreamedWeek?.weekModels?.map(model => model.isFavorite))
        }
    }, [data]);

    const handlePlayPause = (song, model, songs, models) => {
        if (isPlaying && currentTrack?._id === song._id) {
            setIsPlaying(!isPlaying);
        } else if (currentTrack?._id === song._id) {
            setIsPlaying(true);
        } else {
            logEvent('Song Played', { location: "Top Songs", songId: song._id, songName: song.name });
            handlePlayTrack(song, model, songs, models)
        }
    }


    return (
        <div className={"top-songs"}>
            <div className={"top-songs-title-row"}>
                <h1 className={"top-songs-title"}>
                    This Week's Top Charts:
                </h1>
                <p className={"see-all"} onClick={() => navigate('/topSongs')}>
                    See all
                </p>
            </div>

            {topSongsWeek?.length > 1 && (
                <div className={"top-songs-carousel"}>
                    {topSongsWeek.slice(0,5).map((song, i) => {
                        return (
                            <TopSongTile
                                key={song._id}
                                rank={i + 1}
                                song={song}
                                status={topSongStatuses[i]}
                                modelStatus={topSongModelStatuses[i]}
                                modelUsed={topSongsModelsWeek[i]}
                                onPlay={() => handlePlayPause(topSongsWeek[i], topSongsModelsWeek[i], topSongsWeek, topSongsModelsWeek)}
                            />
                        )
                    })}
                </div>
            )}
            {topSongsWeek?.length > 5 && (
                <div className={"top-songs-list"}>
                    {topSongsWeek?.slice(5,9).map((song, i) => {
                        return (
                            <HorizontalSongTile
                                key={song._id}
                                rank={i + 6}
                                song={song}
                                status={topSongStatuses[i]}
                                modelStatus={topSongModelStatuses[i+5]}
                                modelUsed={topSongsModelsWeek[i+5]}
                                onPlay={() => handlePlayPause(topSongsWeek[i+5], topSongsModelsWeek[i+5], topSongsWeek, topSongsModelsWeek)}
                            />
                        )
                    })}
                </div>
            )}
        </div>
    );
}

export default TopSongs;
