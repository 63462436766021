import {
    fetchAllFavorited,
    fetchLatestSongs,
    fetchMostStreamedSongsAll,
    fetchMostStreamedSongWeek
} from "./get/song";
import {fetchHighestRatedModel, fetchHighestRatedModels, fetchModelsFromIds, fetchTopModelDay} from "./get/model";
import {fetchTopCreatorsWeek} from "./get/creators";

export const fetchSongsAndModelsHome = async ({ pageParam = 1, userId }) => {
    try {
        const songs = await fetchLatestSongs(pageParam, userId);
        const usedModelIds = songs?.map(song => song?.song?.origin ? "" : song?.song?.usedModels[0]);
        const songModelObjects = await fetchModelsFromIds(usedModelIds, userId);

        const songModels = songModelObjects.map(model => model.model);
        const modelStatuses = songModelObjects.map(model => model.isFavorite);

        const mergedData = songs?.map((song, index) => {
            const songObject = song.song;
            const model = songObject?.origin ? {} : songModels[index];
            const favoriteCount = songObject?.favoriteCount;
            const downloadCount = model.downloadCount ? model.downloadCount : 0;
            return { songObject, model, favoriteCount, downloadCount };
        });

        return { songs, mergedData, modelStatuses, nextCursor: pageParam + 1 };
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const fetchSongsAndModelsTopSongsWeek = async ({userId}) => {
    try {
        const [mostStreamedWeek] = await Promise.all([
            fetchMostStreamedSongWeek(userId)
        ]);

        return { mostStreamedWeek };
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const fetchSongsAndModelsTopSongs = async ({userId}) => {
    try {
        const [mostStreamedDay] = await Promise.all([
            fetchMostStreamedSongsAll(userId)
        ]);

        return { mostStreamedDay };
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const fetchFavoriteSongsAndModels = async ({userId}) => {
    try {
        const [allFavorited] = await Promise.all([
            fetchAllFavorited(userId)
        ]);

        return { allFavorited };
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};


export const fetchSongsAndModelsTopModelsDay = async ({userId}) => {
    try {
        const mostStreamedModelData = await Promise.all([
            fetchHighestRatedModels(userId)
        ]);

        return { mostStreamedModelData };
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

export const fetchSongsAndModelsTopCreatorsDay = async ({userId}) => {
    try {
        const topCreatorsData = await Promise.all([
            fetchTopCreatorsWeek(userId)
        ]);

        return { topCreatorsData };
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};
