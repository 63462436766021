import {API_URL} from "../auth";

export const toggleFavoriteModel = async (userId, targetModelId) => {
    const response = await fetch(`${API_URL}/routes/status/model/favoriteModel`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ targetModelId, userId })
    });

    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Error toggling favorite status');
    }
    return data;
};

export const checkMultipleModelFavoriteStatuses = async ({userId, modelIds}) => {
    const response = await fetch(`${API_URL}/routes/status/model/checkFavoriteStatuses?userId=${userId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ modelIds })
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Error checking favorite statuses');
    }
    return data.favoriteStatuses;
};
