import './topSongTile.css'
import pauseImage from "../../../../assets/images/pause.png";
import playImage from "../../../../assets/images/play.png";
import React, {useContext, useEffect, useState} from "react";
import AudioContext from '../../../../contexts/audioContext';
import {useAuth} from "../../../../contexts/authContext";
import {toggleFavoriteSong} from "../../../../api/status/song";
import {useNavigate} from "react-router-dom";
import {useTheme} from "../../../../contexts/themeContext";
import {logEvent} from "../../../../App";
import LoginModal from "../../../modals/loginModal";

const TopSongTile = ({ song, modelUsed, status, modelStatus, onPlay, rank }) => {
    const { theme } = useTheme();
    const {activeUser} = useAuth();
    const navigate = useNavigate();
    const { currentTrack, isPlaying} = useContext(AudioContext);
    const [favoriteStatus, setFavoriteStatus] = useState(status);
    const [favoriteCount, setFavoriteCount] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        setFavoriteCount(song?.favoriteCount);
        setFavoriteStatus(status);
    }, [song, modelUsed?.favoriteCount, status, modelStatus]);

    const handleToggleFavoriteSong = async () => {
        try {
            logEvent('Song Favorite Toggled', { location: "Top Song Tile", songId: song._id, songName: song.name });
            if (!activeUser) {
                setIsModalOpen(true);
                return;
            }
            setFavoriteStatus(!favoriteStatus);
            if (favoriteStatus) {
                setFavoriteCount(favoriteCount - 1);
            } else {
                setFavoriteCount(favoriteCount + 1);
            }
            await toggleFavoriteSong(activeUser?._id, song?._id);
        } catch (error) {
            console.error('Favorite/Unfavorite Error:', error);
        }
    };

    return (
        <>
            <div className={"top-song-tile"}>
                <div className={"top-song-info"}>
                    <div className={"top-song-rank"}>
                        <p>{rank}</p>
                    </div>
                    <div>
                        <h1
                            onClick={() => {
                                logEvent('Song Page Viewed', { songId: song._id, songName: song.name })
                                navigate(`/song/${song?._id}`)
                            }}
                            title={song?.name} className={"top-song-title"}>{song?.name}</h1>
                        <h2 onClick={() => {
                            logEvent('Creator Page Viewed', { creatorName: song.artist })
                            navigate(`/creator/${song?.artist}`)
                        }} className={"top-song-artist"}>{song?.artist}</h2>
                    </div>
                </div>
                <div className={"top-song-stats"}>
                    <p
                        onClick={() => {
                            navigate(`/model/${modelUsed?._id}`)
                        }}
                        className={"top-song-stream-count"}>{modelUsed?.name}</p>
                    <div onClick={handleToggleFavoriteSong} className={"top-song-tile-heart-pill"}>
                        <p>{favoriteCount}</p>
                        {favoriteStatus === true ? (
                            <img className={"top-song-tile-heart"} src={require('../../../../assets/images/heart-fill.svg').default} alt="upvote"/>
                        ) : (
                            <img className={"top-song-tile-heart"} style={{filter: `${theme === 'light' ? 'invert(1)' : ''}`}} src={require('../../../../assets/images/light-heart.svg').default} alt="upvote"/>
                        )}
                    </div>
                </div>
                <div className={"top-song-cover"} style={{backgroundImage: `url("${song?.coverImageUrl}")`}}>
                    <div onClick={onPlay} className={"top-song-play-button"}>
                        <img src={isPlaying && currentTrack?.name === song?.name ? pauseImage : playImage} alt={"play"} />
                    </div>
                    {!song?.isRemix && song?.vocalUrl && song?.instrumentalUrl && (
                        <div
                            onClick={() => {
                                if (activeUser) {
                                    navigate('/remix', { state: { starterSong: song }})
                                } else {
                                    setIsModalOpen(true)
                                }
                            }}
                            className={"top-song-remix-overlay-container"}>
                            <img src={require('../../../../assets/icons/magic.svg').default} alt={"remix"} />
                            <p>Cover</p>
                        </div>
                    )}
                </div>
            </div>
            <LoginModal isVisible={isModalOpen} onClose={() => setIsModalOpen(false)} />
        </>
    )
}

export default TopSongTile;
