import React, { useState } from 'react';
import { useAuth } from '../../contexts/authContext';
import { useNavigate } from 'react-router-dom';
import './loginScreen.css'

const LoginScreen = () => {
    const [formData, setFormData] = useState({
        username: '',
        password: '',
    });
    const [error, setError] = useState('');
    const { login } = useAuth();
    const navigate = useNavigate();

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const { user } = await login(formData);
            if (user) {
                navigate('/');
            } else {
                setError("Invalid email/username and/or password");
            }
        } catch (error) {
            console.error('Error during login:', error);
            setError("Invalid email/username and/or password");
        }
    };

    return (
        <div className = 'login-screen'>
            <p>Hugging Bass.</p>
            <h1>
                Welcome back to Hugging Bass.
            </h1>
            <a href="/signup" className = 'no-account-text'>Don't have an account yet, sign up here?</a>

            <div className={"login-form-wrapper"}>
                <form className = 'login-form' onSubmit={handleSubmit}>
                    <input
                        onKeyDown={handleKeyPress}
                        name={"username"}
                        className = 'login-text-box'
                        type="text"
                        placeholder="Username"
                        value={formData.username}
                        onChange={handleChange}
                    />
                    <input
                        className = 'login-text-box'
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={formData.password}
                        onChange={handleChange}
                    />
                    <button className= 'login-button' type="submit">Log In</button>
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                </form>
            </div>
        </div>
    );
};

export default LoginScreen;
