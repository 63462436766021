import React, {useContext, useEffect} from 'react';
import './modelTile.css';

import { useState } from 'react';
import {toggleFavoriteModel} from "../../../api/status/model";
import {useAuth} from "../../../contexts/authContext";
import {downloadModelFiles} from "../../../helpers";
import {useTheme} from "../../../contexts/themeContext";
import pauseImage from "../../../assets/images/pause.png";
import playImage from "../../../assets/images/play.png";
import AudioContext, {useHandlePlayTrack} from '../../../contexts/audioContext';
import {useNavigate} from "react-router-dom";
import {logEvent} from "../../../App";
import LoginModal from "../../modals/loginModal";


const ModelTile = ({ model, songs, status, showCreator, pageLocation }) => {
    const navigate = useNavigate();
    const { isPlaying, currentTrack, setIsPlaying } = useContext(AudioContext)
    const { handlePlayTrack } = useHandlePlayTrack();
    const { activeUser } = useAuth()
    const {theme } = useTheme()
    const [isFavorited, setIsFavorited] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    // set initial status
    useEffect(() => {
        if (status) {
            setIsFavorited(status)
        }
    }, [status])

    //handle actions
    const handleDownloadModelFiles = async (event) => {
        logEvent('Model Downloaded', { location: pageLocation, modelId: model._id, modelName: model.name });
        event.stopPropagation()
        setIsDownloading(true)
        await downloadModelFiles(model)
        setIsDownloading(false)
    };
    const handleToggleFavoriteModel = async () => {
        try {
            logEvent('Model Favorite Toggle', { location: pageLocation, modelId: model._id, modelName: model.name });
            if (!activeUser) {
                setIsModalOpen(true);
                return;
            }
            setIsFavorited(!isFavorited);
            await toggleFavoriteModel(activeUser?._id, model?._id);
        } catch (error) {
            console.error('Favorite/Unfavorite Error:', error);
        }
    };

    const handlePlayPause = (song, model, songs, models) => {
        if (isPlaying && currentTrack?._id === song._id) {
            setIsPlaying(!isPlaying);
        } else if (currentTrack?._id === song._id) {
            setIsPlaying(true);
        } else {
            logEvent('Song Played', { location: pageLocation, songId: song._id, songName: song.name });
            handlePlayTrack(song, model, songs, models)
        }
    }

    return (
        <>
            <div className="model-tile">
                <div className={"model-info-section"}>
                    <p className={"model-tile-descriptor"}>Model</p>
                    <p
                        onClick={() => {
                            logEvent('Model Page Viewed', { modelId: model._id, modelName: model.name })
                            navigate(`/model/${model?._id}`)
                        }}
                        className={"model-tile-title"}>
                        {model?.name}
                    </p>
                    {showCreator !== false && (
                        <p className={"model-tile-second-info"}>
                            By: {model?.creator}
                        </p>

                    )}
                    <div className={"model-tile-actions-row"}>
                        <button
                            onClick={(e) => {
                                handleDownloadModelFiles(e)
                            }}
                            className={`model-tile-download ${isDownloading ? 'disabled' : ''}`}
                            disabled={isDownloading}
                        >
                            {isDownloading ? 'Downloading...' : 'Get Model'}
                        </button>
                        <button
                            onClick={() => {
                                handleToggleFavoriteModel()
                            }}
                            className={`model-tile-upvote`}
                        > {
                            isFavorited ? (
                                <img src={require('../../../assets/images/heart-fill.svg').default} alt="upvote"/>
                            ) : (
                                <>
                                    {theme === 'dark' ? (
                                        <img src={require('../../../assets/images/light-heart.svg').default} alt="upvote"/>
                                    ) : (
                                        <img src={require('../../../assets/images/heart-bold.svg').default} alt="upvote"/>
                                    )}
                                </>
                            )
                        }
                        </button>
                    </div>
                </div>
                {songs && songs.length > 0 && (
                    <>
                        {songs?.slice(0,3).map((song, index) => (
                            <div key={index} className={"model-song-tile"}>
                                <div onClick={() => {handlePlayPause(song?.song, model, songs.slice(0,3), [model, model, model])}} alt={song?.song?.name} style={{backgroundImage: `url("${song?.song?.coverImageUrl}")`}} className={"model-song-tile-cover"}>
                                    <div className={"high-five-play-button"}>
                                        <img src={isPlaying && currentTrack?.name === song?.song?.name ? pauseImage : playImage} alt={"play"} />
                                    </div>
                                </div>
                                <p
                                    onClick={() => {
                                        logEvent('Song Page Viewed', { songId: song.song._id, songName: song.song.name })
                                        navigate(`/song/${song?.song?._id}`)
                                    }}
                                    className={"model-song-title"}>{song?.song?.name}</p>
                                <p
                                    onClick={() => {
                                        logEvent('Creator Page Viewed', { creatorName: song.song.artist })
                                        navigate(`/creator/${song?.song?.artist}`)
                                    }}
                                    className={"model-song-artist"}>{song?.song?.artist}</p>
                            </div>
                        ))}
                    </>
                )}
            </div>
            <LoginModal isVisible={isModalOpen} onClose={() => setIsModalOpen(false)} />
        </>
    );
}

export default ModelTile;
