import React, {useContext, useEffect, useRef, useState} from 'react';
import './player.css'
import AudioContext, {useHandlePlayPause, useHandlePlayTrack} from '../../contexts/audioContext';
import playImage from '../../assets/images/play.png';
import pauseImage from '../../assets/images/pause.png';
import {useAuth} from "../../contexts/authContext";
import {updateStreamCount} from "../../api/song";
import {useNavigate} from "react-router-dom";
import {checkMultipleSongFavoriteStatuses, toggleFavoriteSong} from "../../api/status/song";
import {useTheme} from "../../contexts/themeContext";
import {logEvent} from "../../App";
import LoginModal from "../modals/loginModal";


const Player = ({showPlayer}) => {
    const location = window.location;
    const dontShowRemix = location.pathname.includes('/remix');
    const {audio, volume, setVolume, currentTrack, isPlaying, setIsPlaying, songModel, modelFavoriteStatus} = useContext(AudioContext);
    const { activeUser } = useAuth();
    const {theme} = useTheme();
    const navigate = useNavigate();
    const [progress, setProgress] = useState(0);
    const [albumCoverUrl, setAlbumCoverUrl] = useState('')
    const [scrubbingWidth, setScrubbingWidth] = useState(null);
    const [favoriteStatus, setFavoriteStatus] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const {handlePlayNext, handlePlayPrevious} = useHandlePlayTrack();
    const handlePlayPause = useHandlePlayPause();

    const [isScrubbingVolume, setIsScrubbingVolume] = useState(false);
    const volumeContainerRef = useRef(null);

    // stream count management
    const [streamCountUpdated, setStreamCountUpdated] = useState(false);
    const [currentSongTimeListened, setCurrentSongTimeListened] = useState(0);
    const intervalIDRef = useRef(null);


    const playerRef = useRef();

    const albumCoverStyle = currentTrack && currentTrack.coverImageUrl
        ? {backgroundImage: `url("${albumCoverUrl}")`, backgroundSize: 'cover'}
        : {backgroundColor: '#e6f5ff'};

    useEffect(() => {
        const handleGetFavoriteStatus = async () => {
            const fetchedFavStatus = await checkMultipleSongFavoriteStatuses(activeUser?._id, [currentTrack?._id]);
            setFavoriteStatus(fetchedFavStatus[0]);
        }
        if (currentTrack) {
            handleGetFavoriteStatus();
        }
    }, [currentTrack])

    // pick up media and songs playing
    useEffect(() => {
        if ('mediaSession' in navigator) {
            let artwork = [];

            if (currentTrack?.coverImageUrl) {
                artwork = [
                    {src: currentTrack.coverImageUrl, sizes: '96x96', type: 'image/png'},
                    {src: currentTrack.coverImageUrl, sizes: '128x128', type: 'image/png'},
                    {src: currentTrack.coverImageUrl, sizes: '192x192', type: 'image/png'},
                    {src: currentTrack.coverImageUrl, sizes: '256x256', type: 'image/png'},
                    {src: currentTrack.coverImageUrl, sizes: '384x384', type: 'image/png'},
                    {src: currentTrack.coverImageUrl, sizes: '512x512', type: 'image/png'},
                ];
            }

            if (currentTrack) {
                navigator.mediaSession.metadata = new MediaMetadata({
                    title: currentTrack.name,
                    artist: currentTrack.artist,
                    album: "Hugging Bass",
                    artwork: artwork
                });
            }
            navigator.mediaSession.setActionHandler('play', () => {
                handlePlayPause()
            });

            navigator.mediaSession.setActionHandler('pause', () => {
                handlePlayPause()
            });

            navigator.mediaSession.setActionHandler('previoustrack', async () => {
                handlePlayPrevious();
            });

            navigator.mediaSession.setActionHandler('nexttrack', async () => {
                handlePlayNext();
            });
        }

        if (currentTrack && currentTrack.coverImageUrl) {
            setAlbumCoverUrl(currentTrack.coverImageUrl)
        }

    }, [isPlaying, currentTrack])

    useEffect(() => {
        let playPromise;

        if (audio) {
            if (isPlaying) {
                audio.volume = volume;
                playPromise = audio.play();
                if (playPromise !== undefined) {
                    playPromise.then(_ => {
                        intervalIDRef.current = setInterval(() => {
                            setCurrentSongTimeListened(prevTime => prevTime + 1000);
                        }, 1000);
                    }).catch(error => {
                        console.log("Playback was not successful, error:", error);
                    });
                }
            } else {
                audio.pause();
                if (intervalIDRef.current) {
                    clearInterval(intervalIDRef.current);
                    intervalIDRef.current = null;
                }
            }
        }

        return () => {
            if (audio) {
                audio.pause();
                audio.currentTime = 0;
            }
            if (intervalIDRef.current) {
                clearInterval(intervalIDRef.current);
                intervalIDRef.current = null;
            }
            if (playPromise !== undefined) {
                playPromise.then(_ => {
                    audio.pause();
                    audio.currentTime = 0;
                }).catch(error => {
                    console.log("Playback stop was not successful, error:", error);
                });
            }
        };
    }, [audio]);

    // reset stream count
    useEffect(() => {
        setStreamCountUpdated(false);
        setCurrentSongTimeListened(0);
        if (intervalIDRef.current) {
            clearInterval(intervalIDRef.current);
            intervalIDRef.current = null;
        }
    }, [audio, audio?.src, currentTrack]);

    // increase stream count
    useEffect(() => {
        if (audio) {
            audio.ontimeupdate = () => {
                if (currentSongTimeListened >= 30000 && !streamCountUpdated) {
                    logEvent('Song Streamed > 30s', { songId: currentTrack._id, songName: currentTrack.name });
                    updateStreamCount(currentTrack._id, activeUser?._id, currentTrack?.uploaderId);
                    setStreamCountUpdated(true);
                }
            };
            return () => {
                audio.ontimeupdate = null;
            }
        }
    }, [audio, streamCountUpdated, currentTrack, currentSongTimeListened]);

    // handle spacebar
    useEffect(() => {
        const handleSpacebarPress = (event) => {
            if (event.code === "Space") {
                if (event.target.tagName.toLowerCase() === 'input' || event.target.tagName.toLowerCase() === 'textarea') {
                    return;
                }

                event.preventDefault();
                setIsPlaying(!isPlaying);
            }
        };

        window.addEventListener("keydown", handleSpacebarPress);

        return () => {
            window.removeEventListener("keydown", handleSpacebarPress);
        };
    }, [isPlaying]);

    // volume change
    const handleVolumeChange = () => {
        return (e) => {
            if (audio && isScrubbingVolume) {
                const bounds = volumeContainerRef.current.getBoundingClientRect();
                const x = e.clientX - bounds.left;
                let newVolume = x / bounds.width;
                newVolume = Math.max(0, Math.min(newVolume, 1));
                audio.volume = newVolume;
                setVolume(newVolume);
            }
        };
    }

    const handleVolumeChangeStart = (e) => {
        if (audio) {
            setIsScrubbingVolume(true);
            const offsetX = e.nativeEvent.offsetX;
            const containerWidth = e.currentTarget.clientWidth;
            let newVolume = offsetX / containerWidth;
            newVolume = Math.max(0, Math.min(newVolume, 1));
            audio.volume = newVolume;
            setVolume(newVolume);

            document.addEventListener('mousemove', handleVolumeChange);
            document.addEventListener('mouseup', handleVolumeChangeEnd);
        }
    };

    const handleVolumeChangeEnd = () => {
        setIsScrubbingVolume(false);
        document.removeEventListener('mousemove', handleVolumeChange);
        document.removeEventListener('mouseup', handleVolumeChangeEnd);
    };

    const handleProgress = () => {
        if (audio) {
            setProgress((audio.currentTime / audio.duration) * 100);
        }
    };

    useEffect(() => {
        if (audio) {
            audio.addEventListener('timeupdate', handleProgress);
            return () => {
                audio.removeEventListener('timeupdate', handleProgress);
            };
        }
    }, [audio]);

    const handleMouseOver = (e) => {
        if (audio) {
            const offsetX = e.nativeEvent.offsetX;
            const containerWidth = e.currentTarget.clientWidth;
            const newProgress = offsetX / containerWidth;
            setScrubbingWidth(newProgress * 100);
        }
    };

    useEffect(() => {
        return () => {
            document.removeEventListener('mousemove', handleVolumeChange);
            document.removeEventListener('mouseup', handleVolumeChangeEnd);
        };
    }, []);

    const handleMouseOut = () => {
        setScrubbingWidth(null);
    };

    const handleScrub = (e) => {
        if (audio) {
            const offsetX = e.nativeEvent.offsetX;
            const containerWidth = e.currentTarget.clientWidth;
            const newProgress = offsetX / containerWidth;
            audio.currentTime = newProgress * audio.duration;
            setProgress(newProgress * 100);
        }
    };
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const handleToggleFavoriteSong = async () => {
        try {
            logEvent('Song Favorite Toggled', { location: "Player", songId: currentTrack._id, songName: currentTrack.name });
            if (!activeUser) {
                setIsModalOpen(true);
                return;
            }
            setFavoriteStatus(!favoriteStatus);
            await toggleFavoriteSong(activeUser?._id, currentTrack?._id);
        } catch (error) {
            console.error('Favorite/Unfavorite Error:', error);
        }
    };

    return (
        <>
            {currentTrack && (
                <div className={`player ${showPlayer === false && "invisible-player"}`}>
                    <div ref={playerRef}  className="player-player">
                        <div className={"player-song-info-container"}>
                            <div className={"player-cover-container"} style={albumCoverStyle}>
                            </div>
                            <div className={"player-text-info-container"}>
                                <p
                                    onClick={() => {
                                        logEvent('Song Page Viewed', { songId: currentTrack._id, songName: currentTrack.name })
                                        navigate(`/song/${currentTrack?._id}`)
                                    }}
                                >
                                    {currentTrack?.name}
                                </p>
                                <h6 onClick={() => {
                                    logEvent('Creator Page Viewed', { creatorName: currentTrack.artist })
                                    navigate(`/creator/${currentTrack?.artist}`)
                                }}>
                                    {currentTrack?.artist}
                                </h6>
                            </div>
                            <div className={"player-heart-button"} onClick={handleToggleFavoriteSong}>
                                {
                                    favoriteStatus === true ? (
                                        <img src={require('../../assets/images/heart-fill.svg').default} alt="upvote"/>
                                    ) : (
                                        <img src={require('../../assets/images/light-heart.svg').default} alt="upvote"/>
                                    )
                                }
                            </div>
                        </div>

                        <div className={`player-controls-container`}>
                            <div className={"player-media-controls-container"}>
                                <div
                                    onClick={() => {
                                        if (activeUser) {
                                            navigate(`/remix`, { state: { starterSong: currentTrack }})
                                        } else {
                                            setIsModalOpen(true);
                                        }
                                    }}
                                    className={"player-remix-container player-remix-container-mobile"}>
                                    <img src={require('../../assets/icons/magic.svg').default} alt="remix"/>
                                    <p>Cover</p>
                                </div>
                                <div className={"player-media-controls-center"}>
                                    <div onClick={handlePlayPrevious} className={"player-previous-button"}>
                                        <img style={{filter: `${theme !== 'light' ? 'invert(1)' : ''}`}} src={require('../../assets/images/previous.png')} alt="previous"/>
                                    </div>
                                    <div onClick={() => handlePlayPause()} className={"player-play-button"}>
                                        <img src={isPlaying ? pauseImage : playImage} alt="play"/>
                                    </div>
                                    <div onClick={handlePlayNext} className={"player-next-button"}>
                                        <img style={{filter: `${theme !== 'light' ? 'invert(1)' : ''}`}} src={require('../../assets/images/skip.png')} alt="previous"/>
                                    </div>
                                </div>
                            </div>
                            <div className={"player-time-controls"}>
                                <p>
                                    {currentTrack && audio && audio.currentTime ? formatTime(audio.currentTime) : '0:00'}
                                </p>
                                <div
                                    className="player-time-bar-wrapper"
                                    onClick={handleScrub}
                                    onMouseMove={handleMouseOver}
                                    onMouseLeave={handleMouseOut}
                                >
                                    <div className="player-time-bar-container">
                                        <div className="player-time-bar" style={{width: `${progress}%`}}></div>
                                        {/*{scrubbingWidth !== null && (*/}
                                        {/*    <div className="time-scrubbing-bar"*/}
                                        {/*         style={{width: `${scrubbingWidth}%`}}>*/}

                                        {/*    </div>*/}
                                        {/*)}*/}
                                    </div>
                                </div>
                                <p>
                                    {currentTrack && audio && audio.duration ? formatTime(audio.duration) : '0:00'}
                                </p>
                            </div>
                        </div>
                        {currentTrack?.origin ? (
                            <div className={"player-model-container"}>
                                <h5>prompt</h5>
                                <h2>{currentTrack?.styleOfMusic}</h2>
                            </div>
                        ) : (
                            <>
                                <div className={"player-model-wrapper"}>
                                    {!dontShowRemix && !currentTrack?.isRemix && currentTrack?.vocalUrl && currentTrack?.instrumentalUrl && (
                                        <div
                                            onClick={() => {
                                                if (activeUser) {
                                                    navigate(`/remix`, { state: { starterSong: currentTrack }})
                                                } else {
                                                    setIsModalOpen(true);
                                                }
                                            }}
                                            className={"player-remix-container player-remix-container-desktop"}>
                                            <img src={require('../../assets/icons/magic.svg').default} alt="remix"/>
                                            <p>Cover</p>
                                        </div>
                                    )}
                                    {songModel?.name && (
                                        <div
                                            onClick={() => {
                                                logEvent('Model Page Viewed', { modelId: songModel._id, modelName: songModel.name })
                                                navigate(`/model/${songModel._id}`)
                                            }}
                                            className={"player-model-container"}>
                                            <h5>model</h5>
                                            <h2>{songModel?.name}</h2>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
            <LoginModal isVisible={isModalOpen} onClose={() => setIsModalOpen(false)} />
        </>
    );
}


export default Player;
