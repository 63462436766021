import React, {createContext, useContext, useEffect, useState} from 'react';
import {useAuth} from "./authContext";
import {checkMultipleModelFavoriteStatuses} from "../api/status/model";

const AudioContext = createContext({
    volume: 1,
    setVolume: () => {},
    trackPlayerOpen: false,
    setTrackPlayerOpen: () => {},
    isScrubbingVolume: false,
    setIsScrubbingVolume: () => {},
    audio: null,
    setAudio: () => {},
    alsoByTracks: [],
    setAlsoByTracks: () => {},
    songModel: null,
    modelFavoriteStatus: false,
    setModelFavoriteStatus: () => {},
    setSongModel: () => {},
    modelPlaylist: [],
    setModelPlaylist: () => {},
    currentTrack: null,
    setCurrentTrack: () => {},
    isPlaying: false,
    setIsPlaying: () => {},
    playlist: [],
    setPlaylist: () => {},
    trackPlaylist: [],
    setTrackPlaylist: () => {},
    currentTrackIndex: 0,
    setCurrentTrackIndex: () => {},
    setTrack: () => {}
});

export const AudioProvider = ({ children }) => {
    const [audio, setAudio] = useState(null);
    const [currentTrack, setCurrentTrack] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [playlist, setPlaylist] = useState([]);
    const [trackPlaylist, setTrackPlaylist] = useState([]);
    const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
    const [volume, setVolume] = useState(1);
    const [trackPlayerOpen, setTrackPlayerOpen] = useState(false)
    const [alsoByTracks, setAlsoByTracks] = useState([])
    const [songModel, setSongModel] = useState(null)
    const [modelPlaylist, setModelPlaylist] = useState([])
    const [modelFavoriteStatus, setModelFavoriteStatus] = useState(false)

    const setTrack = (track) => {
        setCurrentTrack(track);
        setIsPlaying(true);
    };

    return (
        <AudioContext.Provider
            value={{
                audio, setAudio, currentTrack, setCurrentTrack, isPlaying, setIsPlaying,
                setTrack, playlist, setPlaylist, currentTrackIndex, setCurrentTrackIndex,
                trackPlaylist, setTrackPlaylist, volume, setVolume,trackPlayerOpen, setTrackPlayerOpen,
                alsoByTracks, setAlsoByTracks, songModel, setSongModel, modelPlaylist, setModelPlaylist, modelFavoriteStatus, setModelFavoriteStatus
            }}
        >
            {children}
        </AudioContext.Provider>
    );
};


export function useHandlePlayTrack() {
    const {
        audio,
        setAudio,
        currentTrack,
        setTrackPlaylist,
        alsoByTracks,
        setAlsoByTracks,
        setCurrentTrack,
        currentTrackIndex,
        setCurrentTrackIndex,
        setPlaylist,
        setTrack,
        setIsPlaying,
        playlist,
        trackPlaylist,
        songModel,
        setSongModel,
        modelFavoriteStatus,
        setModelFavoriteStatus,
        modelPlaylist,
        setModelPlaylist,
        volume
    } = useContext(AudioContext);
    const { activeUser } = useAuth();

    const handlePlayTrack = async (track, model, listOfTracks, listOfModels) => {
        try {
            const newAudio = new Audio(track.audioUrl);
            // newAudio.addEventListener('ended', handlePlayNext);
            const modelStatuses = await checkMultipleModelFavoriteStatuses({userId: activeUser?._id, modelIds: [model]});
            setAudio(newAudio); // Set the new audio instance
            setIsPlaying(true);
            setTrack(track);
            setCurrentTrack(track);
            setSongModel(model);
            setModelFavoriteStatus(modelStatuses[0]);

            newAudio.onerror = function () {
                console.log("Error: " + newAudio.error.code + " " + newAudio.error.message);
            };

            const selectedTrackIndex = listOfTracks.findIndex((playlistTrack) => playlistTrack?._id === track?._id);
            setCurrentTrackIndex(selectedTrackIndex);

            const loadedPlaylistPromises = listOfTracks.map((track) => new Audio(track?.audioUrl));
            const loadedPlaylist = await Promise.all(loadedPlaylistPromises);

            setPlaylist(loadedPlaylist);
            setTrackPlaylist(listOfTracks);
            setModelPlaylist(listOfModels);

            if ('mediaSession' in navigator) {
                navigator.mediaSession.playbackState = 'playing';
            }
        } catch (error) {
            console.error('Error during track streaming:', error);
        }
    };


    const handlePlayNext = async () => {
        if (currentTrackIndex !== (playlist.length - 1)) {
            const newTrack = trackPlaylist[currentTrackIndex + 1];
            const newModel = modelPlaylist[currentTrackIndex + 1];
            await handlePlayTrack(newTrack, newModel, trackPlaylist, modelPlaylist)
        } else {
            setAudio(null);
            setIsPlaying(false);
            setCurrentTrack(null);
            setTrack(null);
            setPlaylist(null);
            setTrackPlaylist(null);
        }
    };

    const handlePlayPrevious = async () => {
        if (currentTrackIndex !== 0) {
            const newTrack = trackPlaylist[currentTrackIndex - 1];
            const newModel = modelPlaylist[currentTrackIndex - 1];
            await handlePlayTrack(newTrack, newModel, trackPlaylist, modelPlaylist) // Play the new track
        } else {
            setAudio(null);
            setIsPlaying(false);
            setCurrentTrack(null);
            setTrack(null);
            setPlaylist(null);
            setTrackPlaylist(null);
        }
    };

    useEffect(() => {
        async function asyncCall() {
            if (audio) {
                audio.addEventListener('ended', handlePlayNext);
            }
        }
        asyncCall();

        return () => {
            if (audio) {
                audio.removeEventListener('ended', handlePlayNext);
            }
        };
    }, [audio]);

    return { handlePlayTrack, handlePlayNext, handlePlayPrevious };
}



// handle play/pause
export function useHandlePlayPause() {
    const {
        audio,
        isPlaying,
        setIsPlaying,
    } = useContext(AudioContext);

    // Set up an effect to pause or play the audio when isPlaying changes
    useEffect(() => {
        if (audio) {
            if (isPlaying) {
                audio.play();
            } else {
                audio.pause();
            }
        }
    }, [audio, isPlaying]);

    return () => {
        setIsPlaying(!isPlaying);
    }
}


export default AudioContext;
