import React, { useState } from 'react';
import './loginModal.css';
import { useAuth } from "../../contexts/authContext";
import { useNavigate } from "react-router-dom";

const LoginModal = ({ isVisible, onClose }) => {
    const [isLogin, setIsLogin] = useState(true);
    const [formData, setFormData] = useState({
        username: '',
        password: '',
    });
    const { login } = useAuth();
    const { signUp } = useAuth();
    const { error } = useAuth();

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleSubmitLogin = async (e) => {
        e.preventDefault();
        try {
            const { user } = await login(formData);
            if (user) {
                onClose();
            }
        } catch (error) {
        }
    };

    const handleSubmitSignup = async (e) => {
        e.preventDefault();
        try {
            console.log("signing up")
            await signUp(formData);
            onClose();
        } catch (error) {
        }
    };

    return (
        <>
            {isVisible && (
                <div className={"login-modal"}>
                    <p
                        onClick={onClose}
                        style={{fontSize: '13px', color: '#9f9f9f', cursor: 'pointer'}}>close</p>
                    <p className={"login-modal-name"}>{isLogin ? "Login" : "Sign Up"}</p>
                    <h1 className={"login-modal-title"}>
                        {isLogin ? "Welcome Back to Hugging Bass." : "Welcome to Hugging Bass."}
                    </h1>
                    {isLogin ? (
                        <p onClick={() => setIsLogin(false)} className='other-signin-option'>Don't have an account yet, sign up here?</p>
                    ) : (
                        <p onClick={() => setIsLogin(true)} className='other-signin-option'>Already have an account yet, sign in here?</p>
                    )}

                    <div className={""}>
                        <form className='login-form' onSubmit={(e) =>
                            isLogin ? handleSubmitLogin(e) : handleSubmitSignup(e)
                        }>
                            <input
                                onKeyDown={handleKeyPress}
                                name={"username"}
                                className='login-text-entry'
                                type="text"
                                placeholder="Username"
                                value={formData.username}
                                onChange={handleChange}
                            />
                            <input
                                className='login-text-entry'
                                type="password"
                                name="password"
                                placeholder="Password"
                                value={formData.password}
                                onChange={handleChange}
                            />
                            <button className='login-submit' type="submit">{isLogin ? 'Login' : 'Sign Up'}</button>
                            {error && <p className={"error-message"}>{error}</p>}
                        </form>
                    </div>
                </div>
            )}
        </>
    );
}

export default LoginModal;
