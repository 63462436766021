import React from 'react';
import './homeRemixBanner.css';
import {useNavigate} from "react-router-dom";

const HomeRemixBanner = () => {
    const navigate = useNavigate();

    return (
        <div className={"home-remix-banner"}>
            <div className={"remix-banner-info"}>
                <h1 className={"home-remix-banner-title"}>Any Song: Sung by any artist</h1>
                <h2 className={"home-remix-banner-subtitle"}>Make a Cover Today on Hugging Bass.</h2>
            </div>
            <div className={"remix-banner-gifs"}>
                <img src={require('../../assets/images/remix-make.gif')} alt="Remix Banner" />
                <img src={require('../../assets/images/remix-cover.gif')} alt="Remix Banner" />
            </div>
            <button
                onClick={() => navigate('/remix')}
                className="make-remix-button" role="button">
                <span class="text">
                    <img src={require('../../assets/icons/magic.svg').default} alt="Remix Icon" />
                    Create a cover
                </span>
            </button>
        </div>
    )
}

export default HomeRemixBanner;
