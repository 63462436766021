import axios from "axios";
import {API_URL} from "./auth";

export const updateProfilePhoto = async (coverImage, userId) => {
    try {
        const formData = new FormData();
        formData.append('coverImage', coverImage);

        const response = await axios.post(`${API_URL}/routes/user/updateProfilePhoto?userId=${userId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    } catch (error) {
        console.error('Song Upload Error:', error);
        throw error;
    }
};

export const updateUsername = async (username, userId) => {
    try {
        const response = await fetch(`${API_URL}/routes/user/updateUsername?userId=${userId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ newUsername: username })
        });

        return response.json();
    } catch (error) {
        console.error('Song Upload Error:', error);
        throw error;
    }
};

export const updateUrl = async (url, userId) => {
    try {
        const response = await fetch(`${API_URL}/routes/user/updateUrl?userId=${userId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ newUrl: url })
        });

        return response.json();
    } catch (error) {
        console.error('Song Upload Error:', error);
        throw error;
    }
};


export const updatePassword = async (password, userId) => {
    try {
        const response = await fetch(`${API_URL}/routes/user/updatePassword?userId=${userId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ newPassword: password })
        });

        return response.json();
    } catch (error) {
        console.error('Song Upload Error:', error);
        throw error;
    }
};

export const updateSongCoverImage = async (coverImage, songId, userId) => {
    try {
        const formData = new FormData();
        formData.append('coverImage', coverImage);

        const response = await axios.post(`${API_URL}/routes/user/updateSongCover/${songId}?userId=${userId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    } catch (error) {
        console.error('Song Upload Error:', error);
        throw error;
    }
};

export const updateSongTitle = async (title, songId, userId) => {
    try {
        const response = await fetch(`${API_URL}/routes/user/updateSongName/${songId}?userId=${userId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ newTitle: title })
        });

        return response.json();
    } catch (error) {
        console.error('Song Upload Error:', error);
        throw error;
    }
};
