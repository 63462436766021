import React, {useContext} from 'react';
import './songTile.css';
import '../../../styles.css'
import AudioContext from '../../../contexts/audioContext';
import pauseImage from "../../../assets/images/pause.png";
import playImage from "../../../assets/images/play.png";
import {useNavigate} from "react-router-dom";
import {logEvent} from "../../../App";

const SongTile = ({ song, status, showArtist, handlePlayPause }) => {
    const navigate = useNavigate();
    const { currentTrack, isPlaying} = useContext(AudioContext);
    // const [isFollowingState, setIsFollowingState] = useState(false)
    // const [isFavorited, setIsFavorited] = useState(false)
    // const [favoriteCount, setFavoriteCount] = useState(0);

    // set initial
    // useEffect(() => {
    //     if (status){
    //         setIsFollowingState(status?.isFollowing)
    //         setIsFavorited(status?.isFavorited)
    //         setFavoriteCount(status?.favoriteCount)
    //     }
    // }, [status])


    return (
        <div className={'song-tile'}>
            <div onClick={handlePlayPause} alt={song?.title} style={{backgroundImage: `url("${song?.coverImageUrl}")`}} className={"song-tile-image-container"}>
                <div className={"high-five-play-button"}>
                    <img src={isPlaying && currentTrack?._id === song?._id ? pauseImage : playImage} alt={"play"} />
                </div>
            </div>
            <div className={'song-tile-info-section'}>
                <h6
                    onClick={() => {
                        logEvent('Song Page Viewed', { songId: song._id, songName: song.name })
                        navigate(`/song/${song?._id}`)}}
                >{song?.name}</h6>
                {showArtist === false ? (
                    <p>
                        {song?.streamCount} streams
                    </p>
                ) : (
                    <p>
                        {song?.artist}
                    </p>
                )}
            </div>
        </div>
    );
};

export default SongTile;
