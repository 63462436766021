export const API_URL = 'https://opus-api-beta-ijvhsb7soq-uc.a.run.app';

export const handleSignup = async (userData) => {
    const response = await fetch(`${API_URL}/routes/auth/signup`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Error signing up');
    }
    return data;
};
export const handleLogin = async (userData) => {
    const response = await fetch(`${API_URL}/routes/auth/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Error logging in');
    }
    return data;
};
