import {API_URL} from "../auth";

export const checkMultipleSongFavoriteStatuses = async (userId, songIds) => {
    const response = await fetch(`${API_URL}/routes/status/song/checkFavoriteStatuses?userId=${userId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({songIds})
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Error checking favorite statuses');
    }
    return data.favoriteStatuses;
};

export const toggleFavoriteSong = async (userId, targetSongId) => {
    const response = await fetch(`${API_URL}/routes/status/song/favoriteSong?userId=${userId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ targetSongId })
    });

    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Error toggling favorite status');
    }
    return data;
};

export const checkSongProcessingStatus = async (processingId) => {
    const response = await fetch(`${API_URL}/routes/status/song/checkProcessingStatus/${processingId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    });
    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Error checking processing status');
    }
    return data;
};
