import React, {useContext, useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import Cookies from 'js-cookie';
import './songScreen.css';
import {useHandlePlayTrack} from "../../contexts/audioContext";
import {fetchSongFromId} from "../../api/get/song";
import pauseImage from "../../assets/images/pause.png";
import playImage from "../../assets/images/play.png";
import AudioContext from "../../contexts/audioContext";
import {useTheme} from "../../contexts/themeContext";
import {toggleFavoriteSong} from "../../api/status/song";
import {useAuth} from "../../contexts/authContext";
import {toggleFavoriteModel} from "../../api/status/model";
import star from "../../assets/images/star.png";
import {downloadModelFiles} from "../../helpers";
import {logEvent} from "../../App";
import SongTile from "../../components/tiles/songTile/songTile";
import LoginModal from "../../components/modals/loginModal";
import {updateProfilePhoto, updateSongCoverImage, updateSongTitle} from "../../api/user";
import ContentEditable from "react-contenteditable";
import Header from "../../components/sidebar/header";

const SongScreen = () => {
    const {activeUser} = useAuth();
    const {theme} = useTheme();
    const { currentTrack, isPlaying, setIsPlaying} = useContext(AudioContext);
    const { handlePlayTrack } = useHandlePlayTrack();
    const { songId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [song, setSong] = useState(null);
    const [songIsRemix, setSongIsRemix] = useState(false);
    const [songStatus, setSongStatus] = useState(null);
    const [favoriteCount, setFavoriteCount] = useState(0);
    const [models, setModels] = useState(null);
    const [modelStatuses, setModelStatuses] = useState(null);
    const [songsUsingModels, setSongsUsingModels] = useState([]);
    const [modelFavoriteCounts, setModelFavoriteCounts] = useState(0);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isPasswordTrue, setIsPasswordTrue] = useState(Cookies.get(`${songId}`));
    const [songPagePassword, setSongPagePassword] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    // changing song
    const [titleIsEditing, setTitleIsEditing] = useState(false);
    const titleEditableRef = useRef(null);
    const [newTitle, setNewTitle] = useState('');


    useEffect(() => {
        const handleFetchSongFromId = async () => {
            try {
                const songData = await fetchSongFromId(songId, activeUser?._id);
                setSong(songData.song);
                setFavoriteCount(songData.song.favoriteCount);
                setSongIsRemix(songData.song.isRemix);
                setSongStatus(songData.songStatus);
                setSongsUsingModels(songData.songsUsingModels);
                setModels(songData.songModels);
                setModelFavoriteCounts(songData.songModels.map(model => model.favoriteCount));
                setModelStatuses(songData.modelStatuses);
            } catch (error) {
                console.error('Error fetching song:', error);
            }
        };

        if (songId) {
            handleFetchSongFromId();
        }
    }, [songId]);

    const handleDownloadModelFiles = async (event, model) => {
        logEvent('Model Downloaded', { location: "Song Screen", modelId: model._id, modelName: model.name });
        event.stopPropagation();
        setIsDownloading(true);
        await downloadModelFiles(model);
        setIsDownloading(false);
    };

    const handleClickBack = () => {
        if (location.state && location.state.from) {
            navigate(location.state.from);
        } else {
            navigate('/');
        }
    };

    const handleGoToCreator = (event) => {
        event.stopPropagation();
        logEvent('Creator Page Viewed', { creatorName: song.artist });
        navigate(`/creator/${song?.artist}`);
    };

    const handlePlayPause = (song, model, songs, models) => {
        if (isPlaying && currentTrack?._id === song._id) {
            setIsPlaying(!isPlaying);
        } else if (currentTrack?._id === song._id) {
            setIsPlaying(true);
        } else {
            logEvent('Song Played', { location: "Song Page", songId: song._id, songName: song.name, pageSongId: songId });
            handlePlayTrack(song, model, songs, models);
        }
    };

    const handleToggleFavoriteSong = async () => {
        try {
            logEvent('Song Favorite Toggled', { location: "Song Screen", songId: song._id, songName: song.name });
            if (!activeUser) {
                setIsModalOpen(true);
                return;
            }
            setSongStatus(!songStatus);
            if (songStatus) {
                setFavoriteCount(favoriteCount - 1);
            } else {
                setFavoriteCount(favoriteCount + 1);
            }
            await toggleFavoriteSong(activeUser?._id, song?._id);
        } catch (error) {
            console.error('Favorite/Unfavorite Error:', error);
        }
    };

    const handleToggleFavoriteModel = async (index) => {
        try {
            if (!activeUser) {
                setIsModalOpen(true);
                return;
            }
            const selectedModel = Array.isArray(models) && models.length > 0 ? models[index] : null;
            if (selectedModel) {
                logEvent('Model Favorite Toggle', { location: "Song Screen", modelId: selectedModel._id, modelName: selectedModel.name });
                let newModelStatuses = [...modelStatuses];
                newModelStatuses[index] = !modelStatuses[index];
                setModelStatuses(newModelStatuses);
                let newModelFavoriteCounts = [...modelFavoriteCounts];
                if (modelStatuses[index]) {
                    newModelFavoriteCounts[index] = (modelFavoriteCounts[index] - 1);
                } else {
                    newModelFavoriteCounts[index] = (modelFavoriteCounts[index] + 1);
                }
                setModelFavoriteCounts(newModelFavoriteCounts);
                await toggleFavoriteModel(activeUser?._id, selectedModel._id);
            } else {
                console.error('Invalid model data');
            }
        } catch (error) {
            console.error('Favorite/Unfavorite Error:', error);
        }
    };

    const handleCheckPassword = (password) => {
        if (password === song?.songPassword) {
            setIsPasswordTrue(true);
            Cookies.set(`${songId}`, true);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleUpdateCoverImage = async (coverFile) => {
        const response = await updateSongCoverImage(coverFile, song?._id, activeUser?._id);
        if (response) {
            setSong(response.song);
        }
    };

    const handleUpdateTitle = async (title) => {
        const response = await updateSongTitle(title, song?._id, activeUser?._id);
        if (response) {
            setSong(response.song);
        }
    };

    const handleDropSongCoverArt = (event) => {
        event.preventDefault();
        handleUpdateCoverImage(event.dataTransfer.files[0]);
    };

    const handleSongCoverArtChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            handleUpdateCoverImage(e.target.files[0]);
        }
    };

    const handleTitleKeyDown = async (event) => {
        if (event.keyCode === 32) {
            event.stopPropagation();
        }
        if (event.key === 'Enter') {
            event.preventDefault();
            setTitleIsEditing(false);
            const newTitle = event.target.textContent.replace(/&nbsp;/g, ' ');
            if (newTitle && newTitle.trim() !== '') {
                await handleUpdateTitle(newTitle);
            }
        }
    };

    const handleTitleBlur = async () => {
        setTitleIsEditing(false);
        const updatedTitle = titleEditableRef.current.innerText;
        if (updatedTitle.trim() !== '' && updatedTitle !== song?.name) {
            await handleUpdateTitle(updatedTitle);
        } else {
            console.log("condition not met")
        }
    };

    const handleTitleClick = () => {
        setTitleIsEditing(true);
        setNewTitle(song?.name || '');
    };

    return (
        <>
            <Helmet>
                <title>{`Listen to ${song?.name} by ${song?.artist} on Hugging Bass`}</title>
                <meta name="description" content={`Listen to ${song?.name} by ${song?.artist} on Hugging Bass`} />
                <meta property="og:title" content={`Play ${song?.name} on Hugging Bass`} />
                <meta property="og:description" content={`Listen to ${song?.name} by ${song?.artist} on Hugging Bass`} />
                <meta property="og:image" content={song?.coverImageUrl} />
                <meta property="og:url" content={`https://huggingbass.com/songs/${songId}`} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={`Play ${song?.name} on Hugging Bass`} />
                <meta name="twitter:description" content={`Listen to ${song?.name} by ${song?.artist} on Hugging Bass`} />
                <meta name="twitter:image" content={song?.coverImageUrl} />
            </Helmet>
            {!isPasswordTrue && song?.isPrivate === true ? (
                <div className="upload-password-page">
                    <h1>This song is private</h1>
                    <input
                        placeholder="Enter the password to view it"
                        value={songPagePassword}
                        onChange={(e) => setSongPagePassword(e.target.value)}
                    />
                    <button onClick={() => handleCheckPassword(songPagePassword)}>
                        Enter password (case sensitive)
                    </button>
                </div>
            ) : (
                <div className="song-screen">
                    <Header />
                    <div className="song-highlight-section">
                        {activeUser?._id === song?.uploaderId ? (
                            <div
                                className="image-drop-area"
                                onDragOver={handleDragOver}
                                onDrop={handleDropSongCoverArt}>
                                {song?.coverImageUrl ? (
                                    <>
                                        <input
                                            type="file"
                                            id="fileInput"
                                            onChange={handleSongCoverArtChange}
                                        />
                                        <img
                                            className={"preview-image"}
                                            src={song?.coverImageUrl}
                                            alt="Preview"
                                        />
                                    </>
                                ) : (
                                    <div className="image-drop-message">
                                        <input
                                            type="file"
                                            id="fileInput"
                                            onChange={handleSongCoverArtChange}
                                        />
                                        <label className="photo-input">Select or drop cover art</label>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <img className="song-highlight-cover" src={song?.coverImageUrl} alt={song?.name} />
                        )}
                        <div className="song-highlight-details-section">
                            {songIsRemix && (
                                <p className={"song-screen-remix-identifier"}>Cover</p>
                            )}
                            {activeUser?._id === song?.uploaderId ? (
                                <>
                                    {titleIsEditing ? (
                                        <ContentEditable
                                            innerRef={titleEditableRef}
                                            disabled={false}
                                            tagName='article'
                                            onKeyDown={handleTitleKeyDown}
                                            onBlur={handleTitleBlur}
                                            className={"account-editable-username"}
                                            html={newTitle}
                                            onChange={(e) => setNewTitle(e.target.value)}
                                        />
                                    ) : (
                                        <p className={"account-username"} onClick={handleTitleClick}>{song?.name}</p>
                                    )}
                                </>
                            ) : (
                                <p className="song-screen-title">{song?.name}</p>

                            )}
                            {!songIsRemix ? (
                                <p onClick={handleGoToCreator} className="song-screen-creator">{song?.artist}</p>
                            ) : (
                                <p className="song-screen-creator-remix">
                                    <span
                                        className="song-screen-creator"
                                        onClick={() => navigate(`/creator/${song?.remixedArtist}`)}>{song?.remixedArtist}
                                    </span> + <span
                                    className="song-screen-creator"
                                    onClick={() => navigate(`/creator/${song?.artist}`)}>{song?.artist}</span>
                                </p>
                            )}
                        </div>

                    </div>
                    <div className="song-details-section">
                        <div onClick={() => handlePlayPause(song, models[0], [song], [models[0]])} className="song-screen-play-button">
                            <img src={isPlaying && currentTrack?._id === song?._id ? pauseImage : playImage} alt="play" />
                        </div>
                        {!songIsRemix && (
                            <div
                                onClick={() => {
                                    if (activeUser) {
                                        navigate(`/remix`, { state: { starterSong: song }});
                                    } else {
                                        setIsModalOpen(true)
                                    }
                                }}
                                className={"song-screen-remix-container"}>
                                <img src={require('../../assets/icons/magic.svg').default} alt="remix" />
                                <p>Cover</p>
                            </div>
                        )}
                        <div className="song-screen-heart-button" onClick={handleToggleFavoriteSong}>
                            {
                                songStatus === true ? (
                                    <img src={require('../../assets/images/heart-fill.svg').default} alt="upvote" />
                                ) : (
                                    <img style={{filter: `${theme === 'light' ? 'invert(1)' : ''}`}} src={require('../../assets/images/light-heart.svg').default} alt="upvote" />
                                )
                            }
                        </div>
                        <p className="song-screen-stream-count">{song?.streamCount} streams</p>
                    </div>
                    <div className="song-screen-content-section">
                        {models && models?.length > 0 && models.map((model, index) => (
                            <div key={index} className="song-screen-model-tile">
                                <div className="song-screen-model-info">
                                    <p className="song-screen-model-title">Model</p>
                                    <p
                                        onClick={() => {
                                            logEvent('Model Page Viewed', { modelId: model._id, modelName: model.name });
                                            navigate(`/model/${model?._id}`);
                                        }}
                                        className="song-screen-model-name">{model?.name}
                                    </p>
                                    <p
                                        onClick={() => {
                                            logEvent('Creator Page Viewed', { creatorName: model.creator });
                                            navigate(`/creator/${model?.creator}`);
                                        }}
                                        className="song-screen-model-creator">{model?.creator}
                                    </p>
                                    <div className="model-tile-actions-row">
                                        <button
                                            onClick={(e) => handleDownloadModelFiles(e, model)}
                                            className={`model-tile-download ${isDownloading ? 'disabled' : ''}`}
                                            disabled={isDownloading}>
                                            {isDownloading ? 'Downloading...' : 'Get Model'}
                                        </button>
                                        {modelStatuses && modelFavoriteCounts && (
                                            <div className="song-screen-model-heart-button" onClick={() => handleToggleFavoriteModel(index)}>
                                                {
                                                    modelStatuses[index] === true ? (
                                                        <img src={require('../../assets/images/heart-fill.svg').default} alt="upvote" />
                                                    ) : (
                                                        <img style={{filter: `${theme === 'light' ? 'invert(1)' : ''}`}} src={require('../../assets/images/light-heart.svg').default} alt="upvote" />
                                                    )
                                                }
                                                <p>{modelFavoriteCounts[index]}</p>
                                            </div>
                                        )}
                                    </div>
                                    <p className="song-screen-model-score-title">Model Score:</p>
                                    {model?.qualityScore ? (
                                        <div className={`song-screen-score-container ${Math.round(model?.qualityScore) >= 80 ? 'song-screen-green' : ''}`}>
                                            <img src={star} alt="star" />
                                            <p className="home-tile-score">{Math.round(model.qualityScore)}%</p>
                                        </div>
                                    ) : (
                                        <p className="not-rated">Rating Coming Soon...</p>
                                    )}
                                </div>
                                <div className="song-screen-model-songs">
                                    {songsUsingModels[index] && songsUsingModels[index].length > 0 && (
                                        songsUsingModels[index].slice(0, 3).map((song, subIndex) => (
                                            <div key={subIndex} className="song-screen-model-song">
                                                <div onClick={() => handlePlayPause(song, model, songsUsingModels[index].slice(0, 3), [model, model, model])} style={{backgroundImage: `url("${song?.coverImageUrl}")`}} className="model-song-tile-cover">
                                                    <div className="high-five-play-button">
                                                        <img src={isPlaying && currentTrack?._id === song?._id ? pauseImage : playImage} alt="play" />
                                                    </div>
                                                </div>
                                                <p onClick={() => { logEvent('Song Page Viewed', { songId: song._id, songName: song.name }); navigate(`/song/${song?._id}`); }} className="model-song-title">{song?.name}</p>
                                                <p onClick={() => { logEvent('Creator Page Viewed', { creatorName: song.artist }); navigate(`/creator/${song?.artist}`); }} className="model-song-artist">{song?.artist}</p>
                                            </div>
                                        ))
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            <LoginModal isVisible={isModalOpen} onClose={() => setIsModalOpen(false)} />
        </>
    );
};

export default SongScreen;
