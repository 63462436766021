import React, {useEffect, useState} from 'react';
import './topCreators.css';
import LoginModal from "../../modals/loginModal";
import {useQuery} from "react-query";
import {fetchSongsAndModelsTopCreatorsDay} from "../../../api/fetches";
import {useAuth} from "../../../contexts/authContext";
import {useNavigate} from "react-router-dom";

const TopCreators = () => {
    const { activeUser } = useAuth();
    const navigate = useNavigate();
    const { data } = useQuery(['songsAndModelsTopCreatorsDay', activeUser?._id], () => fetchSongsAndModelsTopCreatorsDay({userId: activeUser?._id}));
    const [isModalOpen, setIsModalOpen] = useState(false);
    // data
    const [topCreators, setTopCreators] = useState([]);
    const [topCreatorStreamsWeek, setTopCreatorStreamsWeek] = useState([])

    // fetch data
    useEffect(() => {
        if (data) {
            const creatorsData = data.topCreatorsData[0];
            setTopCreators(creatorsData?.map(creator => creator.user));
            setTopCreatorStreamsWeek(creatorsData?.map(creator => creator.streamCount))
        }
    }, [data]);

    return (
        <>
            <div className={"top-creators"}>
                <div className={"top-songs-title-row"}>
                    <h1 className={"top-creators-title"}> This Week's Top Creators:</h1>
                </div>
                <div className={"top-creator"}>
                    <p className={"top-creator-rank"}>
                        #1
                    </p>
                    <img className={"top-creator-pfp"} src={topCreators[0]?.profilePhotoUrl} alt={"pfp"}/>
                    <div className={"top-creator-info"}>
                        <p
                            onClick={() => navigate(`/creator/${topCreators[0]?.username}`)}
                            className={"top-creator-name"}>
                            {topCreators[0]?.username}
                        </p>
                        <p className={"top-creator-streams"}>
                            {topCreatorStreamsWeek[0]} streams
                        </p>
                    </div>
                </div>
                {topCreators.slice(1, 5).map((creator, index) => (
                    <div className={"runner-up-creator"}>
                        <p className={"top-creator-rank"}>
                            #{index + 2}
                        </p>
                        <img className={"top-creator-pfp"} src={topCreators[index + 1]?.profilePhotoUrl} alt={"pfp"}/>
                        <div className={"top-creator-info"}>
                            <p
                                onClick={() => navigate(`/creator/${topCreators[index + 1]?.username}`)}
                                className={"top-creator-name"}>
                                {topCreators[index + 1]?.username}
                            </p>
                            <p className={"top-creator-streams"}>
                                {topCreatorStreamsWeek[index + 1]} streams
                            </p>
                        </div>
                    </div>
                ))}
            </div>
            <LoginModal isVisible={isModalOpen} onClose={() => setIsModalOpen(false)} />
        </>
);
}

export default TopCreators;
