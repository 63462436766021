import {API_URL} from "../auth";

export const fetchTopCreatorsWeek = async (userId) => {
    const response = await fetch(`${API_URL}/routes/get/creators/topCreatorsWeek?userId=${userId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    });
    return await response.json();
};
