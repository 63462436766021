import {API_URL} from "../auth";

const uploadFileInChunks = async (file, chunkSize, additionalData) => {
    const totalChunks = Math.ceil(file.size / chunkSize);
    const responses = [];
    for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
        const start = chunkIndex * chunkSize;
        const end = Math.min(start + chunkSize, file.size);
        const chunk = file.slice(start, end);

        const formData = new FormData();
        formData.append('chunk', chunk);
        formData.append('chunkIndex', chunkIndex);
        formData.append('totalChunks', totalChunks);
        formData.append('fileName', file.name);
        for (const [key, value] of Object.entries(additionalData)) {
            formData.append(key, value);
        }

        const response = await fetch(`${API_URL}/routes/upload/models/uploadChunk`, {
            method: 'POST',
            body: formData,
        });

        const responseData = await response.json();
        responses.push(responseData);

        if (chunkIndex === totalChunks - 1) {
            return responseData;
        }
    }
    return responses;
};

export const uploadModel = async (modelName, songId, modelCreator, modelCreatorUrl, referenceTrack) => {
    const formData = new FormData();
    formData.append('name', modelName);
    formData.append('description', modelName);
    formData.append('songId', songId);
    formData.append('creator', modelCreator);
    formData.append('creatorUrl', modelCreatorUrl);
    referenceTrack.forEach(file => {
        formData.append('referenceTrack', file);
    })
    const response = await fetch(`${API_URL}/routes/upload/models/upload`, {
        method: 'POST',
        body: formData
    });

    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Error uploading model');
    }
    return data;
};

export const uploadModelInChunks = async (modelName, songId, modelCreator, modelCreatorUrl, referenceTrackFile, modelZipFiles = []) => {
    const chunkSize = 28 * 1024 * 1024;

    const additionalData = {
        name: modelName,
        description: modelName,
        songId: songId,
        creator: modelCreator,
        creatorUrl: modelCreatorUrl,
    };

    let referenceTrackResponse = null;
    if (referenceTrackFile) {
        referenceTrackResponse = await uploadReferenceTrackInChunks(referenceTrackFile, { ...additionalData, fileType: 'referenceTrack' });
        additionalData.referenceTrackUrl = referenceTrackResponse.referenceTrackUrl;
    }

    let modelResponse = null;
    for (let i = 0; i < modelZipFiles.length; i++) {
        modelResponse = await uploadFileInChunks(modelZipFiles[i], chunkSize, { ...additionalData, fileType: 'modelZipFile' });
    }
    return modelResponse;
};

const uploadReferenceTrackInChunks = async (referenceTrackFile, additionalData) => {
    const chunkSize = 28 * 1024 * 1024;
    return await uploadFileInChunks(referenceTrackFile, chunkSize, additionalData);
};

export const uploadHuggingfaceUrl = async (huggingfaceUrl, uploaderId, uploaderUsername, image) => {
    try {
        const formData = new FormData();
        formData.append('huggingFaceRepoId', huggingfaceUrl);
        formData.append('uploaderId', uploaderId);
        formData.append('uploaderUsername', uploaderUsername);
        formData.append('modelCoverImage', image);

        const response = await fetch(`${API_URL}/routes/upload/models/uploadFromHuggingFace`, {
            method: 'POST',
            body: formData
        });

        if (!response.ok) {
            throw new Error('Error uploading from huggingface');
        }

        return await response.json();
    } catch (error) {
        console.error('Search Error:', error);
        throw error;
    }
};
export const uploadModelPost = async (modelId, postContent, image, uploaderID) => {
    const formData = new FormData();
    formData.append('modelId', modelId);
    formData.append('content', postContent);
    formData.append('uploaderID', uploaderID);
    formData.append('postImage', image);

    const response = await fetch(`${API_URL}/routes/upload/models/uploadPost`, {
        method: 'POST',
        body: formData
    });

    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Error uploading post');
    }
    return data;
}

export const handleProcessInferenceAndScore = async (modelId) => {
    const response = await fetch(`${API_URL}/routes/upload/models/processInferenceAndScore`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ modelId })
    });

    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Error uploading model');
    }
    return data;
}
