import React, { useEffect, useState, useRef } from 'react';
import './accountScreen.css';
import { useAuth } from "../../contexts/authContext";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchAlsoByArtistProfile } from "../../api/get/song";
import { updatePassword, updateProfilePhoto, updateUrl, updateUsername } from "../../api/user";
import ContentEditable from "react-contenteditable";
import Header from "../../components/sidebar/header";
import SongList from "../../components/lists/SongList/songList";
import ModelList from "../../components/lists/modelList/modelList";

const AccountScreen = () => {
    const { activeUser, handleSetNewActiveUser } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();

    const [userProfile, setUserProfile] = useState(null);
    const [artistUrl, setArtistUrl] = useState(null)
    const [copied, setCopied] = useState(false)
    // artist details
    const [totalLikes, setTotalLikes] = useState(0)
    const [totalStreams, setTotalStreams] = useState(0)
    const [totalDownloads, setTotalDownloads] = useState(0)
    // artist content
    const [songsByArtist, setSongsByArtist] = useState([]);
    const [songsByArtistStatuses, setSongsByArtistStatuses] = useState([]);
    const [songModelsByArtist, setSongModelsByArtist] = useState([]);
    const [songModelsByArtistStatuses, setSongModelsByArtistStatuses] = useState([]);
    const [modelsByArtist, setModelsByArtist] = useState([]);
    const [modelsByArtistStatuses, setModelsByArtistStatuses] = useState([]);
    const [songsUsingModels, setSongsUsingModels] = useState([]);
    const [hasFetchedAlsoByArtist, setHasFetchedAlsoByArtist] = useState(false);
    // management
    const [usernameIsEditing, setUsernameIsEditing] = useState(false);
    const [newUsername, setNewUsername] = useState('');
    const [urlIsEditing, setUrlIsEditing] = useState(false);
    const [newUrl, setNewUrl] = useState('');

    const contentEditableRef = useRef(null);
    const urlEditableRef = useRef(null);

    // Password modal state
    const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');

    useEffect(() => {
        if (activeUser) {
            const handleFetchAlsoByArtists = async () => {
                const alsoBy = await fetchAlsoByArtistProfile(activeUser?.username, activeUser?._id);
                setArtistUrl(alsoBy.artistUrl);
                setUserProfile(alsoBy.user);

                setSongsByArtist(alsoBy.songs.map(song => song.song));
                setSongsByArtistStatuses(alsoBy.songs.map(song => song.isFavorite));
                setSongModelsByArtist(alsoBy.songModels.map(model => model.model));
                setSongModelsByArtistStatuses(alsoBy.songModels.map(model => model.isFavorite));

                setModelsByArtist(alsoBy.models.map(model => model.model));
                setModelsByArtistStatuses(alsoBy.models.map(model => model.isFavorite));

                setSongsUsingModels(alsoBy.songsUsingModels);
                setTotalStreams(alsoBy.totalStreams);
                setTotalLikes(alsoBy.totalLikes);
                setTotalDownloads(alsoBy.totalDownloads);

                setHasFetchedAlsoByArtist(true);
            };
            handleFetchAlsoByArtists();
        }
    }, [activeUser]);

    const handleClickBack = () => {
        if (location.state && location.state.from) {
            navigate(location.state.from);
        } else {
            navigate('/');
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleUpdateProfilePhoto = async (coverFile) => {
        const response = await updateProfilePhoto(coverFile, activeUser?._id);
        if (response) {
            setUserProfile(response.user);
            handleSetNewActiveUser(response.user);
        }
    };

    const handleUpdateUsername = async (username) => {
        try {
            const response = await updateUsername(username, activeUser?._id);
            setUserProfile(response.user);
            handleSetNewActiveUser(response.user);
        } catch (error) {
            console.error('Error updating username:', error);
        }
    };

    const handleUpdatePassword = async (password) => {
        try {
            const response = await updatePassword(password, activeUser?._id);
            setUserProfile(response.user);
            handleSetNewActiveUser(response.user);
        } catch (error) {
            console.error('Error updating password:', error);
        }
    };

    const handleUpdateUrl = async (url) => {
        try {
            const response = await updateUrl(url, activeUser?._id);
            setUserProfile(response.user);
            handleSetNewActiveUser(response.user);
        } catch (error) {
            console.error('Error updating URL:', error);
        }
    };

    const handleDropSongCoverArt = (event) => {
        event.preventDefault();
        handleUpdateProfilePhoto(event.dataTransfer.files[0]);
    };

    const handleSongCoverArtChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            handleUpdateProfilePhoto(e.target.files[0]);
        }
    };

    const handleUsernameClick = () => {
        setUsernameIsEditing(true);
        setNewUsername(userProfile?.username || '');
    };

    const handleUsernameKeyDown = async (event) => {
        if (event.keyCode === 32) {
            event.stopPropagation();
        }
        if (event.key === 'Enter') {
            event.preventDefault();
            setUsernameIsEditing(false);
            const newTitle = event.target.textContent.replace(/&nbsp;/g, ' ');
            if (newTitle && newTitle.trim() !== '') {
                await handleUpdateUsername(newTitle);
            }
        }
    };

    const handleUsernameBlur = async () => {
        setUsernameIsEditing(false);
        const updatedUsername = contentEditableRef.current.innerText;
        if (updatedUsername.trim() !== '' && updatedUsername !== userProfile?.username) {
            await handleUpdateUsername(updatedUsername);
        } else {
            console.log("condition not met")
        }
    };

    const handleUrlClick = () => {
        setUrlIsEditing(true);
        setNewUrl(userProfile?.profileUrl || '');
    };

    const handleUrlKeyDown = async (event) => {
        if (event.keyCode === 32) {
            event.stopPropagation();
        }
        if (event.key === 'Enter') {
            event.preventDefault();
            setUrlIsEditing(false);
            const newTitle = event.target.textContent.replace(/&nbsp;/g, ' ');
            if (newTitle && newTitle.trim() !== '') {
                await handleUpdateUrl(newTitle);
            }
        }
    };

    const handleUrlBlur = async () => {
        setUrlIsEditing(false);
        const updatedUrl = urlEditableRef.current.innerText;
        if (updatedUrl.trim() !== '' && updatedUrl !== userProfile?.username) {
            await handleUpdateUrl(updatedUrl);
        } else {
            console.log("condition not met")
        }
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(`https://www.huggingbass.com/creator/${userProfile?.username}`)
            .then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 2000);
            })
            .catch(err => console.error('Failed to copy text: ', err));
    };

    // Handle password change
    const handlePasswordChange = async () => {
        if (newPassword !== repeatPassword) {
            setPasswordError('Passwords do not match');
            return;
        }
        setPasswordError('');
        await handleUpdatePassword(newPassword);
        setIsPasswordModalOpen(false);
        setNewPassword('');
        setRepeatPassword('');
    };

    return (
        <>
            {activeUser && (
                <div className={"account-screen"}>
                    <Header
                        dontShowSearch={true}
                    />
                    <div className={"account-info-header"}>
                        <div
                            className="image-drop-area"
                            onDragOver={handleDragOver}
                            onDrop={handleDropSongCoverArt}>
                            {userProfile?.profilePhotoUrl ? (
                                <>
                                    <input
                                        type="file"
                                        id="fileInput"
                                        onChange={handleSongCoverArtChange}
                                    />
                                    <img
                                        className={"preview-image"}
                                        src={userProfile?.profilePhotoUrl}
                                        alt="Preview"
                                    />
                                </>
                            ) : (
                                <div className="image-drop-message">
                                    <input
                                        type="file"
                                        id="fileInput"
                                        onChange={handleSongCoverArtChange}
                                    />
                                    <label className="photo-input">Select or drop cover art</label>
                                </div>
                            )}
                        </div>
                        <>
                            <div className={"account-info-column"}>
                                {usernameIsEditing ? (
                                    <ContentEditable
                                        innerRef={contentEditableRef}
                                        disabled={false}
                                        tagName='article'
                                        onKeyDown={handleUsernameKeyDown}
                                        onBlur={handleUsernameBlur}
                                        className={"account-editable-username"}
                                        html={newUsername}
                                        onChange={(e) => setNewUsername(e.target.value)}
                                    />
                                ) : (
                                    <p className={"account-username"} onClick={handleUsernameClick}>{userProfile?.username}</p>
                                )}
                                <div className={"account-profile-url-container"}>
                                    <img src={require('../../assets/images/link.png')} alt="verified" />
                                    {urlIsEditing ? (
                                        <ContentEditable
                                            innerRef={urlEditableRef}
                                            disabled={false}
                                            tagName='article'
                                            onKeyDown={handleUrlKeyDown}
                                            onBlur={handleUrlBlur}
                                            className={"account-editable-url"}
                                            html={newUrl}
                                            onChange={(e) => setNewUrl(e.target.value)}
                                        />
                                    ) : (
                                        <p className={""} onClick={handleUrlClick}>{userProfile?.profileUrl}</p>
                                    )}
                                </div>
                                <div className={"creator-stats-row account-stats-row"}>
                                    <div className={"creator-stats-pill first-pill"}>
                                        <h1>
                                            {totalLikes}
                                        </h1>
                                        <p>Likes</p>
                                    </div>
                                    <div className={"creator-stats-pill"}>
                                        <h1>
                                            {totalStreams}
                                        </h1>
                                        <p>Streams</p>
                                    </div>
                                    <div className={"creator-stats-pill"}>
                                        <h1>
                                            {totalDownloads}
                                        </h1>
                                        <p>Downloads</p>
                                    </div>
                                    <div className="creator-share-pill" onClick={handleCopy} style={{ cursor: 'pointer' }}>
                                        <img
                                            style={{ filter: `${'invert(1)'}` }}
                                            src={require('../../assets/images/share.png')}
                                            alt="share"
                                        />
                                        {copied && <span className="copied-text">Link copied!</span>}
                                    </div>
                                </div>
                                <button className={"change-password-button"} onClick={() => setIsPasswordModalOpen(true)}>
                                    Change password
                                </button>
                            </div>
                        </>
                    </div>
                    <div className={"creator-screen-content"}>
                        {songsByArtist && songsByArtist.length > 0 && (
                            <div className={"creator-screen-content-section"}>
                                <h1>
                                    Top Songs
                                </h1>
                                <SongList
                                    pageLocation={"Creator Page"}
                                    songs={songsByArtist}
                                    models={songModelsByArtist}
                                    statuses={songsByArtistStatuses}
                                    showArtists={false} />
                            </div>
                        )}
                        {modelsByArtist && modelsByArtist.length > 0 && (
                            <div className={"creator-screen-content-section"}>
                                <h1>
                                    Top Models
                                </h1>
                                <ModelList
                                    pageLocation={"Creator Page"}
                                    songsFromModels={songsUsingModels}
                                    models={modelsByArtist}
                                    statuses={modelsByArtistStatuses}
                                    showCreators={false}/>
                            </div>
                        )}
                    </div>
                    {isPasswordModalOpen && (
                        <div className="password-modal">
                            <div className="password-modal-content">
                                <h2>Change Password</h2>
                                <input
                                    type="password"
                                    placeholder="New Password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />
                                <input
                                    type="password"
                                    placeholder="Repeat New Password"
                                    value={repeatPassword}
                                    onChange={(e) => setRepeatPassword(e.target.value)}
                                />
                                {passwordError && <p className="error">{passwordError}</p>}
                                <button className={"submit-change-password"} onClick={handlePasswordChange}>Confirm</button>
                                <button onClick={() => setIsPasswordModalOpen(false)}>Cancel</button>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

export default AccountScreen;
