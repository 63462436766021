import React from 'react';
import './sortOptions.css';
const SortOptions = ({ sortOption, handleChangeSortOptions }) => {
    return (
        <div className="sort-options-container">
            <div className={"sort-options-buttons"}>
                <button
                    className={sortOption === 'recent' ? 'active' : ''}
                    onClick={() => handleChangeSortOptions('recent')}
                >
                    Recent
                </button>
                <button
                    className={sortOption === 'mostUpvoted' ? 'active' : ''}
                    onClick={() => handleChangeSortOptions('mostUpvoted')}
                >
                    Most Upvoted
                </button>
                <button
                    className={sortOption === 'mostDownloaded' ? 'active' : ''}
                    onClick={() => handleChangeSortOptions('mostDownloaded')}
                >
                    Most Downloaded
                </button>
                <button
                    className={sortOption === 'mostStreamed' ? 'active' : ''}
                    onClick={() => handleChangeSortOptions('mostStreamed')}
                >
                    Most Streamed
                </button>
            </div>
        </div>
    );
};

export default SortOptions;
