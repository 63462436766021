import React from 'react';
import './searchBar.css';

const SearchBar = ({ label, query, setQuery, placeholder }) => {
    return (
        <div className="search-bar">
            <label>{label}</label>
            <input
                type="text"
                value={query}
                placeholder={placeholder}
                onChange={(e) => setQuery(e.target.value)}
            />
        </div>
    );
};

export default SearchBar;
