import {API_URL} from "./auth";

export const updateStreamCount = async (trackId, userId, trackOwnerId) => {
    try {
        const response = await fetch(`${API_URL}/routes/song/updateStreamCount/${trackId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId, trackOwnerId }),
        });

        if (!response.ok) {
            throw new Error('Error updating stream count');
        }
    } catch (error) {
        console.error('Error updating stream count:', error);
    }
};
