import React, {useEffect} from 'react';
import {BrowserRouter, Route, Routes, useLocation} from 'react-router-dom';
import { AuthProvider } from './contexts/authContext';
import { AudioProvider} from "./contexts/audioContext";
import { ThemeProvider, useTheme } from "./contexts/themeContext";
import { Analytics } from '@vercel/analytics/react';
import UploadScreen from './screens/upload/uploadScreen';
import LoginScreen from './screens/auth/loginScreen';
import SignUpScreen from './screens/auth/signupScreen';
import HomeScreen from "./screens/home/homeScreen";
import CreatorScreen from "./screens/artistScreen/creatorScreen";
import TestQualityScreen from "./screens/testQuality";
import {QueryClient, QueryClientProvider} from "react-query";
import Player from "./components/player/player";
import SongScreen from "./screens/songScreen/songScreen";
import ModelScreen from "./screens/modelScreen/modelScreen";
import * as amplitude from '@amplitude/analytics-browser';
import TopSongsScreen from "./screens/topSongs/topSongsScreen";
import RemixScreen from "./screens/remix/remixScreen";
import FavoriteSongsScreen from "./screens/favoriteSongs/favoriteSongsScreen";
import AccountScreen from "./screens/account/accountScreen";

export const logEvent = (eventName, eventProperties = {}) => {
    amplitude.track(eventName, eventProperties);
};



const Main = () => {
    const AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY;
    amplitude.init(AMPLITUDE_API_KEY);

    const location = useLocation();
    const { theme } = useTheme();
    useEffect(() => {
        const metaThemeColor = document.querySelector('meta[name=theme-color]');
        const themeColor = getComputedStyle(document.documentElement)
            .getPropertyValue('--background-color').trim();
        if (metaThemeColor && themeColor) {
            metaThemeColor.setAttribute("content", themeColor);
        }
    }, [theme]);

    const noPlayerRoutes = ['/song', '/creator', '/model', '/upload', '/testQuality', '/login', '/signup'];
    const showPlayer = !noPlayerRoutes.some(route => location.pathname.includes(route));


    return (
        <div data-theme={theme}>
            <Routes>
                <Route path="/" element={<HomeScreen />} />
                <Route path="/home" element={<HomeScreen />}/>
                <Route path="/upload" element={<UploadScreen />} />
                <Route path="/login" element={<LoginScreen />} />
                <Route path="/signup" element={<SignUpScreen/>} />
                <Route path={"/creator/:creatorName"} element={<CreatorScreen />} />
                <Route path={"/model/:modelId"} element={<ModelScreen />} />
                <Route path={"/testQuality"} element={<TestQualityScreen />} />
                <Route path={"/song/:songId"} element={<SongScreen />} />
                <Route path={"/topSongs"} element={<TopSongsScreen />} />
                <Route path={"/remix"} element={<RemixScreen />} />
                <Route path={'/myFavorites'} element={<FavoriteSongsScreen />} />
                <Route path={'/account'} element={<AccountScreen />} />
            </Routes>
            <Player showPlayer={showPlayer} />
        </div>
    );
};
const App = () => {
    const queryClient = new QueryClient()

    return (
        <QueryClientProvider client={queryClient}>
            <AuthProvider>
                <ThemeProvider>
                    <AudioProvider>
                        <BrowserRouter>
                            <Analytics />
                            <Main />
                        </BrowserRouter>
                    </AudioProvider>
                </ThemeProvider>
            </AuthProvider>
        </QueryClientProvider>
    );
};

export default App;
