import React from 'react';
import './searchResultItem.css';
import heartFilledIcon from '../../assets/icons/heartFilled.svg';
import starFilledIcon from '../../assets/icons/starFilled.svg';
import playSongIcon from '../../assets/icons/playSong.svg';
import streamCountIcon from '../../assets/icons/streamCount.svg';

const SearchResultItem = ({ item, onClick, isSelected, onPlay }) => {
    return (
        <div className={`search-result-item ${isSelected ? 'selected' : ''}`} onClick={onClick}>
            {item.coverImageUrl && (
                <div className="image-container">
                    <img src={item.coverImageUrl} alt={`${item.name} cover`} className="item-image" />
                    <img src={playSongIcon} alt="play" className="play-icon" onClick={(e) => { e.stopPropagation(); onPlay(item); }} />
                </div>
            )}
            <div className="item-details">
                <p className="item-name">{item.name}</p>
                <p className="item-creator">by: {item.creator || item.artist}</p>
                <div className="item-stats">
                    {item.streamCount !== undefined && (
                        <>
                            <img className="streamCountIcon" src={streamCountIcon} alt="streams" />
                            <p>{item.streamCount}</p>
                        </>
                    )}
                    {item.streamCount !== undefined ? (
                        <>
                            <img className="favoriteCountIcon" src={heartFilledIcon} alt="likes" />
                            <p>{item.favoriteCount}</p>
                        </>
                        ) : (
                            <>
                                {item.qualityScore ? (
                                    <>
                                        <img className="starFilledIcon" src={starFilledIcon} alt="likes" />
                                        <p>{Math.round(item.qualityScore)}</p>
                                    </>
                                ) : (
                                    <p className={"item-creator"}>No rating yet</p>
                                )}
                            </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SearchResultItem;
