
import './headerOverlayMobile.css';
import { useNavigate } from "react-router-dom";
import { useEffect, useRef } from 'react';

const HeaderOverlay = ({ isVisible, onClose }) => {
    const navigate = useNavigate();
    const overlayRef = useRef(null);

    const handleOutsideClick = (e) => {
        if (e.target === overlayRef.current) {
            onClose();
        }
    };

    useEffect(() => {
        const overlay = overlayRef.current;

        if (isVisible) {
            overlay.classList.add('visible');
        } else {
            overlay.classList.remove('visible');
        }
    }, [isVisible]);

    return (
        <div
            ref={overlayRef}
            className="header-overlay"
            onClick={handleOutsideClick}
        >
            <div className="header-overlay-row">
                <h3>Discover More</h3>
                <button onClick={onClose}>×</button>
            </div>
            <div className="header-overlay-row">
                <p
                    onClick={() => {
                        onClose();
                        navigate('/remix');
                    }}
                >
                    Remix a song
                </p>
            </div>
            <div className="header-overlay-row">
                <p
                    onClick={() => {
                        onClose();
                        navigate('/topSongs');
                    }}
                >
                    Top charts
                </p>
            </div>
            <div className="header-overlay-row">
                <p
                    onClick={() => {
                        onClose();
                        navigate('/myFavorites');
                    }}
                >
                    My favorites
                </p>
            </div>
        </div>
    );
};

export default HeaderOverlay;
