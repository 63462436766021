import React, {useContext} from 'react';
import './songList.css';
import SongTile from "../../tiles/songTile/songTile";
import {useHandlePlayTrack} from "../../../contexts/audioContext";
import AudioContext from "../../../contexts/audioContext";
import {logEvent} from "../../../App";

function SongList({songs, models, statuses, showArtists, pageLocation}) {
    const { currentTrack, isPlaying, setIsPlaying } = useContext(AudioContext);
    const { handlePlayTrack } = useHandlePlayTrack();

    const handlePlayPause = (song, model, songs, models) => {
        if (isPlaying && currentTrack?._id === song._id) {
            setIsPlaying(!isPlaying);
        } else if (currentTrack?._id === song._id) {
            setIsPlaying(true);
        } else {
            logEvent('Song Played', { location: pageLocation, songId: song._id, songName: song.name });
            handlePlayTrack(song, model, songs, models)
        }
    }

    return (
        <div className={'song-list'}>
            {songs.slice(0, 10).slice().map((song, index) => {
                return (
                    <SongTile
                        handlePlayPause={() => handlePlayPause(song, models[index], songs, models)}
                        song={song}
                        status={statuses[index]}
                        showArtist={showArtists}
                        key={song?._id}
                    />
                );
            })}
        </div>
    );
}

export default SongList;
