import React, { useState } from 'react';
import { useAuth } from '../../contexts/authContext';
import { useNavigate } from 'react-router-dom';
import './signupScreen.css'

const SignUpScreen = () => {
    const [formData, setFormData] = useState({
        username: '',
        password: '',
    });
    const [error, setError] = useState('');
    const { signUp } = useAuth();
    const navigate = useNavigate();

    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await signUp(formData);
            navigate('/');
        } catch (error) {
            console.error('Error during signup:', error);
            setError("Invalid email/username and/or password")
        }
    };

    return (
        <div className = 'signup-screen'>
            <p>Hugging Bass.</p>
            <h1>
                Welcome to Hugging Bass.
            </h1>
            <a href="/login" className = 'no-account-text'>Already have an account yet, sign in here?</a>

            <div className={"signup-form-wrapper"}>
                <form className = 'signup-form' onSubmit={handleSubmit}>
                    <input
                        name={"username"}
                        className = 'signup-text-box'
                        type="text"
                        placeholder="Username"
                        value={formData.username}
                        onChange={handleChange}
                    />
                    <input
                        className = 'signup-text-box'
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={formData.password}
                        onChange={handleChange}
                    />
                    <button className= 'signup-button' type="submit">Sign Up</button>
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                </form>
            </div>
        </div>
    );
};

export default SignUpScreen;
