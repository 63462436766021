import './horizontalModelTile.css'
import React, {useEffect, useState} from "react";
import {useAuth} from "../../../contexts/authContext";
import {useNavigate} from "react-router-dom";
import {useTheme} from "../../../contexts/themeContext";
import {logEvent} from "../../../App";
import LoginModal from "../../modals/loginModal";
import {toggleFavoriteModel} from "../../../api/status/model";

const HorizontalModelTile = ({ model, status, rank }) => {
    const { theme } = useTheme();
    const {activeUser} = useAuth();
    const navigate = useNavigate();
    const [favoriteStatus, setFavoriteStatus] = useState(status);
    const [favoriteCount, setFavoriteCount] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        setFavoriteCount(model?.favoriteCount);
        setFavoriteStatus(status);
    }, [model, status]);

    const handleToggleFavoriteModel = async () => {
        try {
            logEvent('Model Favorite Toggled', { location: "Top Model Tile", modelId: model._id, modelName: model.name });
            if (!activeUser) {
                setIsModalOpen(true);
                return;
            }
            setFavoriteStatus(!favoriteStatus);
            if (favoriteStatus) {
                setFavoriteCount(favoriteCount - 1);
            } else {
                setFavoriteCount(favoriteCount + 1);
            }
            await toggleFavoriteModel(activeUser?._id, model?._id);
        } catch (error) {
            console.error('Favorite/Unfavorite Error:', error);
        }
    };

    return (
        <>
            <div className={"horizontal-model-tile"}>
                <p className={"horizontal-model-rank"}>{rank}</p>
                <div className={"horizontal-model-info"}>
                    <h1
                        onClick={() => {
                            navigate(`/model/${model?._id}`)
                        }}
                        title={model?.name} className={"horizontal-model-title"}>{model?.name}</h1>
                    <h2 onClick={() => {
                        navigate(`/creator/${model?.creator}`)
                    }} className={"horizontal-model-creator"}>{model?.creator}</h2>
                </div>
                <div className={"horizontal-model-stats"}>
                    <p className={"horizontal-model-download-count"}>{Math.round(model?.qualityScore)}%</p>
                    <div onClick={handleToggleFavoriteModel} className={"top-model-tile-heart-pill"}>
                        <p>{favoriteCount}</p>
                        {favoriteStatus === true ? (
                            <img className={"top-song-tile-heart"} src={require('../../../assets/images/heart-fill.svg').default} alt="upvote"/>
                        ) : (
                            <img className={"top-song-tile-heart"} style={{filter: `${theme === 'light' ? 'invert(1)' : ''}`}} src={require('../../../assets/images/light-heart.svg').default} alt="upvote"/>
                        )}
                    </div>
                </div>
            </div>
            <LoginModal isVisible={isModalOpen} onClose={() => setIsModalOpen(false)} />
        </>
    )
}

export default HorizontalModelTile;
