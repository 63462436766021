import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
const viewportContent = isIOS
    ? 'width=device-width, initial-scale=1, maximum-scale=1'
    : 'width=device-width, initial-scale=1, shrink-to-fit=no';
const metaTag = document.createElement('meta');
metaTag.name = 'viewport';
metaTag.content = viewportContent;
document.head.appendChild(metaTag);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
