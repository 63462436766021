import React, {useEffect, useState} from 'react';
import './modelScreen.css';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {fetchModelsFromIds} from "../../api/get/model";
import {useTheme} from "../../contexts/themeContext";
import {fetchTopSongsFromModel} from "../../api/get/model";
import SongList from "../../components/lists/SongList/songList";
import {downloadModelFiles} from "../../helpers";
import {logEvent} from "../../App";
import {useAuth} from "../../contexts/authContext";

const ModelScreen = () => {
    const {theme} = useTheme()
    const {modelId} = useParams()
    const { activeUser } = useAuth();
    const navigate = useNavigate();
    const location = useLocation()
    const [isDownloading, setIsDownloading] = useState(false)

    // data
    const [modelData, setModelData] = useState([]);
    const [songsFromModel, setSongsFromModel] = useState([]);
    const [songsFromModelStatuses, setSongsFromModelStatuses] = useState([]);
    const [linkSrc, setLinkSrc] = useState(null)

    const [copied, setCopied] = useState(false)

    // styles
    const imageStyles = {
        filter: theme === 'light' ? 'invert(0)' : 'invert(1)',
    }

    useEffect(() => {
        if (modelData.model?.creatorUrl?.includes('youtube.com')) {
            setLinkSrc(require('../../assets/images/yt-icon.png'));
        } else if (modelData.model?.creatorUrl?.includes('weights.gg')) {
            setLinkSrc(require('../../assets/images/weights-icon.png'));
        } else if (modelData.model?.creatorUrl?.includes('huggingface.co')) {
            setLinkSrc(require('../../assets/images/hf-icon.png'));
        } else if (modelData.model?.creatorUrl?.includes('instagram.com')) {
            setLinkSrc(require('../../assets/images/ig-icon.png'));
        }
    }, [modelData]);

    useEffect(() => {
        const fetchModels = async () => {
            const models = await fetchModelsFromIds([modelId], activeUser?._id);
            const topSongs = await fetchTopSongsFromModel(models[0].model[0]._id, activeUser?._id)
            setSongsFromModel(topSongs?.songs?.map(song => song.song))
            setSongsFromModelStatuses(topSongs?.songs?.map(song => song.isFavorite))
            setModelData(models[0].model[0]);
        }

        if (modelId) {
            fetchModels();
        }
    }, [modelId]);

    const handleClickBack = () => {
        if (location.state && location.state.from) {
            navigate(location.state.from);
        } else {
            navigate('/');
        }
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(`https://www.huggingbass.com/model/${modelId}`)
            .then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 2000);
            })
            .catch(err => console.error('Failed to copy text: ', err));
    };

    const handleDownloadModelFiles = async (event) => {
        logEvent('Model Downloaded', { location: "Model Screen", modelId: modelData._id, modelName: modelData.name });
        event.stopPropagation()
        setIsDownloading(true)
        await downloadModelFiles(modelData)
        setIsDownloading(false)
    };

    return (
        <div className="model-screen">
            <div className={"model-screen-artist-details"}>
                <button className={"model-go-back"} onClick={handleClickBack}>
                    Back
                </button>
                <p className={"model-name"}>
                    {modelData?.name}
                </p>
                <p
                    onClick={() => {
                        logEvent('Creator Page Viewed', { creatorName: modelData.creator })
                        navigate(`/creator/${modelData?.creator}`)
                    }}
                    className={"model-creator"}>
                    by: {modelData?.creator}
                </p>
                <div className={"model-links-row"}>
                    <div
                        onClick={() => {
                            window.open(modelData?.creatorUrl,  '_blank', 'noopener noreferrer');
                        }}
                        className={"model-screen-link-pill"}>
                        <img style={imageStyles} src={require('../../assets/images/link.png')} alt="link"/>
                        {linkSrc && (
                            <img
                                style={{filter: `${linkSrc.includes('weights.gg') && theme === 'light' ? 'invert(1)' : 'invert(0)'}`}}
                                src={linkSrc} alt="link"/>
                        )}
                    </div>
                    <button
                        className={`model-screen-download-button ${isDownloading ? 'disabled' : ''}`}
                        onClick={(e) => {
                            handleDownloadModelFiles(e)
                        }}>
                        {isDownloading ? "Downloading... " : "Download Model"}
                    </button>
                    <div className={"model-stats-row"}>
                        <div className={"model-stats-pill first-pill"}>
                            <h1>
                                {modelData?.favoriteCount}
                            </h1>
                            <p>Likes</p>
                        </div>
                        <div className={"model-stats-pill"}>
                            <h1>
                                {modelData?.downloadCount}
                            </h1>
                            <p>Downloads</p>
                        </div>
                    </div>
                    <div className="model-share-pill" onClick={handleCopy} style={{ cursor: 'pointer' }}>
                        <img
                            style={{ filter: `${theme !== 'light' ? 'invert(1)' : ''}` }}
                            src={require('../../assets/images/share.png')}
                            alt="share"
                        />
                        {copied && <span className="copied-text">Link copied!</span>}
                    </div>
                </div>
            </div>
            <div className={"model-screen-content"}>
                {songsFromModel && songsFromModel.length > 0 && (
                    <div className={"model-screen-content-section"}>
                        <h1>
                            Top Songs
                        </h1>
                        <SongList
                            pageLocation={"Model Screen"}
                            songs={songsFromModel}
                            models={Array(songsFromModel.length).fill(modelData.model)}
                            statuses={songsFromModelStatuses}
                        />
                    </div>
                )}
            </div>
        </div>

    )
}

export default ModelScreen;
