import JSZip from "jszip";
import { saveAs } from 'file-saver';
import {API_URL} from "./api/auth";
import {AuthProvider, useAuth} from "./contexts/authContext";

export const downloadModelFiles = async (model) => {
    let dataResponse;
    if (!model || !model.fileUrls || model.fileUrls.length === 0) {
        console.error('No files to download');
        return;
    }

    fetch(`${API_URL}/routes/model/increaseDownloadCount/${model._id}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        }
    }).then(response => response.json())
        .then(data => dataResponse = data)
        .catch(error => console.error('Error incrementing download count:', error));


    try {
        const zip = new JSZip();

        for (const fileUrl of model.fileUrls) {
            const response = await fetch(fileUrl);
            const blob = await response.blob();
            const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
            zip.file(fileName, blob);
        }

        const zipBlob = await zip.generateAsync({ type: 'blob' });
        saveAs(zipBlob, `${model?.name}.zip`);
    } catch (error) {
        console.error('Error generating ZIP:', error);
    }

    return dataResponse;
};

export const checkFormStatus = (uploadType, isUsingExistingModel, songName, songFile, songCoverArt, modelNames, modelZipFiles, modelCreators, modelCreatorUrls, origin, prompt, styleOfMusic, selectedExistingModels, youtubeUrl, referenceTracks, modelUsedCount) => {
//     console.log(`
// Form State:
// ---------------
// Upload Type: ${uploadType}
//
// Using Existing Model(s):
// - Model 1: ${isUsingExistingModel[0] ? 'Yes' : 'No'}
// - Model 2: ${isUsingExistingModel[1] ? 'Yes' : 'No'}
//
// Song Details:
// - Song Name: ${songName || 'N/A'}
// - Song File: ${songFile ? 'Provided' : 'Not Provided'}
// - Artist Name: ${artistName || 'N/A'}
// - Artist URL: ${artistUrl || 'N/A'}
// - Song Cover Art: ${songCoverArt ? 'Provided' : 'Not Provided'}
// - YouTube URL: ${youtubeUrl || 'N/A'}
//
// Model Details:
// - Model Names: ${modelNames?.length > 0 ? modelNames.join(', ') : 'N/A'}
// - Model Zip Files: ${
//         modelZipFiles?.length > 0
//             ? modelZipFiles.map((file, index) => `Model ${index + 1}: ${file?.length > 0 ? 'Provided' : 'Not Provided'}`).join(', ')
//             : 'N/A'
//     }
// - Model Creators: ${modelCreators?.length > 0 ? modelCreators.join(', ') : 'N/A'}
// - Model Creator URLs: ${modelCreatorUrls?.length > 0 ? modelCreatorUrls.join(', ') : 'N/A'}
//
// Other Details:
// - Origin: ${origin || 'N/A'}
// - Prompt: ${prompt || 'N/A'}
// - Style of Music: ${styleOfMusic || 'N/A'}
// - Selected Existing Models: ${selectedExistingModels?.length > 0 ? selectedExistingModels.join(', ') : 'N/A'}
// - Reference Tracks: ${
//         referenceTracks?.length > 0
//             ? referenceTracks.map((track, index) => `Model ${index + 1}: ${track?.length > 0 ? track.join(', ') : 'N/A'}`).join(', ')
//             : 'N/A'
//     }
// - Model Used Count: ${modelUsedCount}
// `);
    if (uploadType === 'model-with-song' && !isUsingExistingModel[0] && modelUsedCount === 1) {
        return !!(songName && songFile && songCoverArt && modelNames?.length > 0 && modelCreators?.length > 0 && modelCreatorUrls?.length > 0 && modelZipFiles[0] && referenceTracks[0]);
    }
    if (uploadType === 'model-with-song' && isUsingExistingModel[0] && modelUsedCount === 1) {
        return !!(songName && songFile && songCoverArt && selectedExistingModels[0]);
    }
    if (uploadType === 'model-with-song' && !isUsingExistingModel[0] && !isUsingExistingModel[1] && modelUsedCount === 2) {
        return !!(songName && songFile && songCoverArt && modelNames?.length > 1 && modelCreators?.length > 1 && modelCreatorUrls?.length > 1 && modelZipFiles[0] && modelZipFiles[1] && referenceTracks[0] && referenceTracks[1]);
    }
    if (uploadType === 'model-with-song' && isUsingExistingModel[0] && !isUsingExistingModel[1] && modelUsedCount === 2) {
        return !!(songName && songFile  && songCoverArt && selectedExistingModels[0] && modelNames?.length > 0 && modelCreators?.length > 0 && modelCreatorUrls?.length > 0 && modelZipFiles[1] && referenceTracks[1]);
    }
    if (uploadType === 'model-with-song' && !isUsingExistingModel[0] && isUsingExistingModel[1] && modelUsedCount === 2) {
        return !!(songName && songFile && songCoverArt && modelNames?.length > 0 && modelCreators?.length > 0 && modelCreatorUrls?.length > 0 && modelZipFiles[0] && selectedExistingModels[1] && referenceTracks[0]);
    }
    if (uploadType === 'model-with-song' && isUsingExistingModel[0] && isUsingExistingModel[1] && modelUsedCount === 2) {
        return !!(songName && songFile && songCoverArt && selectedExistingModels[0] && selectedExistingModels[1]);
    }

    if (uploadType === 'song-from-youtube' && !isUsingExistingModel[0] && modelUsedCount === 1) {
        return !!(youtubeUrl && modelNames?.length > 0  && modelCreators?.length > 0  && modelCreatorUrls?.length > 0 && modelZipFiles[0] && referenceTracks[0]);
    }
    if (uploadType === 'song-from-youtube' && isUsingExistingModel && modelUsedCount === 1) {
        return !!(youtubeUrl && selectedExistingModels[0]);
    }
    if (uploadType === 'song-from-youtube' && !isUsingExistingModel[0] && !isUsingExistingModel[1] && modelUsedCount === 2) {
        return !!(youtubeUrl && modelNames?.length > 1  && modelCreators?.length > 1  && modelCreatorUrls?.length > 1 && modelZipFiles[0] && modelZipFiles[1] && referenceTracks[0] && referenceTracks[1]);
    }
    if (uploadType === 'song-from-youtube' && isUsingExistingModel[0] && !isUsingExistingModel[1] && modelUsedCount === 2) {
        return !!(youtubeUrl && selectedExistingModels[0] && modelNames?.length > 0  && modelCreators?.length > 0  && modelCreatorUrls?.length > 0 && modelZipFiles[1] && referenceTracks[1]);
    }
    if (uploadType === 'song-from-youtube' && !isUsingExistingModel[0] && isUsingExistingModel[1] && modelUsedCount === 2) {
        return !!(youtubeUrl && modelNames?.length > 0  && modelCreators?.length > 0  && modelCreatorUrls?.length > 0 && modelZipFiles[0] && selectedExistingModels[1] && referenceTracks[0]);
    }
    if (uploadType === 'song-from-youtube' && isUsingExistingModel[0] && isUsingExistingModel[1] && modelUsedCount === 2) {
        return !!(youtubeUrl && selectedExistingModels[0] && selectedExistingModels[1]);
    }
    if (uploadType === 'evaluate-model') {
        return true
    }
    return false;
}

export const getUserId = () => {
    const { GetUserId } = require(AuthProvider)
    return GetUserId()
}

