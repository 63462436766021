import {API_URL} from "../auth";

export const fetchAlsoByArtist = async (username, userId) => {
    const response = await fetch(`${API_URL}/routes/get/songs/alsoByUsername/${username}?userId=${userId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    });
    return await response.json();
}

export const fetchAlsoByArtistProfile = async (username, userId) => {
    const response = await fetch(`${API_URL}/routes/get/songs/alsoByUsernameProfile/${username}?userId=${userId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    });
    return await response.json();
}

export const fetchSongFromId = async (songId, userId) => {
    const response = await fetch(`${API_URL}/routes/get/songs/songFromId/${songId}?userId=${userId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    });
    return await response.json();
}

export const fetchLatestSongs = async (pageParam = 1, userId) => {
    const response = await fetch(`${API_URL}/routes/get/songs/recentSongs?page=${pageParam}&limit=20&userId=${userId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    });
    return await response.json();
};

export const fetchMostStreamedSongWeek = async (userId) => {
    const response = await fetch(`${API_URL}/routes/get/songs/mostStreamedWeek?userId=${userId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    });
    return await response.json();
};

export const fetchMostStreamedSongsAll = async (userId) => {
    const response = await fetch(`${API_URL}/routes/get/songs/mostStreamedAll?userId=${userId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    });
    return await response.json();
};

export const fetchAllFavorited = async (userId) => {
    const response = await fetch(`${API_URL}/routes/get/songs/myFavorites?userId=${userId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    });
    return await response.json();
};

export const searchSongs = async (searchString) => {
    const response = await fetch(`${API_URL}/routes/get/songs/searchSongs?search=${encodeURIComponent(searchString)}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Error searching songs: ${response.status}`);
    }
};

export const searchSongsForRemix = async (searchString) => {
    const response = await fetch(`${API_URL}/routes/get/songs/searchSongsForRemix?search=${encodeURIComponent(searchString)}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if (response.ok) {
        return await response.json();
    } else {
        throw new Error(`Error searching songs: ${response.status}`);
    }
};

