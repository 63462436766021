import React, { useEffect, useState } from 'react';
import './uploadScreen.css'
import { useAuth } from '../../contexts/authContext';
import {useNavigate} from "react-router-dom";
import {handleProcessInferenceAndScore, uploadModelInChunks} from "../../api/upload/model";
import {uploadSong, uploadSongFromPrompt, uploadSongInChunks, uploadYouTubeUrl} from "../../api/upload/song";
import {checkFormStatus} from "../../helpers";
import {attachModelToSong, handleAttachReferenceTrack} from "../../api/upload/helpers";
import {searchModels} from "../../api/get/model";
import {checkSongProcessingStatus} from "../../api/status/song";
import {logEvent} from "../../App";



const youtubeIcon = require('../../assets/images/youtube.png');

const UploadScreen = () => {
    const navigate = useNavigate();
    const { activeUser } = useAuth();
    const [uploadType, setUploadType] = useState('song-from-youtube');
    const [isFullyCompletedForm, setIsFullyCompletedForm] = useState(false);
    const [isUploadingUsingExistingModel, setIsUploadingUsingExistingModel] = useState([false, false]);

    // model with song
    // song
    const [songName, setSongName] = useState('');
    const [songFile, setSongFile] = useState(null);
    const [songCoverArt, setSongCoverArt] = useState(null);
    const [songCoverArtPreview, setSongCoverArtPreview] = useState(null);
    const [prompt, setPrompt] = useState('');
    const [styleOfMusic, setStyleOfMusic] = useState('');
    const [origin, setOrigin] = useState('');
    const [youtubeUrl, setYoutubeUrl] = useState('');
    const [isPrivate, setIsPrivate] = useState(false);
    const [songPassword, setSongPassword] = useState('')

    // model
    const [modelsUsedCount, setModelsUsedCount] = useState(1);
    const [modelNames, setModelNames] = useState(['','']);
    const [modelCreators, setModelCreators] = useState(['','']);
    const [modelCreatorUrls, setModelCreatorUrls] = useState(['','']);
    const [modelZipFiles, setModelZipFiles] = useState([null, null]);
    const [searchModelsQuery, setSearchModelsQuery] = useState('');
    const [modelSearchResults, setModelSearchResults] = useState([]);
    const [selectedExistingModels, setSelectedExistingModels] = useState([null, null]);
    const [referenceTracks, setReferenceTracks] = useState([null, null]);

    const [songUploadStatus, setSongUploadStatus] = useState(false);
    const [modelUploadStatus, setModelUploadStatus] = useState(false);
    const [attachUploadStatus, setAttachUploadStatus] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [hasProcessedUpload, setHasProcessedUpload] = useState(false);

    // evaluate
    const [referenceTrack, setReferenceTrack] = useState([]);
    const [selectedExistingModel, setSelectedExistingModel] = useState(null);

    const [uploadStep, setUploadStep] = useState(0);

    const handleUploadOptionClick = (type) => {
        setUploadType(type);
    };

    // manage search
    useEffect(() => {
        if (searchModelsQuery !== '') {
            const handleSearchModels = async () => {
                const results = await searchModels(searchModelsQuery);
                setModelSearchResults(results);
            };
            handleSearchModels();
        }
    }, [searchModelsQuery, isUploadingUsingExistingModel]);

    // manage form
    useEffect(() => {
        const isModelFullyCompleted = checkFormStatus(
            uploadType,
            isUploadingUsingExistingModel,
            songName,
            songFile,
            songCoverArt,
            modelNames,
            modelZipFiles,
            modelCreators,
            modelCreatorUrls,
            origin,
            prompt,
            styleOfMusic,
            selectedExistingModels,
            youtubeUrl,
            referenceTracks,
            modelsUsedCount
            );
        setIsFullyCompletedForm(isModelFullyCompleted);

    }, [modelNames, isUploadingUsingExistingModel, modelCreators, modelCreatorUrls, origin, prompt, styleOfMusic, songName, songFile, songCoverArt, uploadType, selectedExistingModels, youtubeUrl, referenceTracks, modelZipFiles, modelsUsedCount]);
    // manage changing form
    const handleSongFileChange = (e) => {
        setSongFile(e.target.files[0]);
    };
    const handleSongCoverArtChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSongCoverArt(file);
            setSongCoverArtPreview(URL.createObjectURL(file));
        }
    };

    const handleDropSongCoverArt = (event) => {
        event.preventDefault();
        setSongCoverArt(event.dataTransfer.files[0]);
        setSongCoverArtPreview(URL.createObjectURL(event.dataTransfer.files[0]));
    };

    const handleReferenceTrackChange = (e, index) => {
        if (index === 0) {
            setReferenceTracks([e.target.files[0], referenceTracks[1]]);
        } else {
            setReferenceTracks([referenceTracks[0], e.target.files[0]]);
        }
    };

    const handleZIpFileChange = (e, index) => {
        if (index === 0) {
            setModelZipFiles([Array.from(e.target.files), modelZipFiles[1]])
        } else {
            setModelZipFiles([modelZipFiles[0], Array.from(e.target.files)])
        }

    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    // const handleUploadModel = async (e) => {
    //     e.preventDefault();
    //
    //     if (!isFullyCompletedForm) {
    //         setErrorMessage('Please fill out all required fields.');
    //         return;
    //     }
    //
    //     try {
    //         setIsUploading(true);
    //         setErrorMessage('');
    //
    //         const modelUploadResponse = await uploadModel(modelName, "", modelCreator, modelCreatorUrl, referenceTrack, modelZipFile);
    //         console.log('Model uploaded:', modelUploadResponse);
    //         const uploadedModel = modelUploadResponse.model
    //
    //         const inferenceResponse = await handleProcessInferenceAndScore(uploadedModel?._id)
    //         console.log('Inference response:', inferenceResponse);
    //         setIsUploading(false);
    //         navigate('/');
    //     } catch (error) {
    //         console.error('Upload error:', error);
    //         setErrorMessage(error.message || 'Failed to upload model and song.');
    //     } finally {
    //         setIsUploading(false);
    //     }
    // }

    const handleEvaluateModel = async (e) => {
        e.preventDefault()
        if (!isFullyCompletedForm) {
            setErrorMessage('Please fill out all required fields.');
            return;
        }
        try {
            const appendReferenceTrackResponse = await handleAttachReferenceTrack(selectedExistingModel?._id, referenceTrack)
            const updatedModel = appendReferenceTrackResponse.model
            const inferenceResponse = await handleProcessInferenceAndScore(updatedModel?._id)
            console.log('Inference response:', inferenceResponse);
            setIsUploading(false);
            navigate('/')
        } catch (error) {
            console.error('Upload error:', error);
            setErrorMessage(error.message || 'Failed to upload model and song.');
        } finally {
            setIsUploading(false);
        }
    }
    const getAudioDuration = (file) => {
        return new Promise((resolve) => {
            const audio = new Audio();
            audio.onloadedmetadata = () => {
                resolve(audio.duration);
            };
            audio.src = URL.createObjectURL(file);
        });
    };

    const handleUploadModelWithSong = async (e) => {
        e.preventDefault();

        if (!isFullyCompletedForm) {
            setErrorMessage('Please fill out all required fields.');
            return;
        }

        try {
            setIsUploading(true);
            setErrorMessage('');

            let songUploadResponse
            const duration = await getAudioDuration(songFile);
            if (songFile.size > 26214400) {
                songUploadResponse = await uploadSongInChunks(songName, activeUser?.username, activeUser?.profileUrl, songCoverArt, songFile, duration, isPrivate, songPassword, activeUser?._id);
            } else {
                songUploadResponse = await uploadSong(songName, songFile, activeUser?.profileUrl , songCoverArt, activeUser?.username, isPrivate, songPassword, activeUser?._id);
            }
            setSongUploadStatus(true);
            console.log("response: ", songUploadResponse)

            if (!isUploadingUsingExistingModel[0]) {
                let uploadedModel

                const modelUploadResponse = await uploadModelInChunks(modelNames[0], songUploadResponse?.song?._id, modelCreators[0], modelCreatorUrls[0], referenceTracks[0], modelZipFiles[0]);
                uploadedModel = modelUploadResponse.model
                if (modelsUsedCount === 1) {
                    setModelUploadStatus(true)
                }
                await attachModelToSong(uploadedModel?._id, songUploadResponse?.song?._id);
                const inferenceResponse = await handleProcessInferenceAndScore(uploadedModel?._id)
                console.log('Inference response:', inferenceResponse)
                if (modelsUsedCount === 1) {
                    setAttachUploadStatus(true)
                }
            } else {
                if (modelsUsedCount === 1) {
                    setModelUploadStatus(true)
                }
                await attachModelToSong(selectedExistingModels[0]?._id, songUploadResponse?.song?._id);
                if (modelsUsedCount === 1) {
                    setAttachUploadStatus(true)
                }
            }

            if (modelsUsedCount === 2 && !isUploadingUsingExistingModel[1]) {
                let uploadedModel

                const modelUploadResponse = await uploadModelInChunks(modelNames[1], songUploadResponse?.song?._id, modelCreators[1], modelCreatorUrls[1], referenceTracks[1], modelZipFiles[1]);
                uploadedModel = modelUploadResponse.model
                setModelUploadStatus(true)
                await attachModelToSong(uploadedModel?._id, songUploadResponse?.song?._id);
                const inferenceResponse = await handleProcessInferenceAndScore(uploadedModel?._id)
                console.log('Inference response:', inferenceResponse)
                setAttachUploadStatus(true)
            } else if (modelsUsedCount === 2){
                setModelUploadStatus(true)
                await attachModelToSong(selectedExistingModels[1]?._id, songUploadResponse?.song?._id);
                setAttachUploadStatus(true)
            }
        } catch (error) {
            console.error('Upload error:', error);
            setSongUploadStatus(false)
            setModelUploadStatus(false)
            setAttachUploadStatus(false)
            setErrorMessage(error.message || 'Failed to upload model and song.');
            setIsUploading(false);
        } finally {
            setHasProcessedUpload(true);
        }
    };

    const handleUploadYoutubeSongWithModel = async (e) => {
        e.preventDefault();

        if (!isFullyCompletedForm) {
            setErrorMessage('Please fill out all required fields.');
            return;
        }

        try {
            setIsUploading(true);
            setErrorMessage('');

            const songUploadStatus = await uploadYouTubeUrl(youtubeUrl, activeUser?.username, activeUser?._id, activeUser?.profileUrl, isPrivate, songPassword);
            let uploadedSong
            let isUploaded = false
            while (!isUploaded) {
                const songStatus = await checkSongProcessingStatus(songUploadStatus.songProcessId);
                if (songStatus.status === 'done') {
                    isUploaded = true;
                    setSongUploadStatus(true)
                    uploadedSong = songStatus.song
                }
                await new Promise(r => setTimeout(r, 1000));
            }
            if (!isUploadingUsingExistingModel[0]) {
                let uploadedModel
                const modelUploadResponse = await uploadModelInChunks(modelNames[0], uploadedSong?._id, modelCreators[0], modelCreatorUrls[0], referenceTracks[0], modelZipFiles[0]);
                uploadedModel = modelUploadResponse.model;

                if (modelsUsedCount === 1) {
                    setModelUploadStatus(true)
                }
                await attachModelToSong(uploadedModel?._id, uploadedSong?._id);
                const inferenceResponse = await handleProcessInferenceAndScore(uploadedModel._id)
                console.log('Inference response:', inferenceResponse)
                if (modelsUsedCount === 1) {
                    setAttachUploadStatus(true)
                }
            } else {
                if (modelsUsedCount === 1) {
                    setModelUploadStatus(true)
                }
                await attachModelToSong(selectedExistingModels[0]?._id, uploadedSong?._id);
                if (modelsUsedCount === 1) {
                    setAttachUploadStatus(true)
                }
            }

            if (modelsUsedCount === 2 && !isUploadingUsingExistingModel[1]) {
                let uploadedModel
                const modelUploadResponse = await uploadModelInChunks(modelNames[1], uploadedSong?._id, modelCreators[1], modelCreatorUrls[1], referenceTracks[1], modelZipFiles[1]);
                uploadedModel = modelUploadResponse.model;

                setModelUploadStatus(true)
                await attachModelToSong(uploadedModel?._id, uploadedSong?._id);
                const inferenceResponse = await handleProcessInferenceAndScore(uploadedModel._id)
                console.log('Inference response:', inferenceResponse)
                setAttachUploadStatus(true)
            } else if (modelsUsedCount === 2) {
                setModelUploadStatus(true)
                await attachModelToSong(selectedExistingModels[1]?._id, uploadedSong?._id);
                setAttachUploadStatus(true)
            }



        } catch (error) {
            console.error('Upload error:', error);
            setSongUploadStatus(false)
            setModelUploadStatus(false)
            setAttachUploadStatus(false)
            setErrorMessage(error.message || 'Failed to upload model and song.');
            setIsUploading(false);
        } finally {
            setHasProcessedUpload(true);
        }
    };

    const handleUploadSongFromPrompt = async (e) => {
        e.preventDefault();

        if (!isFullyCompletedForm) {
            setErrorMessage('Please fill out all required fields.');
            return;
        }

        try {
            setIsUploading(true);
            setErrorMessage('');

            const songUploadResponse = await uploadSongFromPrompt(songName, songFile, activeUser?.username, activeUser?.profileUrl, songCoverArt, origin, prompt, styleOfMusic);
            console.log('Song uploaded:', songUploadResponse);
            navigate('/');
        } catch (error) {
            console.error('Upload error:', error);
            setIsUploading(false);
            setErrorMessage(error.message || 'Failed to upload song from prompt.');
        } finally {
            setIsUploading(false);
        }
    };

    const handleGoToDiscord = () => {
        window.open('https://discord.gg/JRPz9QSBwa', '_blank', 'noreferrer');
    }


    const handleCheckPrivate = () => {
        setIsPrivate(!isPrivate)
    }

    return (
        <>
            {isUploading && (
                <div className={"upload-status-overlay"}>
                    <h1>{hasProcessedUpload ? "Upload Successful!" : "Upload Underway..."}</h1>
                    <p className={"warning"}>Do not reload the page or your upload will be canceled</p>
                    <div className={"status-container"}>
                        <p>Song</p>
                        {songUploadStatus ? (
                            <div>
                                <img src={require('../../assets/images/check.png')} />
                                <p>Song upload complete!</p>
                            </div>
                        ): (
                            <div>
                                <img src={require('../../assets/images/loading.gif')} />
                                <p>Song upload processing...</p>
                            </div>
                        )}

                    </div>
                    <div className={"status-container"}>
                        <p>Model</p>
                        {modelUploadStatus ? (
                            <div>
                                <img src={require('../../assets/images/check.png')} />
                                <p>Model(s) upload complete!</p>
                            </div>
                        ) : (
                            <div>
                                <img src={require('../../assets/images/loading.gif')} />
                                <p>Model(s) upload processing...</p>
                            </div>
                        )}
                    </div>
                    <div className={"status-container"}>
                        <h1>Finalizing</h1>
                        {attachUploadStatus ? (
                            <div>
                                <img src={require('../../assets/images/check.png')} />
                                <p>Upload successful!</p>
                            </div>
                        ) : (
                            <div>
                                <img src={require('../../assets/images/loading.gif')} />
                                <p>Wrapping up...</p>
                            </div>
                        )}
                    </div>
                    <button
                        disabled={!hasProcessedUpload}
                        onClick={() => navigate('/')}
                        className={`go-back-button ${!hasProcessedUpload ? 'disabled' : ''}`}>
                        Return to Hugging Bass
                    </button>
                </div>
            )}
            <div className='upload-page'>
                <div className={"upload-page-selector-section"}>
                    {uploadStep === 0 ? (
                        <>
                            <div className={"upload-header"}>
                                <button onClick={() => navigate('/')}>Go back</button>
                            </div>
                            <h1 className={"upload-title"}>
                                How do you want to upload your song?
                            </h1>
                            <div className={"upload-options-container"}>
                                {/*<p>Submit a Model</p>*/}
                                {/*<div*/}
                                {/*    className={`upload-option-container ${uploadType === 'model' ? 'active-upload-type' : ''}`}*/}
                                {/*    onClick={() => handleUploadOptionClick('model')}>*/}
                                {/*    <p>New Model</p>*/}
                                {/*</div>*/}
                                {/*<p>Evaluate a Model</p>*/}
                                {/*<div*/}
                                {/*    className={`upload-option-container ${uploadType === 'evaluate-model' ? 'active-upload-type' : ''}`}*/}
                                {/*    onClick={() => handleUploadOptionClick('evaluate-model')}>*/}
                                {/*    <p>From Hugging Bass</p>*/}
                                {/*</div>*/}
                                <p>Submit a Song + Model</p>
                                <div
                                    className={`upload-option-container ${uploadType === 'song-from-youtube' ? 'active-upload-type' : ''}`}
                                    onClick={() => {
                                        setUploadStep(0)
                                        handleUploadOptionClick('song-from-youtube')
                                    }}>
                                    <p>Upload from Youtube</p>
                                    <img src={youtubeIcon} />
                                </div>
                                <div
                                    className={`upload-option-container ${uploadType === 'model-with-song' ? 'active-upload-type' : ''}`}
                                    onClick={() => {
                                        setUploadStep(0)
                                        handleUploadOptionClick('model-with-song')}}>
                                    <p>Upload from MP3</p>
                                </div>
                                {/*<div*/}
                                {/*    className={`upload-option-container ${uploadType === 'song-from-prompt' ? 'active-upload-type' : ''}`}*/}
                                {/*    onClick={() => handleUploadOptionClick('song-from-prompt')}>*/}
                                {/*    <p>Song From Prompt </p>*/}
                                {/*</div>*/}

                            </div>
                        </>
                    ) : (
                        <>
                            {uploadStep === 1 && (uploadType === 'song-from-youtube' || uploadType === 'model-with-song') && (
                                <div className={""}>
                                    <h2 className={"upload-title"}>Model Origin</h2>
                                    <p className={"model-origin-subtitle"}>Which voice model did you use to make this song?</p>
                                    <p className={"model-count-title"}>How many voice models did you use to make this song?</p>
                                    <div className={"model-counts-container"}>
                                        <button
                                            className={`model-count-selector ${modelsUsedCount === 1 && 'active-model-count'}`}
                                            onClick={() => {setModelsUsedCount(1)}}>
                                            1
                                        </button>
                                        <button
                                            className={`model-count-selector ${modelsUsedCount === 2 && 'active-model-count'}`}
                                            onClick={() => {setModelsUsedCount(2)}}>
                                            2
                                        </button>
                                    </div>
                                    {uploadType !== 'model' && (
                                        <>
                                            <p className={"model-identifier"}>First (primary) model origin:</p>
                                            <div className={"upload-options-container"}>
                                                <div
                                                    type="button"
                                                    className={`upload-option-container ${isUploadingUsingExistingModel[0] && 'active-upload-type'}`}
                                                    onClick={() => {
                                                        const newExistingUsingModel = [...isUploadingUsingExistingModel]
                                                        newExistingUsingModel[0] = true
                                                        setIsUploadingUsingExistingModel(newExistingUsingModel)
                                                    }}>
                                                    <p>Search for Existing Model</p></div>
                                                <div
                                                    type="button"
                                                    className={`upload-option-container ${!isUploadingUsingExistingModel[0] && 'active-upload-type'}`}
                                                    onClick={() => {
                                                        const newExistingUsingModel = [...isUploadingUsingExistingModel]
                                                        newExistingUsingModel[0] = false
                                                        setIsUploadingUsingExistingModel(newExistingUsingModel)
                                                    }}>
                                                    <p>
                                                        Upload New Model
                                                    </p>
                                                    </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}
                            {uploadStep === 2 && (uploadType === 'song-from-youtube' || uploadType === 'model-with-song') && (
                                <div className={""}>
                                    <h2 className={"upload-title"}>Second Model Origin</h2>
                                    <p className={"model-origin-subtitle"}>Which voice model did you use to make this song?</p>
                                    {uploadType !== 'model' && (
                                        <>
                                            <p className={"model-identifier"}>Secondary model origin:</p>
                                            <div className={"upload-options-container"}>
                                                <div
                                                    type="button"
                                                    className={`upload-option-container ${isUploadingUsingExistingModel[1] && 'active-upload-type'}`}
                                                    onClick={() => {
                                                        const newExistingUsingModel = [...isUploadingUsingExistingModel];
                                                        newExistingUsingModel[1] = true
                                                        setIsUploadingUsingExistingModel(newExistingUsingModel)
                                                    }}>
                                                    <p>Search for Existing Model</p></div>
                                                <div
                                                    type="button"
                                                    className={`upload-option-container ${!isUploadingUsingExistingModel[1] && 'active-upload-type'}`}
                                                    onClick={() => {
                                                        const newExistingUsingModel = [...isUploadingUsingExistingModel];
                                                        newExistingUsingModel[1] = false;
                                                        setIsUploadingUsingExistingModel(newExistingUsingModel);
                                                    }}>
                                                    <p>
                                                        Upload New Model
                                                    </p>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}
                        </>
                    )}
                </div>
                <div className={"upload-page-form-section"}>
                    <>
                        {uploadType === 'model-with-song' | uploadType === 'song-from-youtube' | uploadType === 'model' && (
                            <form
                                className="upload-form"
                                onSubmit={uploadType === 'model-with-song' ? handleUploadModelWithSong : uploadType === 'song-from-youtube' && handleUploadYoutubeSongWithModel }>
                                {uploadType === 'model-with-song' | uploadType === 'song-from-youtube' && (
                                    <>
                                        {uploadStep === 0 && (
                                            <div className={"upload-form-section"}>
                                                <h1>Step 1</h1>
                                                <h2>Song Details</h2>
                                                {uploadType === 'song-from-youtube' ? (
                                                    <>
                                                        <label className={"form-entry-title"}>Youtube URL</label>
                                                        <input type="text" className="form-text-entry" value={youtubeUrl} placeholder="e.g https://www.youtube.com/watch?v=qBURKrdOOuM" onChange={(e) => setYoutubeUrl(e.target.value)} />
                                                        <p className={"form-entry-description"}>We will extract all the relevant details from the Youtube link (mp3, song name, cover art etc..)</p>
                                                        <label className={"tick-details"}>
                                                            <input
                                                                checked={isPrivate}
                                                                onChange={handleCheckPrivate}
                                                                type={"checkbox"}>
                                                            </input>
                                                            I would like to make this a private song
                                                        </label>
                                                        {isPrivate && (
                                                            <>
                                                                <label className={"form-entry-title"}>Song Password:</label>
                                                                <input type="text" className="form-text-entry" value={songPassword} placeholder="e.g SuperSpecialPassword1123" onChange={(e) => setSongPassword(e.target.value)} />
                                                            </>
                                                        )}
                                                        <div className={"upload-form-navigation-row"}>
                                                            <button
                                                                onClick={() => {
                                                                    logEvent('Upload Step 1 Completed', { uploadType: "YouTube", isPrivate: isPrivate });
                                                                    setUploadStep(uploadStep + 1)
                                                                }}
                                                                className={"upload-form-next-step-bttn"}>
                                                                Next Step
                                                            </button>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <label className={"form-entry-title"}>Song Name</label>
                                                        <input type="text" className="form-text-entry" value={songName} placeholder="e.g Juice Wrld | See You Leave [ RJD2 Ai Cover ]" onChange={(e) => setSongName(e.target.value)} />

                                                        <label className={"form-entry-title"}>Song File (.mp3 / .wav)</label>
                                                        <input type="file" className="form-file-entry" accept="audio/*" onChange={handleSongFileChange} />
                                                        <label className={"tick-details"}>
                                                            <input
                                                                checked={isPrivate}
                                                                onChange={handleCheckPrivate}
                                                                type={"checkbox"}>
                                                            </input>
                                                            I would like to make this a private song
                                                        </label>
                                                        {isPrivate && (
                                                            <>
                                                                <label className={"form-entry-title"}>Song Password:</label>
                                                                <input type="text" className="form-text-entry" value={songPassword} placeholder="e.g SuperSpecialPassword1123" onChange={(e) => setSongPassword(e.target.value)} />
                                                            </>
                                                        )}
                                                        <label className={"form-entry-title"}>Song Cover Art</label>
                                                        <div
                                                            className="image-drop-area"
                                                            onDragOver={handleDragOver}
                                                            onDrop={handleDropSongCoverArt}>
                                                            {songCoverArtPreview ? (
                                                                <>
                                                                    <input
                                                                        type="file"
                                                                        id="fileInput"
                                                                        onChange={handleSongCoverArtChange}
                                                                    />
                                                                    <img
                                                                        className={"preview-image"}
                                                                        src={songCoverArtPreview}
                                                                        alt="Preview"
                                                                    />
                                                                </>
                                                            ) : (
                                                                <div className="image-drop-message">
                                                                    <input
                                                                        type="file"
                                                                        id="fileInput"
                                                                        onChange={handleSongCoverArtChange}
                                                                    />
                                                                    <label className="photo-input">Select or drop cover art</label>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className={"upload-form-navigation-row"}>
                                                            <button
                                                                onClick={() => {
                                                                    logEvent('Upload Step 1 Completed', { uploadType: "mp3", isPrivate: isPrivate });
                                                                    setUploadStep(uploadStep + 1)
                                                                }}
                                                                className={"upload-form-next-step-bttn"}>
                                                                Next Step
                                                            </button>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </>
                                )}
                                {uploadStep === 1 && (
                                    <div className={"upload-form-section"}>
                                        {!isUploadingUsingExistingModel[0] ? (
                                            <>
                                                <h1>Step 2</h1>
                                                <h2>Model Details</h2>

                                                <label className="form-entry-title">Model Name</label>
                                                <input
                                                    type="text"
                                                    className="form-text-entry"
                                                    value={modelNames[0]}
                                                    placeholder="e.g Juice WRLD Voice Model"
                                                    onChange={(e) => setModelNames(prevState => [e.target.value, ...prevState.slice(1)])}
                                                />
                                                <p className="form-entry-description">This is the name you want to give your model</p>

                                                <label className="form-entry-title">Model Creator</label>
                                                <input
                                                    type="text"
                                                    className="form-text-entry"
                                                    value={modelCreators[0]}
                                                    placeholder="e.g Sifts"
                                                    onChange={(e) => setModelCreators(prevState => [e.target.value, ...prevState.slice(1)])}
                                                />
                                                <p className="form-entry-description">This is the name of the model creator</p>

                                                <label className="form-entry-title">Model Creator URL</label>
                                                <input
                                                    type="text"
                                                    className="form-text-entry"
                                                    value={modelCreatorUrls[0]}
                                                    placeholder="e.g https://huggingface.co/Amirkid"
                                                    onChange={(e) => setModelCreatorUrls(prevState => [e.target.value, ...prevState.slice(1)])}
                                                />
                                                <p className="form-entry-description">This is the URL that points to the model creator socials</p>

                                                <label className="form-entry-title">Model Files (Zip)</label>
                                                <input
                                                    type="file"
                                                    className="form-file-entry"
                                                    accept=".zip"
                                                    onChange={(e) => handleZIpFileChange(e, 0)}
                                                />
                                                <p className="form-entry-description">Your model files must include a .PTH and .INDEX file</p>

                                                <label className="form-entry-title">Reference Artist file (mp3 or wav)</label>
                                                <input
                                                    type="file"
                                                    className="form-file-entry"
                                                    accept="audio/*"
                                                    onChange={(e) => {handleReferenceTrackChange(e, 0)}}
                                                />
                                                <p className="form-entry-description">
                                                    This is to help us score the model. Use either the isolated voice or a song (with no features) from the reference artist.
                                                </p>
                                            </>
                                        ) : (
                                            <>
                                                <h1>Step 2</h1>
                                                <h2>Search for existing model on Hugging Bass</h2>
                                                <label className={"form-entry-title"}>Search for Model</label>
                                                <input type="text" className="form-text-entry" value={searchModelsQuery} placeholder="Search for a model..." onChange={(e) => setSearchModelsQuery(e.target.value)} />
                                                {modelSearchResults.length > 0 && (
                                                    <div className="">
                                                        {modelSearchResults.slice(0, 6).map((model, index) => (
                                                            <div onClick={() => {
                                                                setSelectedExistingModels(prevState => {
                                                                    const newState = [...prevState];
                                                                    newState[0] = model;
                                                                    return newState;
                                                                })
                                                            }} className={`existing-model ${selectedExistingModels.indexOf(model) === 0 && 'selected-existing-model'} `}>
                                                                <div className={""}>
                                                                    <p className={""}>{model?.name}</p>
                                                                    <p className={"existing-model-creator"}>by: {model?.creator}</p>
                                                                </div>
                                                                <div className={"existing-model-stats"}>
                                                                    <p>Score: {model?.qualityScore ? Math.round(model.qualityScore) : "Not Rated Yet"}</p>
                                                                    <p className={"existing-model-likes"}>{model?.favoriteCount} likes</p>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                        <div className={"upload-form-navigation-row"}>
                                            <button className={"upload-form-last-step-bttn"} onClick={() => setUploadStep(uploadStep - 1)}>Go Back</button>
                                            {modelsUsedCount === 1 ? (
                                                <button type="submit" className="submit-form" disabled={!isFullyCompletedForm || isUploading}>{isUploading ? 'Uploading... please wait' : 'Upload to Hugging Bass'}</button>
                                            ) : (
                                                <button className={"upload-form-next-step-bttn"} onClick={() => setUploadStep(uploadStep + 1)}>Next Model</button>
                                            )}
                                            {errorMessage && <div className="error-message">{errorMessage}</div>}
                                        </div>
                                    </div>
                                )}
                                {uploadStep === 2 && (
                                    <div className={"upload-form-section"}>
                                        {!isUploadingUsingExistingModel[1] ? (
                                            <>
                                                <h1>Step 3</h1>
                                                <h2>2nd Model Details</h2>

                                                <label className="form-entry-title">Model Name</label>
                                                <input
                                                    type="text"
                                                    className="form-text-entry"
                                                    value={modelNames[1]}
                                                    placeholder="e.g Juice WRLD Voice Model"
                                                    onChange={(e) => setModelNames(prevState => {
                                                        const newState = [...prevState];
                                                        newState[1] = e.target.value;
                                                        return newState;
                                                    })}                                                  />
                                                <p className="form-entry-description">This is the name you want to give your model</p>

                                                <label className="form-entry-title">Model Creator</label>
                                                <input
                                                    type="text"
                                                    className="form-text-entry"
                                                    value={modelCreators[1]}
                                                    placeholder="e.g Sifts"
                                                    onChange={(e) => setModelCreators(prevState => {
                                                        const newState = [...prevState];
                                                        newState[1] = e.target.value;
                                                        return newState;
                                                    })}
                                                />
                                                <p className="form-entry-description">This is the name of the model creator</p>

                                                <label className="form-entry-title">Model Creator URL</label>
                                                <input
                                                    type="text"
                                                    className="form-text-entry"
                                                    value={modelCreatorUrls[1]}
                                                    placeholder="e.g https://huggingface.co/Amirkid"
                                                    onChange={(e) => setModelCreatorUrls(prevState => {
                                                        const newState = [...prevState];
                                                        newState[1] = e.target.value;
                                                        return newState;
                                                    })}                                                />
                                                <p className="form-entry-description">This is the URL that points to the model creator socials</p>

                                                <label className="form-entry-title">Model Files (Zip)</label>
                                                <input
                                                    type="file"
                                                    className="form-file-entry"
                                                    accept=".zip"
                                                    onChange={(e) => handleZIpFileChange(e, 1)}
                                                />
                                                <p className="form-entry-description">Your model files must include a .PTH and .INDEX file</p>

                                                <label className="form-entry-title">Reference Artist file (mp3 or wav)</label>
                                                <input
                                                    type="file"
                                                    className="form-file-entry"
                                                    accept="audio/*"
                                                    onChange={(e) => {handleReferenceTrackChange(e, 1)}}
                                                />
                                                <p className="form-entry-description">
                                                    This is to help us score the model. Use either the isolated voice or a song (with no features) from the reference artist.
                                                </p>
                                            </>
                                        ) : (
                                            <>
                                                <h1>Step 3</h1>
                                                <h2>Search for existing model on Hugging Bass</h2>
                                                <label className={"form-entry-title"}>Search for Model</label>
                                                <input type="text" className="form-text-entry" value={searchModelsQuery} placeholder="Search for a model..." onChange={(e) => setSearchModelsQuery(e.target.value)} />
                                                {modelSearchResults.length > 0 && (
                                                    <div className="">
                                                        {modelSearchResults.slice(0, 6).map((model, index) => (
                                                            <div onClick={() => {
                                                                setSelectedExistingModels(prevState => {
                                                                    const newState = [...prevState];
                                                                    newState[1] = model;
                                                                    return newState;
                                                                })
                                                            }} className={`existing-model ${selectedExistingModels.indexOf(model) === 1 && 'selected-existing-model'} `}>
                                                                <div className={""}>
                                                                    <p className={""}>{model?.name}</p>
                                                                    <p className={"existing-model-creator"}>by: {model?.creator}</p>
                                                                </div>
                                                                <div className={"existing-model-stats"}>
                                                                    <p>Score: {model?.qualityScore ? Math.round(model.qualityScore) : "Not Rated Yet"}</p>
                                                                    <p className={"existing-model-likes"}>{model?.favoriteCount} likes</p>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                        <div className={"upload-form-navigation-row"}>
                                            <button className={"upload-form-last-step-bttn"} onClick={() => setUploadStep(uploadStep - 1)}>Go Back</button>
                                            <button type="submit" className="submit-form" disabled={!isFullyCompletedForm || isUploading}>{isUploading ? 'Uploading... please wait' : 'Upload to Hugging Bass'}</button>
                                            {errorMessage && <div className="error-message">{errorMessage}</div>}
                                        </div>
                                    </div>
                                )}
                            </form>
                        )}
                    </>
                    <>
                        {/*{uploadType === 'song-from-prompt' && (*/}
                        {/*    <form className="upload-form" onSubmit={handleUploadSongFromPrompt}>*/}
                        {/*        /!* Song details *!/*/}
                        {/*        <label className={"model-form-entry-title"} htmlFor="songName">Song Name</label>*/}
                        {/*        <input type="text" id="songName" className="model-form-entry" value={songName} placeholder="Enter song name" onChange={(e) => setSongName(e.target.value)} />*/}

                        {/*        <label className={"model-form-entry-title"} htmlFor="songFile">Song File (MP3)</label>*/}
                        {/*        <input type="file" id="songFile" className="model-form-entry" accept=".mp3" onChange={handleSongFileChange} />*/}

                        {/*        <label className={"model-form-entry-title"} htmlFor="artistName">Artist Name</label>*/}
                        {/*        <input type="text" id="artistName" className="model-form-entry" value={artistName} placeholder="Enter artist name" onChange={(e) => setArtistName(e.target.value)} />*/}

                        {/*        <label className={"model-form-entry-title"} htmlFor="artistUrl">Artist URL</label>*/}
                        {/*        <input type="text" id="artistUrl" className="model-form-entry" value={artistUrl} placeholder="Enter artist URL" onChange={(e) => setArtistUrl(e.target.value)} />*/}

                        {/*        <label className={"model-form-entry-title"} htmlFor="songCoverArt">Song Cover Art</label>*/}
                        {/*        <div*/}
                        {/*            className="model-image-drop-area"*/}
                        {/*            onDragOver={handleDragOver}*/}
                        {/*            onDrop={handleDropSongCoverArt}>*/}
                        {/*            {songCoverArtPreview ? (*/}
                        {/*                <>*/}
                        {/*                    <input*/}
                        {/*                        type="file"*/}
                        {/*                        id="fileInput"*/}
                        {/*                        style={{ display: 'none' }}*/}
                        {/*                        onChange={handleSongCoverArtChange}*/}
                        {/*                    />*/}
                        {/*                    <img*/}
                        {/*                        src={songCoverArtPreview}*/}
                        {/*                        alt="Preview"*/}
                        {/*                        className="preview-image"*/}
                        {/*                    />*/}
                        {/*                </>*/}
                        {/*            ) : (*/}
                        {/*                <div className="drop-message">*/}
                        {/*                    <input*/}
                        {/*                        type="file"*/}
                        {/*                        id="fileInput"*/}
                        {/*                        style={{ display: 'none' }}*/}
                        {/*                        onChange={handleSongCoverArtChange}*/}
                        {/*                    />*/}
                        {/*                    <label className="model-photo-input" htmlFor="fileInput">Select or drop cover art</label>*/}
                        {/*                </div>*/}
                        {/*            )}*/}
                        {/*        </div>*/}

                        {/*        <label className={"model-form-entry-title"} htmlFor="modelName">Origin of Creation</label>*/}
                        {/*        <input type="text" id="modelName" className="model-form-entry" value={origin} placeholder="Did you make this song on Suno, Udio..." onChange={(e) => setOrigin(e.target.value)} />*/}
                        {/*        <label className={"model-form-entry-title"} htmlFor="modelName">Prompt Used</label>*/}
                        {/*        <input type="text" id="modelName" className="model-form-entry" value={prompt} placeholder="Enter the prompt you used to make your song..." onChange={(e) => setPrompt(e.target.value)} />*/}

                        {/*        <label className={"model-form-entry-title"} htmlFor="modelName">Style of Music Used (Optional)</label>*/}
                        {/*        <textarea id="modelDescription" className="model-form-entry longer-model-form-entry" value={styleOfMusic} placeholder="If you used any additional style prompts, enter them here..." onChange={(e) => setStyleOfMusic(e.target.value)} />*/}

                        {/*        {errorMessage && <div className="error-message">{errorMessage}</div>}*/}
                        {/*        <button type="submit" className="submit-form" disabled={!isFullyCompletedForm || isUploading}>{isUploading ? 'Uploading... please wait' : 'Upload Song from Prompt'}</button>*/}
                        {/*    </form>*/}
                        {/*)}*/}
                    </>
                    <>
                        {uploadType === 'evaluate-model' && (
                            <form className="upload-form" onSubmit={handleEvaluateModel}>
                                <div className={"upload-form-section"}>
                                    <label className={"form-entry-title"}>Search for Model</label>
                                    <input type="text" className="form-text-entry" value={searchModelsQuery} placeholder="Search for a model..." onChange={(e) => setSearchModelsQuery(e.target.value)} />
                                    {modelSearchResults.length > 0 && (
                                        <div className="">
                                            {modelSearchResults.slice(0, 10).map((model, index) => (
                                                <div onClick={() => {
                                                    setSelectedExistingModel(model);
                                                }} className={`existing-model ${model === selectedExistingModel && 'selected-existing-model'} `}>
                                                    <div className={""}>
                                                        <p className={""}>{model?.name}</p>
                                                        <p className={""}>by: {model?.creator}</p>
                                                        <p>Score: {model?.qualityScore ? Math.round(model.qualityScore) : "Not Rated Yet"}</p>
                                                    </div>
                                                    <p>{model?.favoriteCount} likes</p>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    <label className={"form-entry-title"}>Solo Reference Track</label>
                                    <input type="file" className="form-file-entry" accept=".mp3" onChange={handleReferenceTrackChange} />
                                    <button type="submit" className="submit-form" disabled={!isFullyCompletedForm || isUploading}>{isUploading ? 'Evaluating... please wait' : 'Evaluate Model'}</button>
                                    <label className={"form-entry-title"}>NOTE: Once processed you will be sent to the Hugging Bass Homepage, this does not mean that the model evaluation has finished. Once the evaluation is done, the model will be updated with its score.</label>
                                </div>
                            </form>
                        )}
                    </>
                </div>
            </div>
        </>
    );
};

export default UploadScreen;
