import React, {useEffect, useState} from "react";
import {handleProcessInferenceAndScore, uploadModel} from "../api/upload/model";

const TestQualityScreen = () => {
    const [modelNames, setModelNames] = useState([]);
    const [modelCreators, setModelCreators] = useState([]);
    const [modelCreatorUrls, setModelCreatorUrls] = useState([]);
    const [modelDescriptions, setModelDescriptions] = useState([]);
    const [modelFilesUrls, setModelFilesUrls] = useState([]);
    const [referenceTrack, setReferenceTrack] = useState([]);

    const handleReferenceTracksChange = (e) => {
        setReferenceTrack(e.target.files[0]);
    };

    const handleBulkUpload = async (e) => {
        e.preventDefault();
        try {
            for (let i = 0; i < modelNames.length; i++) {
                const modelUploadResponse = await uploadModel(
                    modelNames[i],
                    modelDescriptions[i],
                    modelFilesUrls[i],
                    '',
                    modelCreators[i],
                    modelCreatorUrls[i],
                    [referenceTrack]
                );
                const uploadedModel = modelUploadResponse.model;

                const inferenceResponse = await handleProcessInferenceAndScore(uploadedModel?._id);
                console.log('Inference response:', inferenceResponse);
            }

        } catch (error) {
            console.error('Upload error:', error);
        }
    };
    useEffect(() => {
        const logFormState = () => {
            console.group('Form State');
            console.log('Model Names:', modelNames);
            console.log('Model Creators:', modelCreators);
            console.log('Model Creator URLs:', modelCreatorUrls);
            console.log('Model Descriptions:', modelDescriptions);
            console.log('Model Files URLs:', modelFilesUrls);
            console.log('Reference Tracks Arrays:', referenceTrack);

            console.group('Content to be sent to backend');
            console.log('Model Names:', modelNames);
            console.log('Model Descriptions:', modelDescriptions);

            console.log('Model Creators:', modelCreators);
            console.log('Model Creator URLs:', modelCreatorUrls);

            console.group('Reference Tracks');
            console.log(`File Name: ${referenceTrack.name}, Size: ${referenceTrack.size} bytes`);
            console.groupEnd();
            console.groupEnd();

            console.groupEnd();
            console.groupEnd();
        };

        logFormState();
    }, [
        modelNames,
        modelCreators,
        modelCreatorUrls,
        modelDescriptions,
        modelFilesUrls,
        referenceTrack,
    ]);

    return (
        <div style={{backgroundColor: 'white', height: '100vh', width: '100vw'}}>
            <h1>Upload bulk Screen</h1>
            <>
                <label className="form-entry-title">Model Names</label>
                <input type="text" className="form-text-entry" placeholder="Enter model names (comma-separated)" onChange={(e) => setModelNames(e.target.value.split(','))} />
                <label className="form-entry-title">Model Creators</label>
                <input type="text" className="form-text-entry" placeholder="Enter model creators (comma-separated)" onChange={(e) => setModelCreators(e.target.value.split(','))} />
                <label className="form-entry-title">Model Creator URLs</label>
                <input type="text" className="form-text-entry" placeholder="Enter model creator URLs (comma-separated)" onChange={(e) => setModelCreatorUrls(e.target.value.split(','))} />
                <label className="form-entry-title">Model Descriptions</label>
                <textarea className="form-text-entry" placeholder="Enter model descriptions (comma-separated)" onChange={(e) => setModelDescriptions(e.target.value.split(','))} />
                <label className="form-entry-title">Model Files Urls</label>
                <input type="text" className="form-text-entry" placeholder="Enter model files URLs (comma-separated)" onChange={(e) => setModelFilesUrls(e.target.value.split(','))} />
                <label className="form-entry-title">Solo Reference Track</label>
                <input type="file" multiple className="form-file-entry" accept=".mp3" onChange={handleReferenceTracksChange} />
                <button onClick={handleBulkUpload}>Upload Models</button>
            </>
        </div>
    )
}

export default TestQualityScreen;
