import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import './homeScreen.css';
import { useAuth } from "../../contexts/authContext";
import {fetchSongsAndModelsHome} from "../../api/fetches";
import AudioContext, {useHandlePlayTrack} from "../../contexts/audioContext";
import TopSongs from "../../components/lists/topSongs/topSongs";
import SortOptions from "../../components/sidebar/sortOptions/sortOptions";
import HomeSongTile from "../../components/tiles/homeSongTile/homeSongTile";
import {useInfiniteQuery, useQueryClient} from 'react-query';
import {searchSongs} from "../../api/get/song";
import {fetchModelsFromIds} from "../../api/get/model";
import TopModels from "../../components/lists/topModels/topModels";
import {useLocation} from "react-router-dom";
import {logEvent} from "../../App";
import Header from "../../components/sidebar/header";
import HomeRemixBanner from "../../components/homeRemixBanner/homeRemixBanner";
import TopCreators from "../../components/lists/topCreators/topCreators";

const HomeScreen = () => {
    const { isPlaying, currentTrack, setIsPlaying } = useContext(AudioContext);
    const { handlePlayTrack } = useHandlePlayTrack();
    const { activeUser } = useAuth();

    const queryClient = useQueryClient();

    const {
        data,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useInfiniteQuery(
        ['songsAndModelsHome', activeUser?._id],
        ({ pageParam = 1 }) => fetchSongsAndModelsHome({ pageParam , userId: activeUser?._id} ),
        {
            getNextPageParam: (lastPage) => lastPage.nextCursor,
        }
    );

    const observer = useRef();
    const lastElementRef = useCallback(node => {
        if (isFetchingNextPage) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasNextPage) {
                fetchNextPage();
            }
        });
        if (node) observer.current.observe(node);
    }, [isFetchingNextPage, hasNextPage]);

    const [songFavoriteStatuses, setSongFavoriteStatuses] = useState([]);
    const [modelFavoriteStatuses, setModelFavoriteStatuses] = useState([]);

    const [songsAndModelData, setSongsAndModelData] = useState([]);
    const [filteredSongAndModelData, setFilteredSongAndModelData] = useState([]);
    const [sortedData, setSortedData] = useState([]);

    const [sortOption, setSortOption] = useState('recent');
    const [searchQuery, setSearchQuery] = useState('');
    const [isSearching, setIsSearching] = useState(false);

    const location = useLocation();

    useEffect(() => {
        if (data?.pages) {
            const pages = data.pages.flatMap((page) => page.mergedData);
            setSongsAndModelData(pages);
            setFilteredSongAndModelData(pages);
            setModelFavoriteStatuses(data.pages.flatMap((page) => page.modelStatuses));
            setSongFavoriteStatuses(
                data.pages.flatMap((page) => page.songs).map((song) => song.isFavorite)
            );
        }
    }, [data]);

    // Invalidate query to fetch fresh data on navigation
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                queryClient.invalidateQueries(['songsAndModelsHome', activeUser?._id]);
            }
        };
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [queryClient, activeUser?._id]);

    // search
    useEffect(() => {
        if (searchQuery !== '') {
            setIsSearching(true);
            const handleSearchSongs = async () => {
                const results = await searchSongs(searchQuery)
                const usedModelIds = results?.map(song => (
                    song?.origin ? "" : song?.usedModels[0]
                ));

                if (results.length > 0) {
                    const filteredSongModelObjects = await fetchModelsFromIds(usedModelIds, activeUser?._id);
                    const songModels = filteredSongModelObjects.map(model => model.model);
                    const modelStatuses = filteredSongModelObjects.map(model => model.isFavorite);

                    const filteredMergedData = results.map((song, index) => {
                        const songObject = song;
                        const model = songObject?.origin ? {} : songModels[index];
                        const favoriteCount = songObject?.favoriteCount;
                        const downloadCount = model.downloadCount ? model.downloadCount : 0;
                        return { songObject, model, favoriteCount, downloadCount };
                    });

                    setFilteredSongAndModelData(filteredMergedData);
                    setSortedData(filteredMergedData)
                } else {
                    setFilteredSongAndModelData([]);
                }
            };
            handleSearchSongs();
        } else {
            setIsSearching(false);
            setFilteredSongAndModelData(songsAndModelData);
        }
    }, [searchQuery, songsAndModelData]);

    const handleSetSortOption = (option) => {
        const newSortedData = option === 'recent'
            ? filteredSongAndModelData
            : option === 'mostUpvoted'
                ? [...filteredSongAndModelData].sort((a, b) => b.favoriteCount - a.favoriteCount)
                : option === 'mostDownloaded'
                    ? [...filteredSongAndModelData].sort((a, b) => b.downloadCount - a.downloadCount)
                    : [...filteredSongAndModelData].sort((a, b) => b.song?.streamCount - a.song?.streamCount);
        setSortedData(newSortedData);
        setSortOption(option);
    };

    useEffect(() => {
        if (searchQuery === '') {
            handleSetSortOption(sortOption);
        }
    }, [filteredSongAndModelData, sortOption]);

    const handlePlayPause = (song, model, songs, models) => {
        if (isPlaying && currentTrack?._id === song._id) {
            setIsPlaying(!isPlaying);
        } else if (currentTrack?._id === song._id) {
            setIsPlaying(true);
        } else {
            logEvent('Song Played', { location: "Home Song Tile", songId: song._id, songName: song.name });
            handlePlayTrack(song, model, songs, models)
        }
    }

    return (
        <div>
            <div className='home-screen'>
                <Header
                    dontShowDiscover={true}
                    sortedSongs={sortedData}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                />
                <div className="home-content-with-ref">
                    {searchQuery !== '' && (
                        <SortOptions sortOption={sortOption} handleChangeSortOptions={handleSetSortOption} />
                    )}
                    {!isSearching && (
                        <>
                            <HomeRemixBanner />
                            <TopSongs />
                            <div className={"top-models-top-creators-row"}>
                                <TopModels />
                                <TopCreators />
                            </div>
                            <h1 className={"home-screen-section-title"}>All Songs:</h1>
                        </>
                    )}
                    {isSearching && (
                        <h1 key={"d"} className={"home-screen-section-title"}>Search Results:</h1>
                    )}
                    <div className={"feed-container"}>
                        {sortedData.map((object, index) => {
                            return (
                                <div
                                    ref={index === sortedData.length - 1 ? lastElementRef : null}
                                    key={object?.song?._id}
                                    className={`home-song-tile-wrapper`}>
                                    <HomeSongTile
                                        status={songFavoriteStatuses[index]}
                                        song={object?.songObject}
                                        model={object?.model[0]}
                                        onPlay={() => handlePlayPause(object?.songObject, object?.model[0], sortedData.map(object => object?.songObject), sortedData.map(object => object?.model[0]))}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeScreen;
