import './creatorScreen.css';
import {useEffect, useState} from "react";
import {fetchAlsoByArtist} from "../../api/get/song";
import SongList from "../../components/lists/SongList/songList";
import ModelList from "./../../components/lists/modelList/modelList";
import {useTheme} from "../../contexts/themeContext";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useAuth} from "../../contexts/authContext";
import Header from "../../components/sidebar/header";

const CreatorScreen = () => {
    const { theme } = useTheme()
    const location = useLocation()
    const {activeUser} = useAuth();
    const {creatorName} = useParams();
    const navigate = useNavigate();
    const [artistUrl, setArtistUrl] = useState(null)
    const [copied, setCopied] = useState(false)

    // artist details
    const [creatorUserProfile, setCreatorUserProfile] = useState(null)
    const [totalLikes, setTotalLikes] = useState(0)
    const [totalStreams, setTotalStreams] = useState(0)
    const [totalDownloads, setTotalDownloads] = useState(0)

    const [songsByArtist, setSongsByArtist] = useState([]);
    const [songsByArtistStatuses, setSongsByArtistStatuses] = useState([]);
    const [songModelsByArtist, setSongModelsByArtist] = useState([]);
    const [songModelsByArtistStatuses, setSongModelsByArtistStatuses] = useState([]);
    const [modelsByArtist, setModelsByArtist] = useState([]);
    const [modelsByArtistStatuses, setModelsByArtistStatuses] = useState([]);
    const [songsUsingModels, setSongsUsingModels] = useState([]);
    const [hasFetchedAlsoByArtist, setHasFetchedAlsoByArtist] = useState(false);
    const [linkSrc, setLinkSrc] = useState(null)

    // styles
    const imageStyles = {
        filter: theme === 'light' ? 'invert(0)' : 'invert(1)',
    }

    useEffect(() => {
        if (artistUrl?.includes('youtube.com')) {
            setLinkSrc(require('../../assets/images/yt-icon.png'));
        } else if (artistUrl?.includes('weights.gg')) {
            setLinkSrc(require('../../assets/images/weights-icon.png'));
        } else if (artistUrl?.includes('huggingface.co')) {
            setLinkSrc(require('../../assets/images/hf-icon.png'));
        } else if (artistUrl?.includes('instagram.com')) {
            setLinkSrc(require('../../assets/images/ig-icon.png'));
        }
    }, [artistUrl]);

    // handle fetch drawer contents
    useEffect(() => {
        if (creatorName) {
            const handleFetchAlsoByArtists = async () => {
                const alsoBy = await fetchAlsoByArtist(creatorName, activeUser?._id)
                setArtistUrl(alsoBy.artistUrl)

                setSongsByArtist(alsoBy.songs.map(song => song.song))
                setSongsByArtistStatuses(alsoBy.songs.map(song => song.isFavorite))
                setSongModelsByArtist(alsoBy.songModels.map(model => model.model))
                setSongModelsByArtistStatuses(alsoBy.songModels.map(model => model.isFavorite))

                setModelsByArtist(alsoBy.models.map(model => model.model))
                setModelsByArtistStatuses(alsoBy.models.map(model => model.isFavorite))

                setSongsUsingModels(alsoBy.songsUsingModels)
                setTotalStreams(alsoBy.totalStreams)
                setTotalLikes(alsoBy.totalLikes)
                setTotalDownloads(alsoBy.totalDownloads)
                setCreatorUserProfile(alsoBy.associatedUser)
                console.log('Also By:', alsoBy.associatedUser)

                setHasFetchedAlsoByArtist(true)
            }
            handleFetchAlsoByArtists()
        }

    }, [creatorName])

    const handleCopy = () => {
        navigator.clipboard.writeText(`https://www.huggingbass.com/creator/${creatorName}`)
            .then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 2000);
            })
            .catch(err => console.error('Failed to copy text: ', err));
    };

    const handleClickBack = () => {
        if (location.state && location.state.from) {
            navigate(location.state.from);
        } else {
            navigate('/');
        }
    };

    return (
        <div className="creator-screen">
            <Header />
            <div className={"creator-screen-artist-details"}>
                {creatorUserProfile && creatorUserProfile?.profilePhotoUrl ? (
                    <div className={"creator-details-with-cover"}>
                        <img className={"creator-pfp"} src={creatorUserProfile?.profilePhotoUrl} alt="profile" />
                        <div>
                            <p className={"creator-name"}>
                                {creatorName}
                            </p>
                            <div className={"creator-links-row"}>
                                <div
                                    onClick={() => {
                                        window.open(artistUrl, '_blank', 'noopener noreferrer');
                                    }}
                                    className={"screen-link-pill"}>
                                    <img style={imageStyles} src={require('../../assets/images/link.png')} alt="link"/>
                                    {linkSrc && (
                                        <img
                                            style={{filter: `${artistUrl?.includes('weights.gg') && theme === 'light' ? 'invert(1)' : 'invert(0)'}`}}
                                            src={linkSrc} alt="link"/>
                                    )}
                                </div>
                                <div className="creator-share-pill" onClick={handleCopy} style={{ cursor: 'pointer' }}>
                                    <img
                                        style={{ filter: `${theme !== 'light' ? 'invert(1)' : ''}` }}
                                        src={require('../../assets/images/share.png')}
                                        alt="share"
                                    />
                                    {copied && <span className="copied-text">Link copied!</span>}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <p className={"creator-name"}>
                            {creatorName}
                        </p>
                        <div className={"creator-links-row"}>
                            <div
                                onClick={() => {
                                    window.open(artistUrl, '_blank', 'noopener noreferrer');
                                }}
                                className={"screen-link-pill"}>
                                <img style={imageStyles} src={require('../../assets/images/link.png')} alt="link"/>
                                {linkSrc && (
                                    <img
                                        style={{filter: `${artistUrl?.includes('weights.gg') && theme === 'light' ? 'invert(1)' : 'invert(0)'}`}}
                                        src={linkSrc} alt="link"/>
                                )}
                            </div>
                            <div className="creator-share-pill" onClick={handleCopy} style={{ cursor: 'pointer' }}>
                                <img
                                    style={{ filter: `${theme !== 'light' ? 'invert(1)' : ''}` }}
                                    src={require('../../assets/images/share.png')}
                                    alt="share"
                                />
                                {copied && <span className="copied-text">Link copied!</span>}
                            </div>
                        </div>
                    </>
                )}
                <div className={"creator-stats-row"}>
                    <div className={"creator-stats-pill first-pill"}>
                        <h1>
                            {totalLikes}
                        </h1>
                        <p>Likes</p>
                    </div>
                    <div className={"creator-stats-pill"}>
                        <h1>
                            {totalStreams}
                        </h1>
                        <p>Streams</p>
                    </div>
                    <div className={"creator-stats-pill"}>
                        <h1>
                            {totalDownloads}
                        </h1>
                        <p>Downloads</p>
                    </div>
                </div>
                {/*<img className={"creator-screen-cover"} src={require('../../assets/images/banner.jpeg')} alt="cover" />*/}
            </div>
            <div className={"creator-screen-content"}>
                {songsByArtist && songsByArtist.length > 0 && (
                    <div className={"creator-screen-content-section"}>
                        <h1>
                            Top Songs
                        </h1>
                        <SongList
                            pageLocation={"Creator Page"}
                            songs={songsByArtist}
                            models={songModelsByArtist}
                            statuses={songsByArtistStatuses}
                            showArtists={false} />
                    </div>
                )}
                {modelsByArtist && modelsByArtist.length > 0 && (
                    <div className={"creator-screen-content-section"}>
                        <h1>
                            Top Models
                        </h1>
                        <ModelList
                            pageLocation={"Creator Page"}
                            songsFromModels={songsUsingModels}
                            models={modelsByArtist}
                            statuses={modelsByArtistStatuses}
                            showCreators={false}/>
                    </div>
                )}
            </div>
        </div>
    )
}

export default CreatorScreen;
