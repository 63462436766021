import React, {useContext, useEffect, useState} from "react";
import {useTheme} from "../../contexts/themeContext";
import {useAuth} from "../../contexts/authContext";
import {useQuery} from "react-query";
import {fetchFavoriteSongsAndModels} from "../../api/fetches";
import TopSongTile from "../../components/lists/topSongs/topSongTile/topSongTile";
import {logEvent} from "../../App";
import AudioContext, {useHandlePlayTrack} from "../../contexts/audioContext";
import './favoriteSongsScreen.css';
import Sidebar from "../../components/sidebar/header";
import {useNavigate} from "react-router-dom";
import HorizontalSongTile from "../../components/tiles/horizontalSongTile/horizontalSongTile";
import Header from "../../components/sidebar/header";

const FavoriteSongsScreen = () => {
    const navigate = useNavigate();
    const { theme } = useTheme();
    const { activeUser } = useAuth();
    const { isPlaying, currentTrack, setIsPlaying } = useContext(AudioContext)
    const { handlePlayTrack } = useHandlePlayTrack();
    const { data } = useQuery(['songsAndModelsTopSongs', activeUser?._id], () => fetchFavoriteSongsAndModels({userId: activeUser?._id}));

    // data
    const [favoriteSongs, setFavoriteSongs] = useState([]);
    const [favoriteStatuses, setFavoriteStatuses] = useState([]);
    const [modelStatuses, setModelStatuses] = useState([]);
    const [models, setModels] = useState([])


    // fetch data
    useEffect(() => {
        if (data) {
            const {allFavorited } = data;
            console.log('All Favorited:', allFavorited);
            setFavoriteSongs(allFavorited?.map(object => object.song));
            setModels(allFavorited?.map(object => object.model));
            setFavoriteStatuses(allFavorited?.map(_ => true));
            setModelStatuses(allFavorited?.map(object => object.isModelFavorite));
        }
    }, [data]);

    const handlePlayPause = (song, model, songs, models) => {
        if (isPlaying && currentTrack?._id === song._id) {
            setIsPlaying(!isPlaying);
        } else if (currentTrack?._id === song._id) {
            setIsPlaying(true);
        } else {
            logEvent('Song Played', { location: "Favorite Songs Screen", songId: song._id, songName: song.name });
            handlePlayTrack(song, model, songs, models)
        }
    }

    const handleShuffleSongs = () => {
        const shuffledData = favoriteSongs
            .map((song, index) => ({ song, model: models[index], modelStatus: modelStatuses[index] }))
            .sort(() => Math.random() - 0.5);

        const shuffledSongs = shuffledData.map(item => item.song);
        const shuffledModels = shuffledData.map(item => item.model);
        const shuffledModelStatuses = shuffledData.map(item => item.modelStatus);

        handlePlayPause(shuffledSongs[0], shuffledModels[0], shuffledSongs, shuffledModels);
    };

    return (
        <div className={"favorite-songs-screen"}>
            <Header
                dontShowSearch={true}
                dontShowFavorites={true}
                dontShowRadio={true}/>
            <div className={"favorite-songs-content"}>
                <p
                    className={"back-button"}
                    onClick={() => navigate('/')}>
                    back</p>
                <h1 className={"favorite-songs-screen-title"}>My Favorite Songs</h1>
                <div className={"favorite-songs-playback-options-container"}>
                    <div onClick={handleShuffleSongs}>
                        <p>Shuffle</p>
                        <img src={require("../../assets/icons/shuffle.png")}/>
                    </div>
                    <div onClick={() => handlePlayPause(favoriteSongs[0], models[0], favoriteSongs, models)}>
                        <p>Play from start</p>
                    </div>
                </div>
                {favoriteSongs?.length === 0 ? (
                    <div className={"no-favorite-songs-container"}>
                        <p>You haven't liked anything yet...</p>
                        <button
                            onClick={() => navigate('/')}
                        >Start discovering</button>
                    </div>
                ) : (
                    <div className={"favorite-songs-container"}>
                        {favoriteSongs?.map((song, i) => {
                            return (
                                <HorizontalSongTile
                                    rank={i + 1}
                                    song={song}
                                    status={favoriteStatuses[i]}
                                    modelStatus={modelStatuses[i]}
                                    modelUsed={models[i]}
                                    onPlay={() => handlePlayPause(favoriteSongs[i], models[i], favoriteSongs, models)}
                                />
                            )
                        })}
                    </div>
                )}
            </div>
        </div>
    )
}

export default FavoriteSongsScreen;
