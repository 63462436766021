import React, {useContext, useEffect, useState} from "react";
import './homeSongTile.css';
import pauseImage from "../../../assets/images/pause.png";
import playImage from "../../../assets/images/play.png";
import AudioContext from "../../../contexts/audioContext";
import {useNavigate} from "react-router-dom";
import {toggleFavoriteSong} from "../../../api/status/song";
import {useAuth} from "../../../contexts/authContext";
import {useTheme} from "../../../contexts/themeContext";
import {logEvent} from "../../../App";
import LoginModal from "../../modals/loginModal";

const star = require('../../../assets/images/star.png');

const HomeSongTile = ({ song, model, status, onPlay }) => {
    const {activeUser} = useAuth();
    const {theme} = useTheme();
    const navigate = useNavigate();
    const { currentTrack, isPlaying} = useContext(AudioContext);
    const [favoriteStatus, setFavoriteStatus] = useState(false);
    const [favoriteCount, setFavoriteCount] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        setFavoriteStatus(status)
        if (song) {
            setFavoriteCount(song?.favoriteCount);
        }
    }, [status, song]);

    const handleToggleFavoriteSong = async () => {
        try {
            logEvent('Song Favorite Toggled', { location: "Home Song Tile", songId: song._id, songName: song.name });
            if (!activeUser) {
                setIsModalOpen(true);
                return;
            }
            setFavoriteStatus(!favoriteStatus);
            if (favoriteStatus) {
                setFavoriteCount(favoriteCount - 1);
            } else {
                setFavoriteCount(favoriteCount + 1);
            }
            await toggleFavoriteSong(activeUser?._id, song?._id);
        } catch (error) {
            console.error('Favorite/Unfavorite Error:', error);
        }
    };


    return (
        <>
            <div className={"home-song-tile"}>
                <div className={"home-song-tile-cover"} style={{backgroundImage: `url("${song?.coverImageUrl}")`}} >
                    {(!song?.isPrivate === true) && (
                        <div onClick={onPlay} className={"top-song-play-button"}>
                            <img src={isPlaying && currentTrack?.name === song?.name ? pauseImage : playImage} alt={"play"} />
                        </div>
                    )}
                    {!song?.isRemix && song.vocalUrl && song?.instrumentalUrl && (
                        <div
                            onClick={() => {
                                if (activeUser) {
                                    navigate('/remix', { state: { starterSong: song }})
                                } else {
                                    setIsModalOpen(true);
                                }
                            }}
                            className={"top-song-remix-overlay-container"}>
                            <img src={require('../../../assets/icons/magic.svg').default} alt={"remix"} />
                            <p>Cover</p>
                        </div>
                    )}
                </div>
                <div className={"home-song-tile-song-info"}>
                    <div className={"home-tile-top-info"}>
                        <p
                            onClick={() => {
                                logEvent('Song Page Viewed', { songId: song._id, songName: song.name })
                                navigate(`/song/${song?._id}`)
                            }}
                            title={song?.name} className={"home-song-tile-title"}>{song?.name}</p>
                        <p className={"home-song-tile-artist"}
                           onClick={() => {
                               logEvent('Creator Page Viewed', { creatorName: song.artist })
                               navigate(`/creator/${song?.artist}`)
                           }}>by: <span style={{textDecoration: 'underline'}}>
                            {song?.artist}</span></p>

                        <div className={"home-song-tile-actions-row"}>
                            <div onClick={handleToggleFavoriteSong} className={"home-song-tile-heart-pill"}>
                                {favoriteStatus === true ? (
                                    <img className={"home-song-tile-heart"} src={require('../../../assets/images/heart-fill.svg').default} alt="upvote"/>
                                ) : (
                                    <img className={"home-song-tile-heart"} style={{filter: `${theme === 'light' ? 'invert(1)' : ''}`}} src={require('../../../assets/images/light-heart.svg').default} alt="upvote"/>
                                )}
                                <p>{favoriteCount}</p>
                            </div>
                        </div>
                    </div>
                    <div onClick={() => {
                        logEvent('Model Page Viewed', { modelId: model._id, modelName: model.name })
                        navigate(`/model/${model._id}`)
                    }} className={"home-song-tile-model-info"}>
                        <p className={"home-song-tile-model-title"}>Model:</p>
                        {model?.qualityScore ? (
                            <div className={"home-tile-score-container"}>
                                <img src={star} alt={"star"} />
                                <p className={"home-tile-score"}>{Math.round(model.qualityScore)}%</p>
                            </div>
                        ) : (
                            <p className={"not-rated"}>Rating Coming Soon...</p>
                        )}
                    </div>
                </div>
            </div>
            <LoginModal isVisible={isModalOpen} onClose={() => setIsModalOpen(false)} />
        </>
    )
}

export default HomeSongTile;
