import React, {useContext, useEffect, useState} from "react";
import {useTheme} from "../../contexts/themeContext";
import {useAuth} from "../../contexts/authContext";
import {useQuery} from "react-query";
import {fetchSongsAndModelsTopSongs} from "../../api/fetches";
import TopSongTile from "../../components/lists/topSongs/topSongTile/topSongTile";
import {logEvent} from "../../App";
import AudioContext, {useHandlePlayTrack} from "../../contexts/audioContext";
import './topSongsScreen.css';
import Sidebar from "../../components/sidebar/header";
import {useNavigate} from "react-router-dom";
import HorizontalSongTile from "../../components/tiles/horizontalSongTile/horizontalSongTile";
import Header from "../../components/sidebar/header";

const TopSongsScreen = () => {
    const navigate = useNavigate();
    const { theme } = useTheme();
    const { activeUser } = useAuth();
    const { isPlaying, currentTrack, setIsPlaying } = useContext(AudioContext)
    const { handlePlayTrack } = useHandlePlayTrack();
    const { data } = useQuery(['songsAndModelsTopSongs', activeUser?._id], () => fetchSongsAndModelsTopSongs({userId: activeUser?._id}));

    // data
    const [topSongs, setTopSongs] = useState([]);
    const [topSongStatuses, setTopSongStatuses] = useState([]);
    const [topSongModelStatuses, setTopSongsModelStatuses] = useState([]);
    const [topSongsModels, setTopSongsModels] = useState([])

    const [topSongsAll, setTopSongsAll] = useState([]);
    const [topSongAllStatuses, setTopSongAllStatuses] = useState([]);
    const [topSongModelAllStatuses, setTopSongsModelAllStatuses] = useState([]);
    const [topSongsModelsAll, setTopSongsModelsAll] = useState([])

    const [topSongsDay, setTopSongsDay] = useState([]);
    const [topSongDayStatuses, setTopSongDayStatuses] = useState([]);
    const [topSongModelDayStatuses, setTopSongModelDayStatuses] = useState([]);
    const [topSongsModelsDay, setTopSongsModelsDay] = useState([]);

    const [topSongsWeek, setTopSongsWeek] = useState([]);
    const [topSongWeekStatuses, setTopSongWeekStatuses] = useState([]);
    const [topSongModelWeekStatuses, setTopSongModelWeekStatuses] = useState([]);
    const [topSongsModelsWeek, setTopSongsModelsWeek] = useState([]);

    const [topSongsMonth, setTopSongsMonth] = useState([]);
    const [topSongMonthStatuses, setTopSongMonthStatuses] = useState([]);
    const [topSongModelMonthStatuses, setTopSongModelMonthStatuses] = useState([]);
    const [topSongsModelsMonth, setTopSongsModelsMonth] = useState([]);

    const [sortOption, setSortOption] = useState("allTime");


    // fetch data
    useEffect(() => {
        if (data) {
            const {mostStreamedDay } = data;
            console.log(mostStreamedDay)
            setTopSongsAll(mostStreamedDay?.allTime?.map(song => song.song));
            setTopSongsModelsAll(mostStreamedDay?.allTimeModels?.map(model => model.model));
            setTopSongAllStatuses(mostStreamedDay?.allTime?.map(song => song.isFavorite));
            setTopSongsModelAllStatuses(mostStreamedDay?.allTimeModels?.map(model => model.isFavorite))

            setTopSongsWeek(mostStreamedDay?.week?.map(song => song.song));
            setTopSongsModelsWeek(mostStreamedDay?.weekModels?.map(model => model.model));
            setTopSongWeekStatuses(mostStreamedDay?.week?.map(song => song.isFavorite));
            setTopSongModelWeekStatuses(mostStreamedDay?.weekModels?.map(model => model.isFavorite))

            setTopSongsMonth(mostStreamedDay?.month?.map(song => song.song));
            setTopSongsModelsMonth(mostStreamedDay?.monthModels?.map(model => model.model));
            setTopSongMonthStatuses(mostStreamedDay?.month?.map(song => song.isFavorite));
            setTopSongModelMonthStatuses(mostStreamedDay?.monthModels?.map(model => model.isFavorite))

            setTopSongsDay(mostStreamedDay?.day.map(song => song.song));
            setTopSongsModelsDay(mostStreamedDay?.dayModels.map(model => model.model));
            setTopSongDayStatuses(mostStreamedDay?.day.map(song => song.isFavorite));
            setTopSongModelDayStatuses(mostStreamedDay?.dayModels.map(model => model.isFavorite))
        }
    }, [data]);

    const setTopSongsBySort = (sort) => {
        if (sort === "allTime") {
            setSortOption("allTime")
            setTopSongs(topSongsAll);
            setTopSongsModels(topSongsModelsAll);
            setTopSongStatuses(topSongAllStatuses);
            setTopSongsModelStatuses(topSongModelAllStatuses);
        } else if (sort === "today") {
            setSortOption("today")
            setTopSongs(topSongsDay);
            setTopSongsModels(topSongsModelsDay);
            setTopSongStatuses(topSongDayStatuses);
            setTopSongsModelStatuses(topSongModelDayStatuses);
        } else if (sort === "thisWeek") {
            setSortOption("thisWeek")
            setTopSongs(topSongsWeek);
            setTopSongsModels(topSongsModelsWeek);
            setTopSongStatuses(topSongWeekStatuses);
            setTopSongsModelStatuses(topSongModelWeekStatuses);
        } else if (sort === "thisMonth") {
            setSortOption("thisMonth")
            setTopSongs(topSongsMonth);
            setTopSongsModels(topSongsModelsMonth);
            setTopSongStatuses(topSongMonthStatuses);
            setTopSongsModelStatuses(topSongModelMonthStatuses);
        }
    }

    useEffect(() => {
        setTopSongsBySort("allTime")
    }, [topSongsAll])

    const handlePlayPause = (song, model, songs, models) => {
        if (isPlaying && currentTrack?._id === song._id) {
            setIsPlaying(!isPlaying);
        } else if (currentTrack?._id === song._id) {
            setIsPlaying(true);
        } else {
            logEvent('Song Played', { location: "Top Songs Screen", songId: song._id, songName: song.name });
            handlePlayTrack(song, model, songs, models)
        }
    }

    return (
        <div className={"top-songs-screen"}>
            <Header
                dontShowSearch={true}
                dontShowDiscover={true}
                dontShowRadio={true}
                sortedSongs={topSongsAll} sortedModels={topSongsModelsAll} />
            <div className={"top-songs-content"}>
                <h1 className={"top-songs-screen-title"}>Hugging Bass Top Charts</h1>
                <div className={"top-songs-sort-container"}>
                    <button
                        onClick={() => setTopSongsBySort("allTime")}
                        className={`top-songs-sort-button ${sortOption === 'allTime' && 'active-sort-button'}`}>All Time</button>
                    <button
                        onClick={() => setTopSongsBySort("today")}
                        className={`top-songs-sort-button ${sortOption === 'today' && 'active-sort-button'}`}>Today</button>
                    <button
                        onClick={() => setTopSongsBySort("thisWeek")}
                        className={`top-songs-sort-button ${sortOption === 'thisWeek' && 'active-sort-button'}`}>This Week</button>
                    <button
                        onClick={() => setTopSongsBySort("thisMonth")}
                        className={`top-songs-sort-button ${sortOption === 'thisMonth' && 'active-sort-button'}`}>This Month</button>
                </div>
                <div className={"songs-container"}>
                    {topSongs?.map((song, i) => {
                        return (
                            <HorizontalSongTile
                                rank={i + 1}
                                song={song}
                                status={topSongStatuses[i]}
                                modelStatus={topSongModelStatuses[i]}
                                modelUsed={topSongsModels[i]}
                                onPlay={() => handlePlayPause(topSongs[i], topSongsModels[i], topSongs, topSongsModels)}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default TopSongsScreen;
