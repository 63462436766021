export const handleRemixAudio = async (modelId, referenceUrl, songId) => {
    try {
        const response = await fetch(`https://huggingbass-inference-api-ijvhsb7soq-nn.a.run.app/remix`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ modelId, referenceUrl, songId })
        });

        const data = await response.json();
        if (!response.ok) {
            throw new Error(data.error || 'Error remixing audio');
        }
        return data;
    } catch (error) {
        console.error('Error in remixAudio:', error);
        throw error;
    }
};

export const handleReconnectAudiosFromRemix = async (vocalTrackUrl, backgroundTrackUrl, trackId, modelId) => {
    try {
        const response = await fetch(`https://huggingbass-inference-api-ijvhsb7soq-nn.a.run.app/combine-for-output`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ vocalTrackUrl, backgroundTrackUrl, trackId, modelId })
        });

        const data = await response.json();
        if (!response.ok) {
            throw new Error(data.message || 'Error splitting track for remix');
        }
        return data;
    } catch (error) {
        console.error('Error in splitForRemix:', error);
        throw error;
    }
};
