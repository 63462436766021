import React, {useContext, useEffect, useState} from "react";
import './topModels.css';
import {useNavigate} from "react-router-dom";
import {useQuery} from "react-query";
import {useAuth} from "../../../contexts/authContext";
import {fetchSongsAndModelsTopModelsDay} from "../../../api/fetches";
import {useHandlePlayTrack} from "../../../contexts/audioContext";
import AudioContext from '../../../contexts/audioContext';
import {logEvent} from "../../../App";
import LoginModal from "../../modals/loginModal";
import TopModelTile from "./topModelTile/topModelTile";
import HorizontalModelTile from "../../tiles/horizontalModelTile/horizontalModelTile";

const TopModels = () => {
    const { isPlaying, currentTrack, setIsPlaying } = useContext(AudioContext);
    const { handlePlayTrack } = useHandlePlayTrack();
    const navigate = useNavigate();
    const { activeUser } = useAuth();
    const { data } = useQuery(['songsAndModelsTopModelsDay', activeUser?._id], () => fetchSongsAndModelsTopModelsDay({userId: activeUser?._id}));
    const [isModalOpen, setIsModalOpen] = useState(false);
    // data
    const [topModels, setTopModels] = useState([])
    const [topModelStatuses, setTopModelStatuses] = useState([])
    const [topModelSongs, setTopModelSongs] = useState([])
    const [topModelSongStatuses, setTopModelSongStatuses] = useState([])

    // fetch data
    useEffect(() => {
        if (data) {
            const modelData = data.mostStreamedModelData[0];
            const topModels = modelData.map(data => data.model);
            const topModelStatuses = modelData.map(data => data.isFavorite);
            const topModelSongs = modelData.map(data => data.topSongs?.map(topSong => topSong.song));
            const topModelSongFavorites = modelData.map(data => data.topSongs?.map(topSong => topSong.isFavorite));

            setTopModels(topModels);
            setTopModelStatuses(topModelStatuses);
            setTopModelSongs(topModelSongs);
            setTopModelSongStatuses(topModelSongFavorites);
        }
    }, [data]);

    const handlePlayPause = (song, model, songs, models) => {
        if (isPlaying && currentTrack?._id === song._id) {
            setIsPlaying(!isPlaying);
        } else if (currentTrack?._id === song._id) {
            setIsPlaying(true);
        } else {
            logEvent('Song Played', { location: "Top Models", songId: song._id, songName: song.name });
            handlePlayTrack(song, model, songs, models)
        }
    }

    return (
        <>
            <div className={"top-models"}>
                <div className={"top-songs-title-row"}>
                    <h1 className={"top-creators-title"}>Top Models:</h1>
                    {/*<p className={"see-all"}>See all</p>*/}
                </div>
                <TopModelTile
                    model={topModels[0]}
                    status={topModelStatuses[0]}
                    modelSongs={topModelSongs[0]}
                    modelSongStatuses={topModelSongStatuses[0]}
                />
                {topModels.slice(1, 5).map((model, index) => (
                    <HorizontalModelTile
                        model={model}
                        status={topModelStatuses[index + 1]}
                        rank={index + 2}
                    />
                ))}
            </div>
            <LoginModal isVisible={isModalOpen} onClose={() => setIsModalOpen(false)} />
        </>
    )
}

export default TopModels;
