import {API_URL} from "../auth";


export const attachModelToSong = async (modelId, songId) => {
    const response = await fetch(`${API_URL}/routes/upload/attachModelToSong`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({modelId, songId})
    });

    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Error uploading model');
    }
    return data;
};

export const handleAttachReferenceTrack = async (modelId, referenceTrack) => {
    const formData = new FormData();
    formData.append('modelId', modelId);
    referenceTrack.forEach(file => {
        formData.append('referenceTrack', file);
    });

    const response = await fetch(`${API_URL}/routes/upload/models/addReferenceTrack`, {
        method: 'POST',
        body: formData
    });

    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.message || 'Error uploading model');
    }
    return data;
}
