import axios from "axios";
import {API_URL} from "../auth";

export const uploadSongFromPrompt = async (name, audioFile, artist, artistUrl, coverImage, origin, prompt, styleOfMusic) => {
    try {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('artist', artist);
        formData.append('artistUrl', artistUrl);
        formData.append('coverImage', coverImage);
        formData.append('audioFile', audioFile);
        formData.append('origin', origin);
        formData.append('prompt', prompt);
        formData.append('styleOfMusic', styleOfMusic);

        const response = await axios.post(`${API_URL}/routes/upload/songs/uploadFromPrompt`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    } catch (error) {
        console.error('Song Upload Error:', error);
        throw error;
    }
};
export const uploadYouTubeUrl = async (youtubeUrl, username, uploaderId, uploaderUrl, isPrivate, songPassword) => {
    try {

        const response = await fetch(`${API_URL}/routes/upload/songs/youtubeUpload`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ youtubeUrl, username, uploaderId, uploaderUrl, isPrivate, songPassword }),
        });

        if (!response.ok) {
            throw new Error('Error uploading from youtube');
        }

        return await response.json();
    } catch (error) {
        console.error('Search Error:', error);
        throw error;
    }
};
export const uploadSoundcloudUrl = async (soundcloudUrl, username, uploaderId) => {
    try {

        const response = await fetch(`${API_URL}/routes/upload/songs/soundcloudUpload`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ soundcloudUrl, username, uploaderId }),
        });

        if (!response.ok) {
            throw new Error('Error uploading from soundcloud');
        }

        return await response.json();
    } catch (error) {
        console.error('Search Error:', error);
        throw error;
    }
};
export const uploadSongInChunks = async (songName, username, profileUrl, coverImage, audioFile, duration, isPrivate, songPassword, uploaderId) => {
    const chunkSize = 25 * 1024 * 1024; // 25 MB

    const additionalData = {
        name: songName,
        username,
        profileUrl,
        isPrivate,
        songPassword,
        uploaderId
    };

    let songResponse = null;
    songResponse = await uploadFileInChunks(audioFile, chunkSize, coverImage, { ...additionalData, duration, fileType: 'audioFile' });
    return songResponse;
};

const uploadFileInChunks = async (file, chunkSize, coverImage, additionalData) => {
    const totalChunks = Math.ceil(file.size / chunkSize);
    const responses = [];
    for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
        const start = chunkIndex * chunkSize;
        const end = Math.min(start + chunkSize, file.size);
        const chunk = file.slice(start, end);

        const formData = new FormData();
        formData.append('chunk', chunk);
        formData.append('chunkIndex', chunkIndex);
        formData.append('totalChunks', totalChunks);
        formData.append('fileName', file.name);
        formData.append('coverImage', coverImage);
        for (const [key, value] of Object.entries(additionalData)) {
            formData.append(key, value);
        }


        const response = await fetch(`${API_URL}/routes/upload/songs/uploadSongChunk`, {
            method: 'POST',
            body: formData,
        });

        const responseData = await response.json();
        responses.push(responseData);

        if (chunkIndex === totalChunks - 1) {
            return responseData;
        }
    }
    return responses;
};

export const uploadSong = async (name, audioFile, profileUrl, coverImage, username, isPrivate, songPassword, uploaderId) => {
    try {
        const formData = new FormData();
        formData.append('coverImage', coverImage);
        formData.append('audioFile', audioFile);

        const additionalData = {
            name,
            username,
            profileUrl,
            isPrivate,
            songPassword,
            uploaderId
        };

        const response = await axios.post(`${API_URL}/routes/upload/songs/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            params: additionalData,
        });

        return response.data;
    } catch (error) {
        console.error('Song Upload Error:', error);
        throw error;
    }
};

export const uploadSongFromRemix = async (audioUrl, name, artist, artistUrl, coverImage, isPrivate, songPassword, remixedArtist, remixedArtistUrl) => {
    try {
        const formData = new FormData();
        formData.append('coverImage', coverImage);
        console.log(formData)
        const additionalData = {
            audioUrl,
            name,
            artist,
            artistUrl,
            isPrivate,
            songPassword,
            remixedArtist,
            remixedArtistUrl,
        };

        const response = await axios.post(`${API_URL}/routes/upload/songs/uploadFromRemix`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            params: additionalData,
        });

        return response.data;
    } catch (error) {
        console.error('Song Upload Error:', error);
        throw error;
    }
};


export const updateSongUrls = async () => {
    const response = await fetch(`${API_URL}/routes/upload/songs/updateSongUrls`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    });
    return await response.json();
};
